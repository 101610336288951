
import React from "react";

import {Helmet} from "react-helmet";

const css = `@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
}

b {
    font-size: 12px;
}

.document {
    margin: auto auto;
    max-width: 216mm;
    max-height: 297mm;
    background-color: #fff;
}

.headerBtn {
    margin: auto auto;
    width: 216mm;
    background-color: #fff;
    display: none;
}

table {
    width: 100%;
    position: relative;
    border-collapse: collapse;
}

.bankLogo {
    width: 28%;
}

.boletoNumber {
    width: 62%;
    font-weight: bold;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
    right: 20px;
}

td {
    position: relative;
    /*padding: 10px;*/
    padding: 2px 5px;
}

.title {
    position: absolute;
    left: 0px;
    top: 0px;
    font-size: 10px;
    padding-left: 5px;
}

.text {
    font-size: 10px;
    font-weight: bold;
}

p.content {
    padding: 0px;
    width: 100%;
    margin: 0px;
    font-size: 10px;
}

.sideBorders {
    border-left: 1px solid black;
    border-right: 1px solid black;
}

hr {
    border: 1px dashed;
}

br {
    content: " ";
    display: block;
    margin: 12px 0;
    line-height: 12px;
}

.print {
    background-color: rgb(77, 144, 254);
    background-image: linear-gradient(to bottom, rgb(77, 144, 254), rgb(71, 135, 237));
    border: 1px solid rgb(48, 121, 237);
    color: #fff;
    text-shadow: 0 1px rgba(0, 0, 0, 0.1);
}

.btnDefault {
    font-kerning: none;
    font-weight: bold;
}

.pagador-description {
    padding-left: 10px;
}

#root {
    overflow: scroll !important;
}
  
`;

const StyleHelmet = () => (
    <Helmet>
        <style>
            {css}
        </style>
    </Helmet>
)

export default StyleHelmet;