import React, { PureComponent } from "react";
import "./TextStep.css";
import PropTypes from "prop-types";

/**
 * @description Componente responsavel por informar qual passo de um workflow o usuário esta
 * @param {*} Props
 * @example
 *    <TextStep current="3" total="3" />
 */
export default class TextStep extends PureComponent {
  static propTypes = {
    // Propriedade className
    className: PropTypes.string,
    // Passo atual do processo
    current: PropTypes.number,
    // Total de passos do processo
    total: PropTypes.number
  };

  static defaultProps = {
    className: "",
    current: 1,
    total: 1
  };

  render() {
    const { className, current, total } = this.props;
    return (
      <div className={`${className} text-right`}>
        {current} de {total}
      </div>
    );
  }
}
