import React, { useState } from "react";
import PropTypes from "prop-types";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import "./styles.css";

function IdentifierPasswordInput({
  placeholder,
  className,
  errorText,
  name,
  disabled,
  isBoxInput,
  maxLength,
  onChangeValue,
}) {
  const [value, setValue] = useState("");
  const [error] = useState("");
  const [showPassword, togglePassword] = useState(false);

  const onChange = (e) => {
    let value = e.target.value;
    setValue(e.target.value);

    onChangeValue(value);
  };

  const errorMessage = (message) => `Ops! ${message} :(`;

  const joinClassNames = () => {
    return [
      isBoxInput ? "form-control" : "line-input",
      className,
      error || errorText ? "is-invalid" : "was-validated",
    ].join(" ");
  };

  const showError = () =>
    (error || errorText) && (
      <div className={isBoxInput ? "invalid-feedback" : "mt-4 text-error"}>
        {errorMessage(error || errorText)}
      </div>
    );

  return (
    <>
      <div className="container-identifie-password-input">
        <input
          autoComplete="off"
          className={joinClassNames()}
          type={showPassword ? "text" : "password"}
          maxLength={maxLength}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={name}
          disabled={disabled}
        />
        <div
          className="input-group-append PasswordInputPointer"
          onClick={() => togglePassword(!showPassword)}
        >
          <div className="eye">
            {showPassword ? (
              <FaEye className="Icon" color={"#fff"} size={22} />
            ) : (
              <FaEyeSlash className="Icon" color={"#fff"} size={22} />
            )}
          </div>
        </div>
      </div>
      <div>{showError()}</div>
    </>
  );
}

IdentifierPasswordInput.defaultProps = {
  placeholder: "Password",
  required: false,
  defaultValue: "",
  disabled: false,
  isBoxInput: true,
};

IdentifierPasswordInput.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  onChangeValue: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  isBoxInput: PropTypes.bool,
};

export default IdentifierPasswordInput;
