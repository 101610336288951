import React from "react";
import Feedback from "Containers/FeedBack";
import { Paths } from "Routes";
import { withRouter } from "react-router";
import { navigate } from "Helpers/Navigate";

function CompleteRegisterSuccess(props) {
  const goToHome = () => {
    navigate(props, Paths.HOME);
  };

  return (
    <Feedback
      goBack={props.history.goBack}
      headerTitle="Validar Cadastro"
      summary="Obrigado por completar seu cadastro!"
      emoji=":)"
      textButton="OK"
      details={
        <div>
          Vamos analisar seus dados e te avisamos assim que forem aprovados.
        </div>
      }
      submit={() => {
        goToHome();
      }}
    />
  );
}

export default withRouter(CompleteRegisterSuccess);
