import crypto from 'crypto';
import CRYPTOGRAPHY_CONSTANTS from '../Config/cryptography-constants'

class CryptographyHelper {
  encrypt(json) {
    const data = JSON.stringify(json);
    const partitions = Math.floor(data.length / CRYPTOGRAPHY_CONSTANTS.CHUNK_SIZE);

    const chunks = [];

    if (partitions === 0) {
      chunks.push({ chunk: this.generateChunk(data) });
    } else {
      for (let index = 0; index < partitions; index++) {
        chunks.push({
          chunk: this.generateChunk(
            data.substring(
              index * CRYPTOGRAPHY_CONSTANTS.CHUNK_SIZE,
              (index + 1) * CRYPTOGRAPHY_CONSTANTS.CHUNK_SIZE
            )
          ),
        });
      }

      if (partitions * CRYPTOGRAPHY_CONSTANTS.CHUNK_SIZE < data.length) {
        chunks.push({
          chunk: this.generateChunk(
            data.substring(partitions * CRYPTOGRAPHY_CONSTANTS.CHUNK_SIZE, data.length)
          ),
        });
      }
    }

    return { origin: this.generateChunk(CRYPTOGRAPHY_CONSTANTS.ORIGIN_PWA), chunks };
  }

  generateChunk(data) {
    return crypto
      .publicEncrypt(
        {
          key: CRYPTOGRAPHY_CONSTANTS['PRODUCTION'].ENCRYPT_KEY,
          padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
          oaepHash: CRYPTOGRAPHY_CONSTANTS.OAEP_HASH,
        },
        Buffer.from(data)
      )
      .toString(CRYPTOGRAPHY_CONSTANTS.ENCODING);
  }

  decrypt(chunk) {
    try {
      const decipher = crypto.createDecipheriv(
        'aes-256-gcm',
        CRYPTOGRAPHY_CONSTANTS['PRODUCTION'].DECRYPT_KEY,
        Buffer.from(chunk.iv, 'base64')
      );
      decipher.setAuthTag(Buffer.from(chunk.tag, 'base64'));

      return Buffer.concat([
        decipher.update(Buffer.from(chunk.data, 'base64')),
        decipher.final(),
      ]).toString();
    } catch (error) {
      return null;
    }
  }

  validate(origin) {
    return this.decrypt(origin) === CRYPTOGRAPHY_CONSTANTS.ORIGIN_BFF;
  }

  decryptChunks(chunks) {
    let decrypted = '';

    chunks.forEach(value => {
      decrypted = `${decrypted}${this.decrypt(value.chunk)}`;
    });

    return JSON.parse(decrypted);
  }
}

export default new CryptographyHelper();
