import { FaCheck } from "react-icons/fa";
import React, { Component } from "react";
import PropTypes from "prop-types";
import "./UploadInput.css";

/**
 * @author Antônio D. F.
 * @description Componente responsável por carregar uma imagem e converter para base64
 * @copyright 06/2019
 * @example
 *    <UploadInput
 *      onChange={image => {
 *        this.setState({ imageBase64: image });
 *      }}
 *    />
 */
class UploadInput extends Component {
  state = {
    errorText: "",
    base64: ""
  };

  getBase64 = (file, isto) => {
    let document = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      document = reader.result;
      document = document.split(",")[1];
      isto.setState({ base64: document });
      isto.props.onChange(document);
    };
  };

  renderIndicationText = () => {
    const { base64 } = this.state;
    if (base64) {
      return (
        <div>
          Foto carregada com sucesso <FaCheck className="icon" />
        </div>
      );
    } else {
      return "Foto do documento";
    }
  };

  verifyFile = file => {
    if (!file.type.includes("image")) {
      this.setState({ base64: "" });
      this.setState({ errorText: "Tipo de arquivo incorreto." });
    } else if (file.size > 3000000) {
      this.setState({ base64: "" });
      this.setState({ errorText: "A imagem deve ser menor do que 3 MB" });
    } else {
      this.setState({ errorText: "" });
      this.getBase64(file, this);
    }
  };

  onChange = e => {
    this.verifyFile(e.target.files[0]);
  };

  render() {
    const { label, buttonText } = this.props;
    const { errorText } = this.state;
    return (
      <div>
        <div className="form-control">
          <div className="row">
            <div className="col-10 lightGray">
              {this.renderIndicationText()}
            </div>

            <div className="col-2">
              <div className="float-right text-right">
                <label className="upload-input-label" htmlFor="upload-photo">
                  {buttonText}
                </label>
                <input
                  type="file"
                  name="photo"
                  id="upload-photo"
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="errorText">{errorText}</div>
      </div>
    );
  }
}

UploadInput.defaultProps = {
  label: "Foto do documento",
  buttonText: "Upload",
  disabled: false
};

UploadInput.propTypes = {
  /* Texto explicativo */
  label: PropTypes.string,
  /* Texto do botão */
  buttonText: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default UploadInput;
