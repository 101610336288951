import React, { Component } from "react";
import { connect } from "react-redux";

import GridLayout from "Layouts/GridLayout/GridLayout";
import LoggedHeader from "Components/LoggedHeader";
import Button from "Components/Button/Button";

import { Paths } from "Routes/";
import { navigate } from "Helpers/Navigate";
import "./InstallmentsDelayed.css";
import { formatName } from "../../Helpers/Formatters";

import whatsLebes from "../../images/whatsLebes.svg";
import chamawhatsapp from "../../images/chamawhatsapp.png";

class InstallmentsDelayed extends Component {
  handleClickNegotiate = () => {
    window.open("https://acordoonline.com/lojaslebes");
  };

  handleClickWhatsApp = () => {
    window.open("https://wa.me/555134997003");
  };

  renderBody() {
    return (
      <div className="mb-5 text-center">
        <div className="textNegotiate">
          <p className="textNegotiate">
            Olá, {formatName(this.props.fullName)}!
          </p>
          <p className="textNegotiate mb-3">
            Identificamos que constam parcelas em atraso em seu cadastro, para
            renegociar seus contratos, clique no botão abaixo ou entre em
            contato com um de nossos atendentes através do nosso WhatsApp.
          </p>
          <p className="textNegotiate mb-5">
            Atendimento Central de Negociações de Dívidas.
          </p>
        </div>

        <div className="mb-3">
          <Button
            text="Negocie aqui"
            name="negocie"
            onClick={this.handleClickNegotiate}
          />
        </div>
        <div className="mb-3">
          <img className="linkImg" src={chamawhatsapp} alt="whatsapp" onClick={this.handleClickWhatsApp}/>
        </div>
        <div>
          <p className="mb-1 textQR">Escaneie o código QR e fale conosco via WhatssApp:</p>
          <img src={whatsLebes} alt="qr-code" width={250} />
        </div>
      </div>
    );
  }

  render() {
    console.log();
    return (
      <>
        <GridLayout
          alignContent="center"
          alignFooter="center"
          alignHeader="center"
          header={
            <LoggedHeader
              navFunction={() => navigate(this.props, Paths.HOME)}
              text=""
            />
          }
          content={this.renderBody()}
        />
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  fullName: store.user.fullName,
});

export default connect(mapStateToProps, {})(InstallmentsDelayed);
