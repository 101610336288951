import React from "react";

export default ({ onClick, banner, ...rest }) => (
  <div className="justify-content-center row">
    <img
      alt="banner"
      style={{ maxHeight: "350px", cursor: "pointer" }}
      className="mb-3"
      src={`data:image/jpeg;base64,${banner}`}
      onClick={onClick}
    />
  </div>
);
