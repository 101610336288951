import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Loader from "Components/Loader/Loader";
import { Paths as UserRoutes } from "Routes/UserRoutes";
import { navigate } from "Helpers/Navigate";

/**
 * @author Guilherme Z, Leonardo L
 * @description HOC que valida usuário logado antes de proceder para a tela
 * @copyright 05/2019
 */
const protect = Component => props => {
  const { isLogged, ...rest } = props;

  if (isLogged) {
    return <Component {...rest} />;
  }
  navigate(props, UserRoutes.USER_IDENTIFICATION);

  return <Loader loading={true} />;
};

const mapStateToProps = ({ user }) => ({
  isLogged: user.isLogged
});

const composedProtect = compose(
  connect(mapStateToProps),
  protect
);

export default composedProtect;
