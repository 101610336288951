import React from "react";
import PropTypes from "prop-types";
import "./GridLayout.css";

// Possibilidades de configurações flex.
const flexPossibilities = [
  "flex-start",
  "flex-end",
  "center",
  "stretch",
  "baseline",
  "space-between",
  "space-around"
];

// Possibilidades de configurações de flex direction.
const directionsPossibilities = [
  "row",
  "column",
  "row-reverse",
  "column-reverse"
];

const GridLayout = props => {
  const {
    alignFooter,
    alignHeader,
    alignContent,
    directionContent,
    directionFooter,
    directionHeader,
    coloredBackgroundSize
  } = props;

  const centeringClass = "GridLayoutCentering";

  // css de alinhamento do footer
  const footerCustomStyles = {
    alignContent: alignFooter,
    alignItens: alignFooter,
    justifyContent: alignFooter,
    flexDirection: directionFooter
  };

  // css de alinhamento do header
  const headerCustomStyles = {
    alignContent: alignHeader,
    alignItens: alignHeader,
    justifyContent: alignHeader,
    flexDirection: directionHeader
  };

  // css de alinhamento do content
  const contentCustomStyles = {
    alignContent: alignContent,
    alignItens: alignContent,
    justifyContent: alignContent,
    flexDirection: directionContent
  };

  return (
    <>
      <div className="GridLayout">
        <div className="GridLayoutHeader">
          <div className={centeringClass} style={headerCustomStyles}>
            {props.header}
          </div>
        </div>
        <div className="GridLayoutContent">
          <div className={centeringClass} style={contentCustomStyles}>
            <div className="container" style={{ maxWidth: "720px" }}>
              {props.content}
            </div>
          </div>
        </div>
        <div className="GridLayoutFooter">
          <div className={centeringClass} style={footerCustomStyles}>
            {props.footer}
          </div>
        </div>
        {coloredBackgroundSize && (
          <div
            className="ColoredBackground"
            style={{
              height: `${coloredBackgroundSize}%`
            }}
          />
        )}
      </div>
    </>
  );
};

/**
 *  @description Possibilidades de uso para as três variaveis, @param alignContent, @param alignHeader, @param alignFooter:
 * "flex-start",
 * "flex-end",
 * "center",
 * "stretch",
 * "baseline",
 * "space-between",
 * "space-around"
 *
 *  Possibilidades de uso para as três variaveis, @param directionContent, @param directionHeader, @param directionFooter:
 * "row",
 * "column",
 * "row-reverse",
 * "column-reverse"
 */
GridLayout.propTypes = {
  // Alinhamento do conteudo do header, deve ser String.
  alignContent: PropTypes.oneOf(flexPossibilities),
  // Alinhamento do conteudo do header, deve ser String.
  alignHeader: PropTypes.oneOf(flexPossibilities),
  // Alinhamento do conteudo do header, deve ser String.
  alignFooter: PropTypes.oneOf(flexPossibilities),
  // Direction do conteudo do content, deve ser String.
  directionContent: PropTypes.oneOf(directionsPossibilities),
  // Direction do conteudo do header, deve ser String.
  directionHeader: PropTypes.oneOf(directionsPossibilities),
  // Direction do conteudo do footer, deve ser String.
  directionFooter: PropTypes.oneOf(directionsPossibilities),
  // Conteudo a ser renderizado no centro.
  content: PropTypes.any,
  // Conteudo a ser renderizado no header.
  header: PropTypes.any,
  // Conteudo a ser renderizado no footer.
  footer: PropTypes.any,
  // Tamanho da cor do fundo.
  coloredBackgroundSize: PropTypes.number
};

GridLayout.defaultProps = {
  // Alinhamento do conteudo do header, por default center
  alignContent: "center",
  // Alinhamento do conteudo do content, por default center
  alignHeader: "center",
  // Alinhamento do conteudo do footer, por default center
  alignFooter: "center",
  // Direction do conteudo do content, por default row
  directionContent: "row",
  // Direction do conteudo do header, por default row
  directionHeader: "row",
  // Direction do conteudo do footer, por default row
  directionFooter: "row"
};

export default GridLayout;
