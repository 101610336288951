import * as React from 'react'
import moment from 'moment'

const valueFormat = "YYYY-MM-DD";

const Body = (props) => {
  const {
    dtVencimento,
    beneficiarioNome,
    beneficiarioEndereco,
    beneficiarioCNPJ,
    agenciaCodigoDoBeneficiario,
    numeroDocumento,
    dtEmissao,
    especie,
    aceite,
    sacadoCPF,
    sacadoCEP,
    sacadoEndereco,
    sacadoNome,
    nossoNumeroBoleto,
    valorTotal,
    usoDoBanco,
    carteira,
    instrucoes,
    especieDoc,
    codigoBarras,
    localDePagamento,
    caixaObs1,
    caixaObs2,
    caixaObs3,
    caixaObs4
  } = props
  
  return (
    <table cellSpacing="0" cellPadding="0" border="1">
      <tbody>
        <tr>
          <td width="70%" colSpan="6">
            <span className="title">Local de Pagamento</span>
            <br/>
            <span className="text">{localDePagamento}</span>
          </td>
          <td width="30%">
            <span className="title">Data de Vencimento</span>
            <br/>
            <br/>
            <p className="content right text" style={{fontWeight: 'bold'}}>{dtVencimento}</p>
          </td>
        </tr>
        <tr>
          <td width="70%" colSpan="6">
            <span className="title">Beneficiário</span>
            <br/>
            <table border="0" style={{border: 'none'}}>
              <tbody>
                <tr>
                  <td width="60%">
                    <p className="content text" style={{fontWeight: 'bold'}}>{beneficiarioNome}</p>
                  </td>
                  <td width="60%">
                    <p className="content right text" style={{fontWeight: 'bold'}}>{beneficiarioCNPJ}</p>
                  </td>
                </tr>
                <tr>
                  <td width="60%">
                    <p className="content text" style={{fontWeight: 'bold', padding:0}}>{beneficiarioEndereco}</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br/>
          </td>
          <td width="30%">
            <span className="title">Agência/Código Beneficiário</span>
            <br/>
            <br/>
            <p className="content right">{agenciaCodigoDoBeneficiario}</p>
          </td>
        </tr>
        <tr>
          <td width="15%">
            <span className="title">Data do Documento</span>
            <br/>
            <p className="content center">{ dtEmissao }</p>
          </td>
          <td width="17%" colSpan="2">
            <span className="title">Num. do Documento</span>
            <br/>
            <p className="content center">{ numeroDocumento }</p>
          </td>
          <td width="10%">
            <span className="title">Espécie doc</span>
            <br/>
            <p className="content center">{ especieDoc }</p>
          </td>
          <td width="8%">
            <span className="title">Aceite</span>
            <br/>
            <p className="content center">{aceite}</p>
          </td>
          <td>
            <span className="title">Data Processamento</span>
            <br/>
            <p className="content center">{ dtEmissao }</p>
          </td>
          <td width="30%">
            <span className="title">Nosso Número</span>
            <br/>
            <br/>
            <p className="content right">{nossoNumeroBoleto}</p>
          </td>
        </tr>
        <tr>
          <td width="15%">
            <span className="title">Uso do Banco</span>
            <br/>
            <p className="content center">{usoDoBanco}</p>
          </td>
          <td width="10%">
            <span className="title">Carteira</span>
            <br/>
            <p className="content center">{ carteira }</p>
          </td>
          <td width="10%">
            <span className="title">Espécie</span>
            <br/>
            <p className="content center">{ especie }</p>
          </td>
          <td width="8%" colSpan="2">
            <span className="title">Quantidade</span>
            <br/>
            <p className="content center">-</p>
          </td>
          <td>
            <span className="title">Valor</span>
            <br/>
            <p className="content center">&nbsp;</p>
          </td>
          <td width="30%">
            <span className="title">(=) Valor do Documento</span>
            <br/>
            <p className="content right">{valorTotal}</p>
          </td>
        </tr>
        <tr>
          <td colSpan="6" rowSpan="5" style={{maxWidth:'525px', wordBreak:'break-all' }}>
            <span className="title" style={{textAlign: 'justify', padding: 5, fontSize: 8}}>
                {instrucoes}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="title">(-) Descontos/Abatimento</span>
            <br/>
            <p className="content right">&nbsp;</p>
          </td>
        </tr>

        <tr>
          <td>
            <span className="title">(+) Juros/Multa</span>
            <br/>
            <p className="content right">&nbsp;</p>
          </td>
        </tr>
        <tr>
          <td>
            <span className="title">(=) Valor Pago</span>
            <br/>
            <p className="content right">&nbsp;</p>
          </td>
        </tr>
        <tr >
          <td>
          <p className="content right">&nbsp;</p>
          <br/>
          </td>
        </tr>
        <tr>
          <td colSpan="7">
            <table border="0" style={{border: 'none'}}>
              <tbody>
                <tr>
                  <td width="60%">
                    <span
                      className="text"
                    >
                      <b>Pagador: {sacadoNome}</b>
                    </span>
                  </td>
                  <td><span className="text"><b>CNPJ/CPF: </b> {sacadoCPF} </span></td>
                </tr>
                <tr>
                  <td><span className="text"><b>Sacador/Avalista: </b></span></td>
                  <td><span className="text"><b>CNPJ/CPF: </b></span></td>
                </tr>
                <tr>
                  <td><p className="content text" style={{fontWeight: 'normal'}}>{caixaObs1}</p></td>
                  <td><p className="content text" style={{fontWeight: 'normal'}}>{caixaObs4}</p></td>
                </tr>
                <tr>
                  <td><p className="content text" style={{fontWeight: 'normal'}}>{caixaObs2}</p></td>
                </tr>
                <tr>
                  <td><p className="content text" style={{fontWeight: 'normal'}}>{caixaObs3}</p></td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  )
};

export default Body
