import React, { Component } from "react";
import "./Home.css";

import HeaderLogo from "Components/Headerlogo";
import Greetings from "Components/Greetings";
import QuickAccess from "./QuickAccess";
import Footer from "./Footer";
import Banner from "./Banner";
import ProductCarousel from "./ProductCarousel";

import { connect } from "react-redux";

import { handleGetLoggedUser } from "../../Store/Ducks/loggedUser";
import { handleSetFinancialStatus } from "../../Store/Ducks/user";
import { handleSetChaordicProducts } from "../../Store/Ducks/chaordicProducts";
import { getBalance } from "../../Store/Ducks/creditBankTransfer";

import initializeReactGA from "Services/ReactGA";
import { navigate } from "Helpers/Navigate";
import ServicesBFF from "Store/BFFWeb/BFFWeb";
import { Paths } from "Routes/MainPaths";

class Home extends Component {
  state = {
    isMobile: true,
    bannerImage: "",
    bannerNav: ""
  };

  componentDidMount() {
    this.setState({ isMobile: window.innerWidth < 980 });

    window.addEventListener(
      "resize",
      () => {
        const isMobile = window.innerWidth < 980;
        this.setState({ isMobile });
      },
      false
    );

    this.searchUserData();
    this.props.handleSetChaordicProducts();
    this.props.handleGetLoggedUser();
    this.BFFGetBanner();
    initializeReactGA({
      pageName: "/",
      category: "HOME",
      action: "Acesso_a_tela_inicial",
      label: "Acesso_a_home"
    });
  }

  searchUserData = () => {
    if (this.props.user.cpf && this.props.user.sessionToken) {
      this.props.handleSetFinancialStatus();
      this.props.getBalance();
    }
  };

  BFFGetBanner = async () => {
    await ServicesBFF.getBanner("web")
      .then(res => {
        const { base64, link_web } = res.data;
        this.setState({
          bannerImage: base64,
          bannerNav: link_web
        });
      })
      .catch(err => {
        console.warn(err);
        this.props.history.push(Paths.FAIL);
      });
  };

  openBanner = () => {
    const { bannerNav } = this.state;
    initializeReactGA({
      pageName: "/",
      category: "HOME",
      action: "clique_no_banner_principal",
      label: "Visualizar_detalhe_banner"
    });
    if (bannerNav.includes("http")) window.location.assign(bannerNav);
    else navigate(this.props, bannerNav);
  };

  render() {
    const { isMobile, bannerImage } = this.state;
    const { user, produtos, userBalance } = this.props;

    let containerClasses = [
      "container-fluid p-0",
      "HomeContainer",
      isMobile ? "m-0" : ""
    ].join(" ");

    return (
      <div className="Home" style={{ paddingBottom: isMobile && "40px" }}>
        <HeaderLogo height={12} isHome />
        <div className={containerClasses}>
          <Banner banner={bannerImage} onClick={this.openBanner} />
          <div className="justify-content-center row m-0">
            {user && (
              <Greetings
                name="ButtonGreetings"
                limiteDisponivel={Number(user.respLimiteDisponivel).toFixed(2)}
                valorDisponivel={Number(user.respValorDisponivel).toFixed(2)}
                fullName={user.fullName}
                userBalance={userBalance}
                isLogged={!!user.sessionToken}
                lebesUser={user.lbs_completo}
                creditRequestStatus={
                  user.ultimoStatus === "C" ? "" : user.ultimoStatus
                }
                userFinancialStatus={
                  user.statusUsuario === "A" ? "" : user.statusUsuario
                }
                reproveTime={user.reproveTime}
                enderecoRetirada={user.enderecoRetirada}
              />
            )}
          </div>
          <QuickAccess className="mb-4" />
          <ProductCarousel
            section="destaquesParaVoce"
            products={produtos["destaquesParaVoce"]}
          />
          <ProductCarousel
            section="produtosRecomendados"
            products={produtos["produtosRecomendados"]}
          />
          <ProductCarousel
            section="ofertasEspeciais"
            products={produtos["ofertasEspeciais"]}
          />
        </div>
        <Footer isMobile={this.state.isMobile} />
      </div>
    );
  }
}

const mapStateToProps = ({
  user,
  loggedUser,
  chaordicProducts,
  creditBankTransfer
}) => {
  return {
    user,
    loggedUser,
    produtos: chaordicProducts,
    userBalance: creditBankTransfer.userBalance
  };
};

export default connect(
  mapStateToProps,
  {
    handleGetLoggedUser,
    handleSetFinancialStatus,
    handleSetChaordicProducts,
    getBalance
  }
)(Home);
