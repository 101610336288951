import React, { Component } from "react";
import CheckBox from "Components/CheckBox/CheckBox";
import "./CreditTerms.css";
import LoggedHeader from "Components/LoggedHeader/LoggedHeader";
import GridLayout from "Layouts/GridLayout/GridLayout";
import Button from "Components/Button/Button";
import { Paths } from "Routes/CreditRequestRoutes";
import BFFWeb from "Store/BFFWeb/BFFWeb.js";
import { connect } from "react-redux";
import {
  handleSubmitContract,
  getParams
} from "../../Store/Ducks/creditRequest";
import { navigate } from "Helpers/Navigate";
import initializeReactGA from "Services/ReactGA";

class CreditTerms extends Component {
  state = { contratos: [], checked: false };

  componentDidMount() {
    this.getTerms();
    initializeReactGA({
      pageName: "credit/terms",
      category: "EMPRESTIMO-SIMULAR",
      action: "Acesso_a_tela_de_simulacao_de_emprestimo",
      label: "Simular_emprestimo"
    });
  }

  getTerms = () => {
    const { loggedUser, creditRequest, parametros } = this.props;

    let codigoTermo = "1";
    if (creditRequest.insurance) codigoTermo = "2";

    BFFWeb.getContract(
      codigoTermo,
      loggedUser.enderecoResidencial.cidade || "1",
      parametros
    ).then(res => {
      const { termoContrato, termoContratoSeguro } = res.data;
      let contratos = [termoContrato.termo];
      if (termoContratoSeguro) {
        contratos = [...contratos, termoContratoSeguro.termo];
      }
      this.setState({ contratos });
    });
  };

  submitContract = () => {
    this.props.handleSubmitContract().then(() => {
      let { idOperacao } = this.props.creditRequest;
      if (idOperacao) {
        initializeReactGA({
          pageName: "credit/request-success",
          category: "EMPRESTIMO-SIMULAR",
          action: "Acesso_a_tela_de_simulacao_de_emprestimo",
          label: "Simular_emprestimo"
        });
        navigate(this.props, Paths.CREDIT_REQUEST_SUCCESS);
      } else {
        initializeReactGA({
          pageName: "credit/request-fail",
          category: "EMPRESTIMO-SIMULAR",
          action: "Acesso_a_tela_de_simulacao_de_emprestimo",
          label: "Simular_emprestimo"
        });
        navigate(this.props, Paths.CREDIT_REQUEST_FAIL);
      }
    });
  };

  onChange = checked => this.setState({ checked: checked });

  renderLinhas(contrato) {
    return contrato.linhas.map((linha, index) => {
      return (
        <small key={index}>
          {linha}
          <br />
        </small>
      );
    });
  }

  renderContent() {
    const { contratos, checked } = this.state;
    return (
      <>
        <div className="terms-scroll p-2 mb-4">
          {contratos.map((contrato, index) => {
            return (
              <div key={index}>
                <p className="text-dark text-center mb-4">
                  {contrato.descricao}
                </p>
                <div>{this.renderLinhas(contrato)}</div>
              </div>
            );
          })}

          <div className="mt-5">
            <CheckBox
              label="Li e aceito os termos do contrato."
              checked={checked}
              onChangeValue={this.onChange}
              classNameConteiner="ConfirmHiringTextContent TextBold"
            />
          </div>
        </div>
      </>
    );
  }

  renderFooter = () => {
    return (
      <Button
        text="Finalizar Contratação"
        disabled={!this.state.checked}
        onClick={this.submitContract}
      />
    );
  };

  render() {
    return (
      <GridLayout
        alignContent="center"
        alignFooter="center"
        alignHeader="center"
        header={<LoggedHeader text="Fazer empréstimo" />}
        content={this.renderContent()}
        footer={this.renderFooter()}
      />
    );
  }
}

const mapStateToProps = ({ loggedUser, creditRequest }) => ({
  loggedUser,
  parametros: getParams(creditRequest),
  creditRequest
});

export default connect(
  mapStateToProps,
  { handleSubmitContract }
)(CreditTerms);
