import React from "react";
import { PropTypes } from "prop-types";

/**
 * @author Guilherme Z.
 * @description Componente de caixa radio input
 * @example
 *   <Radio
 *      checked={this.state.opcao}
 *      onChange={this.changeOpcao}
 *      value="opcaoUm"
 *    >
 *       Opção um
 *     </Radio>
 */
const Radio = ({ id, value, checked, onChangeValue, children }) => {
  const onChange = e => {
    onChangeValue(e.target.value);
  };

  const isChecked = () =>
    typeof checked === "boolean" ? checked : checked === value;

  return (
    <div className="form-group">
      <div className="custom-control custom-radio custom-control-inline">
        <input
          id={id}
          name={id}
          type="radio"
          value={value}
          onChange={onChange}
          checked={isChecked()}
          className="custom-control-input"
        />
        <label className="custom-control-label" htmlFor={id}>
          {children}
        </label>
      </div>
    </div>
  );
};

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
};

export default Radio;
