import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./Text.css";

export default class Text extends PureComponent {
  static propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.any
  };

  render() {
    const { children, size, color, className } = this.props;
    let style = { fontSize: size, color: color };
    const classNames = [className, "Text"].join(" ");
    return (
      <div className={classNames} style={style}>
        {children}
      </div>
    );
  }
}
