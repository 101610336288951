/* Action types */
import BFFWeb from "../../Store/BFFWeb/BFFWeb";

export const Types = {
  SET_STORES: "stores/set"
};

/* Reducer */
export default function stores(state = [], { type, payload }) {
  switch (type) {
    case Types.SET_STORES:
      return payload;
    default:
      return state;
  }
}

/* Action Creators */
export function setStores(payload) {
  return {
    type: Types.SET_STORES,
    payload
  };
}
/* Action Creators */

/* Handlers */
export function handleGetStores() {
  return dispatch => {
    BFFWeb.lojasList().then(({ data }) => {
      dispatch(setStores(data.listaLojas));
    });
  };
}
/* Handlers */
