import React, { Component } from "react";
import "./Welcome.css";
import Feedback from "../FeedBack";

import { Paths } from "Routes/UserRoutes";
import { navigate } from "Helpers/Navigate";
import { registerInitialData } from "../../Store/Ducks/user";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ServicesBFF from "Store/BFFWeb/BFFWeb";

import initializeReactGA from "Services/ReactGA";

/**
 * <UI-005></UI-005>
 */
class Welcome extends Component {
  state = {
    message: "OK"
  };

  componentDidMount = () => {
    initializeReactGA({
      pageName: "user/welcome",
      category: "HOME-LOGIN-CADASTRO",
      action: "Acesso_a_tela_para_cadastro_ou_login",
      label: "cadastrar_falta_pouco"
    });
  };

  formatDate = date => {
    if (date) {
      let newDate = date.split("/");
      return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
    }
  };

  submit = () => {
    const { cpf, emailAddress, phoneNumber, fullName, birthdate } = this.props;

    let initialData = {
      fullName,
      cpf,
      birthdate,
      emailAddress,
      phoneNumber,
      sendSms: true,
      sendEmail: false
    };

    ServicesBFF.startCustomerCreation(initialData)
      .then(res => {
        const { status } = res.data;
        const { message } = status;
        this.setState({ message });
        if (
          res.data &&
          res.data.tokenData &&
          res.data.tokenData.creationToken
        ) {
          initialData.creationToken = res.data.tokenData.creationToken;
        }

        if (message === "OK") {
          this.props.registerInitialData(initialData);
          navigate(this.props, Paths.USER_CODE_CONFIRM_ALREADY);
        }
      })
      .catch(res => {
        this.setState({
          message: "Ops! Ocorreu algum problema, por favor tente novamente."
        });
      });
  };

  render() {
    return (
      <Feedback
        title="Bem vindo"
        summary="à sua conta digital da Lebes!"
        emoji=":)"
        textButton="Continuar"
        details="Agora falta pouco pra finalizar seu cadastro!"
        submit={this.submit}
        errorMessage={this.state.message}
      />
    );
  }
}

const mapStateToProps = store => ({
  cpf: store.user.cpf,
  fullName: store.user.fullName,
  emailAddress: store.user.emailAddress,
  phoneNumber: store.user.phoneNumber,
  birthdate: store.user.birthdate
});

const mapActionsToProps = dispatch =>
  bindActionCreators({ registerInitialData }, dispatch);

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Welcome);
