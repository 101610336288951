export default {
  0: { value: 0, key: "Janeiro" },
  1: { value: 1, key: "Fevereiro" },
  2: { value: 2, key: "Março" },
  3: { value: 3, key: "Abril" },
  4: { value: 4, key: "Maio" },
  5: { value: 5, key: "Junho" },
  6: { value: 6, key: "Julho" },
  7: { value: 7, key: "Agosto" },
  8: { value: 8, key: "Setembro" },
  9: { value: 9, key: "Outubro" },
  10: { value: 10, key: "Novembro" },
  11: { value: 11, key: "Dezembro" },
  12: { value: 12, key: "Todos os meses" }
};
