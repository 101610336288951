import React, { Component } from "react";
import Text from "Components/Text/Text";
import CodeInput from "Components/CodeInput";
import GridLayout from "Layouts/GridLayout";
import Button from "Components/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { registerSessionToken } from "Store/Ducks/user";
import LoggedHeader from "Components/LoggedHeader/LoggedHeader";
import { Paths } from "Routes/UserRoutes";
import PhoneMask from "Components/PhoneMask";
import VMasker from "vanilla-masker";
import { navigate } from "Helpers/Navigate";

import initializeReactGA from "Services/ReactGA";

class CodeConfirmAlready extends Component {
  state = {
    error: "",
    challenge: ""
  };

  componentDidMount = () => {
    initializeReactGA({
      pageName: "user/welcome",
      category: "HOME-LOGIN-CADASTRO",
      action: "Acesso_a_tela_para_cadastro_ou_login",
      label: "cadastrar_Cod_Verificacao"
    });
  };

  onBlurCodeInput = challenge => {
    if (challenge) this.setState({ challenge });
  };

  submit = () => {
    const { challenge } = this.state;
    if (challenge) {
      this.props.registerSessionToken({ challenge });
      navigate(this.props, Paths.USER_PASSWORD_CONFIRM);
    }
  };

  showError = error => this.setState({ error: error });

  render() {
    const { phoneNumber } = this.props;
    let phoneMasked = VMasker.toPattern(phoneNumber.slice(9), "(XX)XXXXX-9999");
    const { error } = this.state;
    const header = <LoggedHeader text="Cadastro Lebes" hasNavigation={false} />;
    const content = (
      <>
        <Text>
          <p>
            Enviamos por <b>SMS</b> o código de 6 dígitos para o número:
          </p>
        </Text>

        <PhoneMask hideFirstDigits value={phoneMasked} />

        <CodeInput
          errorText={error}
          onChange={this.onBlurCodeInput}
          className="col-6"
          name="code"
        />
        <Text className="mt-4">
          Caso o telefone não esteja correto você pode atualizar o número indo
          até a loja mais próxima ou ligando para a nossa Central de Atendimento
          Facilita Lebes: (51) 3499-7236
        </Text>
      </>
    );
    return (
      <div className="CodeConfirm">
        <GridLayout
          alignContent="center"
          alignFooter="center"
          alignHeader="center"
          content={content}
          header={header}
          footer={
            <Button
              name="btnProximo"
              text="Próximo"
              onClick={this.submit}
              disabled={!this.state.challenge}
            />
          }
        />
      </div>
    );
  }
}

const mapDispathToProps = dispatch =>
  bindActionCreators({ registerSessionToken }, dispatch);

const mapStateToProps = store => ({
  phoneNumber: store.user.phoneNumber,
  challenge: store.user.challenge
});

export default connect(
  mapStateToProps,
  mapDispathToProps
)(CodeConfirmAlready);
