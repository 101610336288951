import React, { Component } from "react";
import PropTypes from "prop-types";
import "./DateInput.css";
import VMasker from "vanilla-masker";

const moment = require("moment");

/**
 * @author Vicente Balaguer
 * @copyright 03/2019
 * @description Campo de Data de Nascimento, com aviso de preenchimento incorreto e datas inválidas
 */
class DateInput extends Component {
  state = {
    date: "",
    errorText: ""
  };

  /**
   * @author Vicente Balaguer
   * @copyright 03/2019
   * @description Evento de atualização de estado ao digitar a data
   */
  onChange = e => {
    let date = VMasker.toPattern(e.target.value, "99/99/9999");
    this.setState({ date }, () => {
      if (!this.validate(this.state.date)) {
        date = "";
      }
      this.props.onChange(date);
    });
  };

  /**
   * @author Vicente Balaguer
   * @copyright 03/2019
   * @description Evento de validação e atualização de estado ao digitar a data
   */
  onBlur = e => {
    this.onChange(e);
  };

  /**
   * @author Vicente Balaguer
   * @copyright 03/2019
   * @description Evento de validação de data
   * @return Retorna verdadeiro se a data for válida e falso caso seja inválida
   */
  validate = () => {
    let today = moment(new Date(), "DD/MM/YYYY");
    let date = moment(this.state.date, "DD/MM/YYYY");

    if (!this.state.date && this.props.required) {
      this.setState({ errorText: "Ops! Data não foi preenchida :(" });
      return false;
    } else if (this.state.date) {
      if (this.state.date.length < 10 || !date.isValid()) {
        this.setState({ errorText: "Ops! Data inválida :(" });
        return false;
      } else if (today.diff(date, "years") >= 18) {
        this.setState({ errorText: "OK" });
        return true;
      } else {
        this.setState({ errorText: "Ops! A idade mínima é 18 anos :(" });
        return false;
      }
    } else {
      this.setState({ errorText: "OK" });
      return true;
    }
  };

  componentDidMount = () => {
    this.setState({ date: this.props.date });
  };

  componentDidUpdate(prevProps) {
    if (this.props.date !== prevProps.date)
      this.setState({ date: this.props.date });
  }

  render() {
    const { placeholder, className, name, disabled } = this.props;
    const { date, errorText } = this.state;
    return (
      <div className="form-group">
        <input
          className={`form-control ${
            !errorText || errorText === "OK" ? "" : "is-invalid"
          } ${className}`}
          type="text"
          maxLength="10"
          name={name}
          placeholder={placeholder}
          value={date}
          onChange={this.onChange}
          onBlur={this.onBlur}
          disabled={disabled}
        />
        <div className="invalid-feedback">
          {errorText === "OK" ? "" : errorText}
        </div>
      </div>
    );
  }
}

DateInput.defaultProps = {
  placeholder: "Data de nascimento (dd/mm/aaaa)",
  /* Campo habilitado por valor padrao */
  disabled: false
};

DateInput.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  date: PropTypes.string,
  errorText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  /* Desabilita campo se valor pre carregado */
  disabled: PropTypes.bool
};

export default DateInput;
