import React from "react";
import { PropTypes } from "prop-types";

function CPFText({ value }) {
  /**
   * @author Anderson Oliveira, Leonardo Lucas dos Santos
   * @copyright 02/2019
   * @description Método de aplicação de máscara de CPF
   */
  const formatCPF = cpf => {
    cpf = cpf.replace(/[^\d]/g, "");
    cpf = cpf.substr(0, 11);
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1-$2");
    return cpf;
  };

  return <>{formatCPF(value)}</>;
}

CPFText.propTypes = {
  value: PropTypes.string.isRequired
};

export default CPFText;
