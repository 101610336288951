import React from "react";
import Card from "Components/Card";
import TextMoney from "Components/TextMoney";
import "./Product.css";

import initializeReactGA from "Services/ReactGA";

export default function Product({ product, section }) {
  const ga = {
    destaquesParaVoce: {
      pageName: "/",
      category: "HOME-DESTAQUE",
      action: "clique_no_botao_ver_oferta",
      label: "visualizar_destaque_de_retirar_na_loja"
    },
    produtosRecomendados: {
      pageName: "/",
      category: "HOME-PRODUTOS_RECOMENDADOS",
      action: "clique_no_botao_ver_oferta",
      label: "visualizar_produtos_recomendados_de_retirar_na_loja"
    },
    ofertasEspeciais: {
      pageName: "/",
      category: "HOME-OFERTAS_ESPECIAIS",
      action: "clique_no_botao_ver_oferta",
      label: "visualizar_ofertas_especiais_de_retirar_na_loja"
    }
  };

  const visitProduct = () => {
    initializeReactGA(ga[section]);

    window.open(product.url);
  };
  const { name, price, oldPrice, installment } = product;
  return (
    <div className="product">
      <Card padding={13}>
        <img
          onClick={() => window.open(product.url)}
          style={{ cursor: "pointer", width: "100%" }}
          src={product.image}
          alt={product.name}
        />
        <div style={{ height: "140px" }}>
          <div className="mb-2 product-name">{name}</div>
          <div className="old-price-product product-font-sm">
            De <TextMoney className="product-font-md" value={oldPrice} />
          </div>
          <div className="product-color-orange">
            Por <TextMoney className="product-font-lg" value={price} />
          </div>
          {renderInstallment(installment)}
        </div>
        <button
          type="button"
          onClick={visitProduct}
          className="btn btn-primary btn-ver-oferta"
        >
          Ver oferta
        </button>
      </Card>
    </div>
  );
}

function renderInstallment({ price, count }) {
  return (
    <div className="product-color-orange">
      ou {count}x de {price}
    </div>
  );
}
