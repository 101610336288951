import React, { Component } from "react";
import LoggedHeader from "Components/LoggedHeader";
import Button from "Components/Button";
import GridLayout from "Layouts/GridLayout";
import PasswordInput from "Components/PasswordInput";
import { connect } from "react-redux";
import BFFWeb from "Store/BFFWeb/BFFWeb.js";
import { Paths as CreditRequestPaths } from "Routes/CreditRequestRoutes";
import { Paths } from "Routes/MainPaths";
import moment from "moment";
import { formatMoney } from "../../Helpers/Formatters";
import { navigate } from "Helpers/Navigate";

import ImageIcon from "Components/ImageIcon";
import Card from "Components/Card";

import "./CreditRequestSubmit.css";

import initializeReactGA from "Services/ReactGA";

/**
 * @author Giuseppe Pereira
 * @copyright 03/2019
 * @description UI-031 - Tela de confirmação com senha da requisição de crédito.
 */
class CreditRequestSubmit extends Component {
  state = {
    password: null,
    errorMessage: ""
  };

  /**
   * @author Giuseppe Pereira, Leonardo Lucas dos Santos
   * @copyright 03/2019
   * @description Navegação para tela de aceite dos contratos.
   */

  componentDidMount = () => {
    initializeReactGA({
      pageName: "credit/submit",
      category: "EMPRESTIMO-SIMULAR",
      action: "Acesso_a_tela_de_simulacao_de_emprestimo",
      label: "Simular_emprestimo"
    });
  };

  btnProximoOnClick = () => {
    const { sessionToken } = this.props.store.user;
    const { password } = this.state;

    BFFWeb.checkPassword(password, sessionToken)
      .then(({ data }) => {
        const { code, message } = data.status;
        if (code === "8200") {
          navigate(this.props, CreditRequestPaths.CREDIT_TERMS);
        } else {
          this.setState({ errorMessage: message });
        }
      })
      .catch(error => {
        navigate(this.props, Paths.FAIL);
      });
  };

  /**
   * @author Giuseppe Pereira
   * @copyright 03/2019
   * @description callback onChange do Input de Password
   */
  onChangeInputPasswordCallback = password => {
    this.setState({ password });
  };

  format = value => formatMoney(value, { unit: "R$" });

  /**
   * @author Anderson O.
   * @copyright 04/2019
   * @returns Booleano validando preenchimento do campo de senha
   * @description Valida se campo de senha foi preenchido com 6 dígitos, habilitando o botão
   */
  disableButton = () => {
    return (
      !this.state.password ||
      (this.state.password && this.state.password.length < 6)
    );
  };

  renderContent = () => {
    const {
      store: {
        value,
        plots,
        valuePlots,
        firstInstallmentDate,
        taxaCetMensal,
        taxaCetAno,
        valorTfc,
        lastInstallmentDate,
        insurance
      }
    } = this.props;
    const { errorMessage } = this.state;
    return (
      <div>
        <div className="row">
          <Card padding={15} style={{ width: "100%" }}>
            <div class="text-center">
              <ImageIcon name="MoneyIcon" alt="Dinheiro" src="moneybag" />
            </div>
            <div className="mt-3">
              <p className="mt-1 text-center fontSize18">
                Empréstimo pessoal no valor de <br />
                <span className="font-weight-bold fontSize18">
                  {" "}
                  {this.format(value)}{" "}
                </span>
                <br />
                <span className="font-weight-bold fontSize18">
                  {plots}x {this.format(valuePlots)}
                </span>
              </p>
              <p className="text-center lineHeight1">
                <small>
                  {insurance ? "Seguro incluso" : null}
                  <br />
                  Primeira parcela {firstInstallmentDate}
                  <br />
                  Última parcela {lastInstallmentDate}
                  <br />
                </small>
              </p>
            </div>
          </Card>
          <p className="text-center">
            <small>
              CET Mensal {taxaCetMensal.replace(".", ",")}% | CET Anual{" "}
              {taxaCetAno.replace(".", ",")}% | TFC de{" "}
              {valorTfc.replace(".", ",")}
            </small>
          </p>
        </div>
        <div className="row justify-content-center">
          <div style={{ maxWidth: 350 }} className="mt-5 col">
            <p>
              <small className="font-weight-bold">
                Digite sua senha para finalizar a contratação:
              </small>
            </p>
            <PasswordInput
              onChangeValue={this.onChangeInputPasswordCallback}
              onBlur={this.onChangeInputPasswordCallback}
              value={this.state.password}
              style={{ width: "100%" }}
              className="block"
              successValidation={!errorMessage}
              errorText={errorMessage}
            />
          </div>
        </div>
      </div>
    );
  };

  /**
   * @author Giuseppe Pereira
   * @copyright 02/2019
   * @description Evento de atualização de estado ao digitar número de CPF
   */
  render = () => {
    const header = <LoggedHeader text="Fazer empréstimo" />;

    const footer = (
      <Button
        text="Próximo"
        disabled={!this.state.password}
        onClick={() => this.btnProximoOnClick()}
      />
    );
    return (
      <GridLayout
        header={header}
        content={this.renderContent()}
        footer={footer}
        coloredBackgroundSize={30}
      />
    );
  };
}

const mapStateToProps = store => {
  const { valorFinanciado, insurance, condicao } = store.creditRequest;
  const {
    valorTfc,
    taxaCetAno,
    taxaCet,
    qtdParcelas,
    parcelas,
    primeiroVencimento
  } = condicao;

  let parcela = {
    vctoParcela: "",
    valorParcela: "",
    primeiroVencimento: ""
  };
  if (parcelas && parcelas.length) parcela = parcelas[parcelas.length - 1];
  const { vctoParcela, valorParcela } = parcela;

  return {
    store: {
      value: valorFinanciado && Number(valorFinanciado).toFixed(2),
      plots: qtdParcelas,
      valuePlots: valorParcela,
      firstInstallmentDate:
        primeiroVencimento && moment(primeiroVencimento).format("DD/MM/YYYY"),
      lastInstallmentDate:
        vctoParcela && moment(vctoParcela).format("DD/MM/YYYY"),
      insurance: insurance,
      valorTfc: valorTfc || "",
      taxaCetAno: taxaCetAno || "",
      taxaCetMensal: taxaCet || "",
      user: store.user
    }
  };
};

export default connect(mapStateToProps)(CreditRequestSubmit);
