import React, { Component } from "react";
import LoggedHeader from "../../Components/LoggedHeader/LoggedHeader";
import Button from "Components/Button";
import Text from "Components/Text/Text";
import TextInput from "Components/TextInput";
import TextStep from "Components/TextStep";
import CepInput from "Components/CepInput";
import NumberInput from "Components/NumberInput";
import Select from "Components/Select";
import "./CompleteRegisterSecondStep.css";
import GridLayout from "Layouts/GridLayout";
import ServicesBFF from "Store/BFFWeb/BFFWeb";
import { connect } from "react-redux";
import { completeRegisterSecondStep } from "Store/Ducks/user";
import { bindActionCreators } from "redux";
import { Paths } from "Routes/LoanRequestRoutes";

import initializeReactGA from "Services/ReactGA";

class CompleteRegisterSecondStep extends Component {
  state = {
    cep: "",
    errorCep: "",
    adress: "",
    adressNumber: "",
    district: "",
    adresscomplement: "",
    state: "",
    listState: [
      {
        ufCod: "AC",
        ufNome: "ACRE"
      },
      {
        ufCod: "AL",
        ufNome: "ALAGOAS"
      },
      {
        ufCod: "AM",
        ufNome: "AMAZONAS"
      },
      {
        ufCod: "AP",
        ufNome: "AMAPA"
      },
      {
        ufCod: "BA",
        ufNome: "BAHIA"
      },
      {
        ufCod: "CE",
        ufNome: "CEARA"
      },
      {
        ufCod: "DF",
        ufNome: "DISTRITRO FEDERAL"
      },
      {
        ufCod: "ES",
        ufNome: "ESPIRITO SANTO"
      },
      {
        ufCod: "GO",
        ufNome: "GOIAS"
      },
      {
        ufCod: "MA",
        ufNome: "MARANHAO"
      },
      {
        ufCod: "MG",
        ufNome: "MINAS GERAIS"
      },
      {
        ufCod: "MS",
        ufNome: "MATO GROSSO DO SUL"
      },
      {
        ufCod: "MT",
        ufNome: "MATO GROSSO"
      },
      {
        ufCod: "PA",
        ufNome: "PARA"
      },
      {
        ufCod: "PB",
        ufNome: "PARAIBA"
      },
      {
        ufCod: "PE",
        ufNome: "PERNAMBUCO"
      },
      {
        ufCod: "PI",
        ufNome: "PIAUI"
      },
      {
        ufCod: "PR",
        ufNome: "PARANA"
      },
      {
        ufCod: "RJ",
        ufNome: "RIO DE JANEIRO"
      },
      {
        ufCod: "RN",
        ufNome: "RIO GRANDE DO NORTE"
      },
      {
        ufCod: "RO",
        ufNome: "RONDONIA"
      },
      {
        ufCod: "RR",
        ufNome: "RORAIMA"
      },
      {
        ufCod: "RS",
        ufNome: "RIO GRANDE DO SUL"
      },
      {
        ufCod: "SC",
        ufNome: "SANTA CATARINA"
      },
      {
        ufCod: "SE",
        ufNome: "SERGIPE"
      },
      {
        ufCod: "SP",
        ufNome: "SAO PAULO"
      },
      {
        ufCod: "TO",
        ufNome: "TOCANTINS"
      }
    ],
    listCity: [],
    city: ""
  };

  componentDidMount = () => {
    initializeReactGA({
      pageName: "loanrequest/completeregistersecondstep",
      category: "CADASTRO_COMPLETAR_CADASTRO",
      action: "Acesso_a_tela_de_cadastro",
      label: "Completar_cadastro"
    });
  };

  isFormStateValid = () => {
    const { cep, adress, adressNumber, district, state, city } = this.state;
    let validateInfo =
      cep && adress && adressNumber && district && state && city;

    return validateInfo;
  };

  /**
   * @author Antônio D. F.
   * @description chama o serviço que retorna todos os dados com base no CEP
   * @param {string} CEP
   * @copyright 06/2019
   */
  BFFgetAdress = async CEP => {
    CEP = CEP.replace(/[^\d]/g, "");
    let param = { cep: CEP };
    try {
      let response = await ServicesBFF.adressList(param);
      const { data } = response;
      const { addressLine, city, district, state } = data;
      this.setState({
        city: city
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase(),
        adress: addressLine,
        district,
        state,
        errorCep: ""
      });

      this.BFFCityList(state);
    } catch (error) {
      const { response } = error;
      const { data } = response;
      const { mensagem } = data;
      this.setState({ errorCep: mensagem });
    }
  };

  BFFCityList = async state => {
    let param = { listaCidade: state };
    let response = await ServicesBFF.cityList(param);
    this.setState({ listCity: response.data.cidadeList });
  };

  onChangeCEP = cep => {
    this.setState({ cep });
    if (cep.length === 9) this.BFFgetAdress(cep);
  };

  onChangeAdress = adress => {
    this.setState({ adress });
  };

  onChangeAdressNumber = adressNumber => {
    this.setState({ adressNumber });
  };

  onChangeDistrict = district => {
    this.setState({ district });
  };

  onChangeAdressComplement = adresscomplement => {
    this.setState({ adresscomplement });
  };

  onChangeState = e => {
    let state = e.target.value;
    if (state) {
      this.BFFCityList(state);
      this.setState({ state });
    } else {
      this.setState({ listCity: [] });
    }
  };

  onChangeCity = e => {
    this.setState({ city: e.target.value });
  };

  onClick = () => {
    this.props.completeRegisterSecondStep(this.state);
    if (this.isFormStateValid()) {
      this.props.history.push(Paths.LR_COMP_REG_THIRD_STEP);
    }
  };

  render() {
    const header = <LoggedHeader text="Validar cadastro" />;
    const footer = (
      <Button
        text="Próximo"
        disabled={!this.isFormStateValid()}
        onClick={this.onClick}
      />
    );

    const content = (
      <div className="CompleteRegisterSecondStep">
        <TextStep current={2} total={3} />
        <Text className="Label">Endereço residencial</Text>
        <div className="form-group">
          <CepInput
            errorText={this.state.errorCep}
            name="CepInput"
            placeholder="CEP"
            required={true}
            onChange={this.onChangeCEP}
          />
        </div>

        <div className="form-row">
          <div className="col">
            <TextInput
              value={this.state.adress}
              name="adressInput"
              placeholder="Logradouro"
              required={true}
              onChangeValue={this.onChangeAdress}
            />
          </div>

          <div className="col">
            <NumberInput
              value={this.state.adressNumber}
              name="numInput"
              placeholder="Número"
              onChangeValue={this.onChangeAdressNumber}
              required={true}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col">
            <TextInput
              value={this.state.district}
              name="bairroInput"
              placeholder="Bairro"
              textOnly
              required={true}
              onChangeValue={this.onChangeDistrict}
            />
          </div>
          <div className="col">
            <TextInput
              value={this.state.adresscomplement}
              name="complementoInput"
              placeholder="Complemento (opcional)"
              onChangeValue={this.onChangeAdressComplement}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col">
            <Select
              name="stateSelect"
              placeholder="UF"
              onChange={this.onChangeState}
              required={true}
              value={this.state.state}
              labelKey="idUf"
              label="ufCod"
              options={this.state.listState}
            />
          </div>
          <div className="col">
            <Select
              name="citySelect"
              onChange={this.onChangeCity}
              value={this.state.city}
              required={true}
              placeholder="Cidade"
              labelKey="idCidade"
              label="cidadeNome"
              options={this.state.listCity}
            />
          </div>
        </div>
      </div>
    );
    return (
      <GridLayout
        alignContent="center"
        alignFooter="center"
        alignHeader="center"
        content={content}
        header={header}
        footer={footer}
      />
    );
  }
}

const mapDispathToProps = dispatch =>
  bindActionCreators({ completeRegisterSecondStep }, dispatch);

const mapStateToProps = store => ({
  cep: store.user.cep,
  adress: store.user.adress,
  adressNumber: store.user.adressNumber,
  district: store.user.district,
  adresscomplement: store.user.adresscomplement,
  state: store.user.state,
  city: store.user.city
});

export default connect(
  mapStateToProps,
  mapDispathToProps
)(CompleteRegisterSecondStep);
