import {
  browserName,
  browserVersion,
  isBrowser,
  isMobile,
  isWindows,
  isConsole,
  mobileModel,
  mobileVendor,
  osName,
  deviceType,
} from "react-device-detect";

const device_info = {
  browser_name: browserName ? browserName : "err",
  browser_version: browserVersion ? browserVersion : "err",
  is_browser: isBrowser ? isBrowser : "err",
  is_mobile: isMobile ? isMobile : "err",
  is_windows: isWindows ? isWindows : "err",
  is_console: isConsole ? isConsole : "err",
  mobile_model: mobileModel ? mobileModel : "err",
  mobile_vendor: mobileVendor ? mobileVendor : "err",
  os_name: osName ? osName : "err",
  device_type: deviceType ? deviceType : "err",
};

export const setAppVersion = config => ({
  ...config,
  headers: {
    ...(config.headers || {}),
    AppVersion: process.env.REACT_APP_VERSION
  }
});

export const setDeviceInfos = (config) => ({
  ...config,
  headers: {
    ...(config.headers || {}),
    deviceInfos: JSON.stringify(device_info),
  },
});

export const setPlatform = config => ({
  ...config,
  headers: {
    ...(config.headers || {}),
    device: `web_Lebes_1.0.20`
  }
});

export const setTokenJWT = (config, tokenJWT) =>
  tokenJWT
    ? {
        ...config,
        headers: {
          ...(config.headers || {}),
          Authorization: `Bearer ${tokenJWT}`
        }
      }
    : config;

export const setSessionToken = (config, sessionToken) =>
  sessionToken
    ? {
        ...config,
        data: {
          ...(config.data || {}),
          sessionToken
        }
      }
    : config;
