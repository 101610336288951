import * as React from 'react'
// import itauImage from '../../../images/itau-150x60.jpeg';
import caixaImage from '../../../images/caixa.png';

const Header = (props) => {
  const {
    linhaDigitavel,
    banco,
    onImageLoad = () => null,
  } = props
  return (
    <table
      cellSpacing="0"
      cellPadding="0"
    >
      <thead>
      <tr
        className="topLine"
      >
        <td
          className="bankLogo"
        >
          <img
            onLoad={onImageLoad}
            src={caixaImage}
            alt="logo"
            style={{
              maxWidth: 150,
              paddingTop: 10,
              paddingBottom: 10
            }}
          />
        </td>
        <td
          className="sideBorders center"
        >
              <span
                style={{
                  fontSize: 16,
                  fontWeight: 'bold'
                }}
              >
                {banco}
              </span>
        </td>
        <td
          style={{
            fontSize: 10,
            fontWeight: 'bold'
          }}
        >
          <span>{linhaDigitavel}</span>
        </td>
      </tr>
      </thead>
    </table>
  )
}

export default Header
