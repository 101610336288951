import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

/* Ducks */
import user from "./Ducks/user";
import loggedUser from "./Ducks/loggedUser";
import creditRequest from "./Ducks/creditRequest";
import loading from "./Ducks/loading";
import conditions from "./Ducks/conditions";
import creditBankTransfer from "./Ducks/creditBankTransfer";
import chaordicProducts from "./Ducks/chaordicProducts";
import clientInstallments from "./Ducks/clientInstallments";
import stores from "./Ducks/stores";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
/* Ducks */

const persistConfig = {
  key: "lebesRoot",
  storage,
  whitelist: ["user", "loggedUser"]
};

const reducers = combineReducers({
  user,
  creditRequest,
  loggedUser,
  conditions,
  loading,
  creditBankTransfer,
  chaordicProducts,
  clientInstallments,
  stores
});

const persistedReducer = persistReducer(persistConfig, reducers);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(thunk))

const store = createStore(persistedReducer, enhancer);

const persistor = persistStore(store);

export { store, persistor };
