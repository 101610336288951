import React, { Component } from "react";
import "./InstallmentValueConfirmation.css";

import { Link } from "react-router-dom";

import GridLayout from "Layouts/GridLayout/GridLayout";

import LoggedHeader from "Components/LoggedHeader/";

import { connect } from "react-redux";

import TextMoney from "../../Components/TextMoney/TextMoney";

import { FaCopy } from "react-icons/fa";

import { copyStringToClipboard } from "../../Helpers";

import initializeReactGA from "Services/ReactGA";

import { Paths as LoanPaths } from "Routes/LoanRoutes";

import Button from "Components/Button/Button";

import { formatDate } from '../../Helpers/Formatters';

class InstallmentValueConfirmation extends Component {
  state = {
    copied: false
  };

  componentDidMount() {
    if(!this.props.barcode) window.location.href = '/getcpf';
    // initializeReactGA({
    //   pageName: "loan/installmentvalueconfirmation",
    //   category: "PAGAR_PARCELA",
    //   action: "Acesso_a_tela_de_geracao_de_boletos",
    //   label: "Gerar_codigo_para_pagamento_de_boletos"
    // });
  }

  onPressCopyToClipboard = () => {
    copyStringToClipboard(this.props.barcode);
    this.setState({ copied: true }, () =>
      setTimeout(() => this.setState({ copied: false }), 2000)
    );
  };

  render() {
    const { copied } = this.state;
    return (
      <GridLayout
        header={
          <LoggedHeader
            navFunction={() => (window.location.href = LoanPaths.EASY_INSTALLMENTS ) }
            text=""
          />
        }
        content={
          <div>
            <div className="row">
              <div className="col-12 text-center">
                <h3 className="mb-0">Valor do boleto</h3>
              </div>
              <div className="col-12 text-center">
                <TextMoney
                  bold
                  style={{ fontSize: 40 }}
                  value={this.props.installmentValue}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-12">
                Seu boleto vence dia{" "}
                <strong>
                  {formatDate(this.props.expirationDate)}
                </strong>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-12">
                Para pagamentos por boleto bancário será acrescentado o valor de{" "}
                <TextMoney
                  bold
                  value={this.props.installmentsData.taxaEmissaoBoleto}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-12">
                Você pode copiar o código abaixo para realizar o pagamento do
                boleto:
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-12">
                <button
                  className="btn btn-block ButtonCopy"
                  onClick={this.onPressCopyToClipboard}
                >
                  <div className="row">
                    <div className="col-2 align-self-center text-orange">
                      <FaCopy style={{ fontSize: 30 }} />
                    </div>
                    <div className="col-10 text-left">{this.props.barcode}</div>
                  </div>
                </button>
              </div>
            </div>
            {copied && (
              <div className="text-center font-weight-bold">Copiado!</div>
            )}

               <div className="row">
               <div className="col-12 text-center">
                 <Link
                   to={{
                     pathname: "/geraBoleto",
                     state: { clientStallments: this.props.clientInstallments, cpf: this.props.cpf }
                 }}>
                   <Button text="Gerar boleto" />
                 </Link>
               </div>
             </div>

          </div>
        }
      />
    );
  }
}

const mapStateToProps = ({ clientInstallments, user }) => ({
  clientInstallments,
  barcode: clientInstallments.linhaDigitavel,
  installmentValue: clientInstallments.valorTotal,
  expirationDate: clientInstallments.vencimento,
  installmentsData: clientInstallments.installmentsData,
  cpf: user.cpf,
});

export default connect(mapStateToProps)(InstallmentValueConfirmation);
