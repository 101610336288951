import React from "react";
import { Route } from "react-router";

import CompleteRegisterFirstStep from "Containers/CompleteRegisterFirstStep";
import CompleteRegister from "Containers/CompleteRegister/CompleteRegister";
import CompleteRegisterSecondStep from "Containers/CompleteRegisterSecondStep";
import CompleteRegisterThirdStep from "Containers/CompleteRegisterThirdStep";
import CompleteRegisterSuccess from "Containers/CompleteRegisterSuccess";
import { Paths as RoutePaths } from "./MainPaths";

const { LOAN_REQUEST } = RoutePaths;

export const Paths = {
  /* Introdução ao fluxo -> UI-012 */
  LOAN_REQUEST: LOAN_REQUEST,
  /* Primeira etapa -> UI-009 */
  LR_COMP_REG_FIRST_STEP: LOAN_REQUEST + "/completeregisterfirststep",
  /* Segunda etapa -> UI-010 */
  LR_COMP_REG_SECOND_STEP: LOAN_REQUEST + "/completeregistersecondstep",
  /* Terceira etapa -> UI-011*/
  LR_COMP_REG_THIRD_STEP: LOAN_REQUEST + "/completeregisterthirdstep",
  /* Feedback de sucesso. -> UI-008 */
  LR_COMP_REG_SUCCESS: LOAN_REQUEST + "/completeregistersuccess"
};

export const Routes = (
  <>
    <Route exact path={Paths.LOAN_REQUEST} component={CompleteRegister} />
    <Route
      exact
      path={Paths.LR_COMP_REG_FIRST_STEP}
      component={CompleteRegisterFirstStep}
    />
    <Route
      exact
      path={Paths.LR_COMP_REG_SECOND_STEP}
      component={CompleteRegisterSecondStep}
    />
    <Route
      exact
      path={Paths.LR_COMP_REG_THIRD_STEP}
      component={CompleteRegisterThirdStep}
    />
    <Route
      exact
      path={Paths.LR_COMP_REG_SUCCESS}
      component={CompleteRegisterSuccess}
    />
  </>
);
