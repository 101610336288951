import React from "react";
import CodeInput from "Components/CodeInput/CodeInput";
import PhoneInputEditable from "Components/PhoneInputEditable/PhoneInputEditable";
import Text from "Components/Text/Text";
import GridLayout from "Layouts/GridLayout/GridLayout";
import LoggedHeader from "Components/LoggedHeader/LoggedHeader";
import { Paths } from "Routes/UserRoutes";
import { registerPhone } from "Store/Ducks/user";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./CodeConfirm.css";
import { navigate } from "Helpers/Navigate";

import initializeReactGA from "Services/ReactGA";

class CodeConfirm extends React.Component {
  state = {
    phoneNumber: "",
    completeCustomerCreationMessage: ""
  };

  handleChangeCode = challenge => {
    if (challenge) {
      const { phoneNumber } = this.state;
      let initialData = { challenge, phoneNumber };
      this.props.registerPhone(initialData);
      navigate(this.props, Paths.USER_PASSWORD_CONFIRM);
    }
  };

  componentDidMount() {
    this.setState({ phoneNumber: this.props.user.phoneNumber });
    initializeReactGA({
      pageName: "user/codeconfirm",
      category: "HOME-LOGIN-CADASTRO",
      action: "Acesso_a_tela_para_cadastro_ou_login",
      label: "cadastrar_SMS"
    });
  }

  handleChangePhone = phoneNumber => this.setState({ phoneNumber });

  render() {
    const {
      phoneNumber,
      startCustomerCreationMessage,
      completeCustomerCreationMessage
    } = this.state;
    const content = (
      <div>
        <Text styleCss="TextHeader">
          <p>
            Enviamos por <b>SMS</b> o código de 6 dígitos para o número:
          </p>
        </Text>
        <PhoneInputEditable
          className="col-12"
          phone={phoneNumber}
          onChange={this.handleChangePhone}
          errorText={startCustomerCreationMessage}
        />
        <CodeInput
          errorText={completeCustomerCreationMessage}
          onChange={this.handleChangeCode}
          className="col-6"
        />
        <Text>
          <p>
            Não recebeu o código?{" "}
            <a href="https://www.lebes.com.br/facilita-lebes">
              <u>
                <b>Clique aqui</b>
              </u>
            </a>
          </p>
        </Text>
      </div>
    );

    const header = <LoggedHeader text="Cadastro Lebes" hasNavigation={false} />;

    return (
      <div className="CodeConfirm">
        <GridLayout
          alignContent="center"
          alignFooter="center"
          alignHeader="center"
          content={content}
          header={header}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });
const mapActionsToProps = dispatch =>
  bindActionCreators({ registerPhone }, dispatch);

export default connect(
  mapStateToProps,
  mapActionsToProps
)(CodeConfirm);
