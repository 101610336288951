// TODO: Ajustar depara de valor
export function formatToInputRadioBox(items) {
  return items.map((item, index) => {
    const value = item.parcelas[0].valorParcela;
    return {
      ...item,
      label: item.qtdParcelas,
      text: value,
      isSelected: false,
      value: index
    };
  });
}
