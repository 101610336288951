import axios from "axios";
import { store } from "..";
import redirectToErrorPage from "./Helpers/Navigate";
import {
  setAppVersion,
  setDeviceInfos,
  setTokenJWT,
  setSessionToken,
  setPlatform,
} from "./Helpers/MountHeaders";
import cryptography from "../../Helpers/cryptography-helper";
import CRYPTOGRAPHY_CONSTANTS from "../../Config/cryptography-constants";

const api = axios.create({
  baseURL: process.env.REACT_APP_BFF_WEB_API,
  timeout: Number(process.env.REACT_APP_TIMEOUT_API),
});

api.interceptors.request.use(
  function (config) {
    switch (config.url.replace(config.baseURL, "")) {
      case "/cstmr/gtcstmrstts":
        break;
      case "/cstmr/strtcmrcrtn":
        break;
      case "/cstmr/ddfcmtkn":
        break;
      default:
        store.dispatch({ type: "loading/start" });
        break;
    }

    const {
      user: { sessionToken, tokenJWT },
    } = store.getState();

    if (CRYPTOGRAPHY_CONSTANTS.PRODUCTION.ACTIVE) {
      config.data = cryptography.encrypt(config.data);
    }

    let newConfig = { ...config };

    newConfig = setTokenJWT(newConfig, tokenJWT);
    newConfig = setSessionToken(newConfig, sessionToken);
    newConfig = setAppVersion(newConfig);
    newConfig = setDeviceInfos(newConfig);
    newConfig = setPlatform(newConfig);

    return newConfig;
  },
  function (error) {
    switch (error.config.url.replace(error.config.baseURL, "")) {
      case "/cstmr/gtcstmrstts":
        break;
      case "/cstmr/strtcmrcrtn":
        break;
      case "/cstmr/ddfcmtkn":
        break;
      default:
        store.dispatch({ type: "loading/stop" });
        break;
    }

    redirectToErrorPage(error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    switch (response.config.url.replace(response.config.baseURL, "")) {
      case "/cstmr/gtcstmrstts":
        break;
      case "/cstmr/strtcmrcrtn":
        break;
      case "/cstmr/ddfcmtkn":
        break;
      default:
        store.dispatch({ type: "loading/stop" });
        break;
    }

    if (CRYPTOGRAPHY_CONSTANTS.PRODUCTION.ACTIVE) {
      // decrypt data
      response.data = cryptography.decryptChunks(response.data.chunks);

      if (response.data && response.data.data.tokenJWT) {
        store.dispatch({
          type: "user/LOGIN",
          payload: { tokenJWT: response.data.data.tokenJWT },
        });
      }
    }

    return response.data;
  },
  function (error) {
    switch (error.config.url.replace(error.config.baseURL, "")) {
      case "/cstmr/gtcstmrstts":
        break;
      case "/cstmr/strtcmrcrtn":
        break;
      case "/cstmr/ddfcmtkn":
        break;
      default:
        store.dispatch({ type: "loading/stop" });
        break;
    }

    if (
      error.response &&
      error.response.data.chunks &&
      error.response.data.origin &&
      CRYPTOGRAPHY_CONSTANTS.PRODUCTION.ACTIVE
    ) {
      error.response.data = cryptography.decryptChunks(
        error.response.data.chunks
      );
    }

    // logs errors
    // console.log("URL:", error.config.url, "Response ERROR:", error.response);

    redirectToErrorPage(error);
    return Promise.reject(error);
  }
);

export const WEBBFFRoutes = {
  getCustomerStats: "/cstmr/gtcstmrstts",
  startCustomerCreation: "/cstmr/strtcmrcrtn",
  completeCustomerCreation: "/cstmr/cmpltcmrcrtn",
  setPassword: "/cstmr/stpsswrd",
  customerLogin: "/cstmr/cstmrlgn",
  passwordRecovery: "/cstmr/psswrdrcvr",
  status: "/cstmr/status",
  getPersonalData: "/cstmr/ccntdt",
  getJobList: "/lst/lstprfss",
  stateList: "/lst/lstf",
  adressList: "/cstmr/gtddrss",
  cityList: "/lst/lstcdd",
  lojasList: "/lst/lstlj",
  getBanner: "/lst/gtbnnr",
  insertCustomerLebes: "/cstmrlbs/nsrtcstmr",
  updateCustomerLebes: "/cstmrlbs/tlzclnt",
  getParcelas: "/cstmrlbs/gtprcl",
  searchCustomer: "/cstmrlbs/srchcstmr",
  checkPassword: "cstmr/chckpsswrd",
  getContract: "cstmrlbs/gtcntrct",
  submitContract: "/cstmrlbs/sbmttcntrct",
  getFinancialData: "/cstmrlbs/cstmrfnnclstts",
  listBanks: "/cstmr/lstbnks",
  addBankAccount: "/cstmr/ddbnkccnt",
  bankAccounts: "/cstmr/ccntdt",
  submitBankTranfer: "/cstmr/tdtrnsfr",
  getBalance: "/cstmr/blnc",
  listChaordicProducts: "/cstmrlbs/bscprdts",
  getClientEasyInstalmments: "/cstmrlbs/cstprclsclnt",
  getClientInstalmments: "/cstmrlbs/prclsclnt",
  generateEasyTicket: "/cstmrlbs/emtblt",
  generateTicket: "/cstmrlbs/mtblt",
  buscaPrimeiroPagamento: "/cstmrlbs/bscprmrpgmnt",
  defineWithdrawalLoan: "/cstmrlbs/rtrmprstm",
  retirarEmprestimo: "/cstmrlbs/rtrmprstm",
  completePasswordRecover: "/cstmr/cmpltpsswrdrcvr",
  buscaTermosCondicoes: "/cstmrlbs/bsctrmscndcs",
  sendFcmToken: "/cstmr/ddfcmtkn",
  sendNotification: "/cstmrlbs/mtntfcc",
  receiveToken: "/cstmr/lgntkn",
  checkValidToken: "cstmr/cmpltlgntkn",
};

export default api;
