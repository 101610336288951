import React, { Component } from "react";
import Text from "Components/Text/Text";
import Button from "Components/Button/Button";
import "./PersonalCreditSimulation.css";
import InputRadioBoxes from "Components/InputRadioBoxes";
import GridLayout from "Layouts/GridLayout";
import { connect } from "react-redux";
import LoggedHeader from "Components/LoggedHeader";
import { setCreditRequest } from "Store/Ducks/creditRequest";
import { Paths as CreditRequestPaths } from "Routes/CreditRequestRoutes";
import { formatToInputRadioBox } from "./helper";

import initializeReactGA from "Services/ReactGA";
import Card from "Components/Card/Card";
import { handleGetConditions } from "../../Store/Ducks/conditions";
import DateText from "Components/DateText/DateText";
import { formatMoney } from "Components/InputRange/Format";
import ImageIcon from "Components/ImageIcon";

class PersonalCreditSimulation extends Component {
  state = {
    plan: null
  };

  componentDidMount = () => {
    const { handleGetConditions } = this.props;
    handleGetConditions();
    initializeReactGA({
      pageName: "credit/installments",
      category: "EMPRESTIMO-SIMULAR",
      action: "Acesso_a_tela_de_simulacao_de_emprestimo",
      label: "Simular_emprestimo"
    });
  };

  submit = () => {
    const { plan } = this.state;
    const { setCreditRequest, conditions } = this.props;
    setCreditRequest({ condicao: conditions[plan] });
    this.props.history.push(CreditRequestPaths.CREDIT_REQUEST_SUBMIT);
  };

  renderContent = () => {
    const { plan } = this.state;
    let {
      conditions,
      creditRequest: { firstInstallmentDate, valorFinanciado, insurance } = {}
    } = this.props;
    return (
      <div
        className="PersonalCreditSimulation"
        style={{ marginBottom: "-5vh", marginTop: "-3vh" }}
      >
        <Card padding={10} className="my-3">
          <ImageIcon src="moneybag" />
          <Text>
            <div className="text-lg">
              Valor solicitado <b>R$ {formatMoney(valorFinanciado)}</b>
            </div>
          </Text>
          <Text>{this.renderInsurance(insurance)}</Text>
          <Text>
            <div>
              Dia para Pagamento <DateText value={firstInstallmentDate} />
            </div>
          </Text>
        </Card>
        <Card padding={10} className="mb-3 mt-3">
          <ImageIcon src="wallet" className="mt-2" />
          <Text className="my-2 text-lg">
            Em quantas<b> parcelas </b>
            você deseja pagar?
          </Text>
          <div style={{ width: 350 }} className="mx-auto">
            <InputRadioBoxes
              onChangeValue={plan => this.setState({ plan })}
              value={plan}
              itens={formatToInputRadioBox(conditions)}
            />
          </div>
        </Card>
      </div>
    );
  };

  renderInsurance(insurance) {
    return insurance && <div>Seguro incluso</div>;
  }

  render() {
    const { plan } = this.state;

    const header = <LoggedHeader text="Fazer empréstimo" />;

    const footer = (
      <Button
        className="btn"
        text="Próximo"
        disabled={!plan && plan !== 0}
        onClick={() => this.submit()}
      />
    );

    return (
      <GridLayout
        coloredBackgroundSize={50}
        alignContent="center"
        alignFooter="center"
        alignHeader="center"
        content={this.renderContent()}
        header={header}
        footer={footer}
      />
    );
  }
}

const mapStateToProps = ({ conditions, creditRequest }) => {
  return {
    conditions,
    creditRequest
  };
};

export default connect(
  mapStateToProps,
  { handleGetConditions, setCreditRequest }
)(PersonalCreditSimulation);
