import React, { Component } from "react";
import HeaderLogo from "Components/Headerlogo/HeaderLogo";
import CpfInput from "Components/CpfInput/CpfInput";
import Button from "Components/Button";
import Label from "Components/Label/Label";
import PasswordInput from "Components/PasswordInput";
import { Link } from "react-router-dom";
import _ from "lodash";
import "./LoginPwd.css";
import GridLayout from "Layouts/GridLayout";
import ServicesBFF from "Store/BFFWeb/BFFWeb";
import ServicesBFFCustomerLebes from "Store/BFFWeb/BFFWebCustomerLebes";
import { connect } from "react-redux";
import { login, getPersonalData } from "Store/Ducks/user";
import { Paths as UserPaths } from "Routes/UserRoutes";
import { navigate } from "Helpers/Navigate";
import initializeReactGA from "Services/ReactGA";

class LoginPwd extends Component {
  componentDidMount = () => {
    this.setState({ cpf: this.props.cpf });
    initializeReactGA({
      pageName: "user/login",
      category: "HOME-LOGIN-CADASTRO",
      action: "Acesso_a_tela_para_cadastro_ou_login",
      label: "logar_CPF"
    });
  };

  state = {
    cpf: "",
    password: "",
    validCPF: "OK",
    validPassword: "OK",
    errorValidation: ""
  };

  mensagemErrorOps = mensagem => `Ops! ${mensagem.replace(/\[.*?\]/g, "")} :(`;

  isFormStateValid = () => {
    const { validCPF, validPassword, cpf, password } = this.state;
    let validateInfo =
      validCPF === "OK" &&
      validPassword === "OK" &&
      !_.isEmpty(cpf) &&
      !_.isEmpty(password);
    return validateInfo;
  };

  onClickBtnLogin = async () => {
    const { cpf, password } = this.state;
    if (this.isFormStateValid) {
      const payload = {
        identifier: {
          cpf
        },
        password
      };

      ServicesBFF.customerLogin(payload)
        .then(res => {
          const {
            data: {
              status: { message }
            }
          } = res;
          if (message !== "Authorized") {
            this.setState({
              errorValidation: this.mensagemErrorOps(message)
            });
          } else {
            // action para setar session Token
            this.props.login(cpf, res.data.sessionToken, res.data.tokenJWT);

            // action set info do usuario logado
            this.consultAccountData(res.data.sessionToken).then(() => {
              navigate(this.props, "/");
            });
          }
        })
        .catch(err => {
          if (err.response) {
            const {
              data: {
                status: { message }
              }
            } = err.response;

            this.setState({
              errorValidation: this.mensagemErrorOps(message)
            });
          }
        });
    }
  };

  onChangeCpfInput = cpf => {
    this.setState({ cpf: cpf.replace(/[^\d]+/g, ""), errorValidation: "" });
  };

  onChangePasswordInput = password => {
    this.setState({ password, errorValidation: "" });
  };

  isPasswordInputValid = () => {
    const { errorValidation, validPassword } = this.state;
    return validPassword === "OK" && errorValidation === "";
  };

  isCpfInputValid = () => {
    const { validCPF, errorValidation } = this.state;
    return validCPF === "OK" && errorValidation === "";
  };

  getCpfInputErrorText = () => {
    const { validCPF } = this.state;
    return validCPF === "OK" ? "" : validCPF;
  };

  getPasswordInputErrorText = () => {
    const { validPassword } = this.state;
    return validPassword === "OK" ? "" : validPassword;
  };

  setAccountData = data => {
    let cliente = {
      cpf: data.cpf,
      birthdate: data.dataNascimento,
      emailAddress: data.email,

      companyWork:
        data.emprego.empresa.nomeFantasia !== "?"
          ? data.emprego.empresa.nomeFantasia
          : "",
      job: data.emprego.profissao !== "?" ? data.emprego.profissao : "",
      money: data.emprego.renda,

      cep: data.enderecoResidencial.cep,
      city: data.enderecoResidencial.cidade,
      addressComplement: data.enderecoResidencial.complemento,
      addressNumber: data.enderecoResidencial.numero,
      address: data.enderecoResidencial.rua,
      state: data.enderecoResidencial.ufCod,

      rg: data.identidade,
      fullName: data.nome,
      motherName: data.nomeMae,

      gender: data.sexo,
      phoneNumber: data.telefoneList[0].telefone
    };

    if (
      data.referencias[0] &&
      data.referencias[0].refNome &&
      data.referencias[0].refTelefoneList[0] &&
      data.referencias[0].refTelefoneList[0].telefone
    ) {
      cliente = {
        ...cliente,
        firstNameRefer: data.referencias[0].refNome,
        firstPhoneRefer: data.referencias[0].refTelefoneList[0].telefone
      };
    }

    if (
      data.referencias[1] &&
      data.referencias[1].refNome &&
      data.referencias[1].refTelefoneList[0] &&
      data.referencias[1].refTelefoneList[0].telefone
    ) {
      cliente = {
        ...cliente,
        secondNameRefer: data.referencias[1].refNome,
        secondPhoneRefer: data.referencias[1].refTelefoneList[0].telefone
      };
    }

    return cliente;
  };

  consultAccountData = async sessionToken => {
    const json = {
      sessionToken: sessionToken,
      fullContent: true,
      cpfCliente: this.state.cpf
    };

    let response = await ServicesBFF.getPersonalData(json);
    let { cpf } = response.data;
    let cliente = {};
    if (response.data && response.data.identidade) {
      cliente = this.setAccountData(response.data);
    }

    const financialData = await ServicesBFFCustomerLebes.getFinancialData(
      cpf,
      sessionToken
    );
    const { respLimiteDisponivel, respValorDisponivel } = financialData.data;

    const ultimoStatus = financialData.data.ultimoStatus
      ? financialData.data.ultimoStatus[0]
        ? financialData.data.ultimoStatus[0].status
        : ""
      : "";
    const payload = {
      ...cliente,
      respLimiteDisponivel,
      respValorDisponivel,
      ultimoStatus
    };
    this.props.getPersonalData(payload);
  };

  render() {
    const { cpf, password, errorValidation } = this.state;

    const content = (
      <>
        {this.renderCPFInput(cpf)}
        {this.renderPasswordInput(password)}
        {this.renderPasswordRecovery()}
        {this.renderError(errorValidation)}
      </>
    );

    return (
      <GridLayout
        alignContent="center"
        alignFooter="center"
        alignHeader="center"
        content={content}
        header={<HeaderLogo />}
        footer={
          <Button
            name="LoginProximo"
            text="Próximo"
            disabled={!this.isFormStateValid()}
            onClick={this.onClickBtnLogin}
          />
        }
      />
    );
  }

  renderError(errorValidation) {
    return (
      <div className="row">
        <div className="col-12 text-center text-danger">{errorValidation}</div>
      </div>
    );
  }

  renderPasswordRecovery() {
    return (
      <Label
        styleCss="TextHeader"
        text={
          <label className="">
            <u>
              <Link name="LinkEsqueciSenha" to={UserPaths.USER_RECOVERY}>
                Esqueci minha senha
              </Link>
            </u>
          </label>
        }
      />
    );
  }

  renderPasswordInput(password) {
    return (
      <div className="d-flex justify-content-center">
        <div style={{ maxWidth: 350 }} className="form-group col p-0">
          <PasswordInput
            name="passwordInput"
            placeholder="Senha"
            text={password}
            maxLength={6}
            password={password}
            onChangeValue={this.onChangePasswordInput}
            successValidation={this.isPasswordInputValid()}
            errorText={this.getPasswordInputErrorText()}
          />
        </div>
      </div>
    );
  }

  renderCPFInput(cpf) {
    return (
      <div className="form-group">
        <Label styleCss="TextHeader" text="Agora informe a sua senha" />
        <div className="d-flex justify-content-center">
          <div style={{ maxWidth: 350 }} className="col p-0">
            <CpfInput
              name="cpfInput"
              text={cpf}
              defaultValue={this.props.cpf}
              onChangeValue={this.onChangeCpfInput}
              successValidation={this.isCpfInputValid()}
              errorText={this.getCpfInputErrorText()}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  cpf: user.cpf,
  respLimiteDisponivel: user.respLimiteDisponivel,
  respValorDisponivel: user.respValorDisponivel,
  ultimoStatus: user.ultimoStatus
});

export default connect(
  mapStateToProps,
  { login, getPersonalData }
)(LoginPwd);
