import BFFWebCustomerLebes from "../BFFWeb/BFFWebCustomerLebes";
import ServicesBFF from "../BFFWeb/BFFWeb";
import { createSelector } from "reselect";

/* Action types */
export const Types = {
  IDENTIFICATION: "user/IDENTIFICATION",
  LAZY_LOGIN: "user/LAZY_LOGIN",
  LOGIN: "user/LOGIN",
  UNSET: "user/UNSET",
  REGISTER_INITIAL_DATA: "user/REGISTER_INITIAL_DATA",
  REGISTER_PERSONAL_DATA: "user/REGISTER_PERSONAL_DATA",
  GET_PERSONAL_DATA: "user/GET_PERSONAL_DATA",
  REGISTER_PHONE: "user/REGISTER_PHONE",
  REGISTER_SESSION_TOKEN: "user/REGISTER_SESSION_TOKEN",
  REGISTER_CREATION_TOKEN: "user/REGISTER_CREATION_TOKEN",
  COMPLETE_REGISTER_FIRST_STEP: "user/COMPLETE_REGISTER_FIRST_STEP",
  COMPLETE_REGISTER_SECOND_STEP: "user/COMPLETE_REGISTER_SECOND_STEP",
  COMPLETE_REGISTER_THIRD_STEP: "user/COMPLETE_REGISTER_THIRD_STEP",
  ERASE_IMAGE_BASE_64: "user/ERASE_IMAGE_BASE_64",
  USER_DATA_LEBES: "user/USER_DATA_LEBES",
  SET_FINANCIAL_STATUS: "user/SET_FINANCIAL_STATUS"
};

/* Initial State */
const initialState = {
  _4ll: false,
  lbs: false,
  lbs_completo: null,
  cpf: "",
  fullName: "",
  phoneNumber: "",
  emailAddress: "",
  birthdate: "",
  date: "",
  isLogged: false,
  loginToken: null,
  creationToken: null,
  sessionToken: "",
  maskedPhone: null,
  sendSms: true,
  sendEmail: false,
  name: "",
  rg: "",
  companyWork: "",
  motherName: "",
  money: "",
  job: "",
  cep: "",
  address: "",
  addressNumber: "",
  district: "",
  addressComplement: "",
  state: "",
  city: "",
  firstNameRefer: "",
  secondNameRefer: "",
  firstPhoneRefer: "",
  secondPhoneRefer: "",
  respLimiteDisponivel: "",
  respValorDisponivel: "",
  ultimoStatus: "",
  challenge: "",
  gender: "",
  tokenJWT: "",
  imageBase64: ""
};

/* Reducer */
export default function user(state = initialState, { type, payload }) {
  switch (type) {
    // case Types.REGISTER_PHONE:
    //   return { ...state, phoneNumber: payload.phoneNumber };
    case Types.LOGIN:
      return { ...state, ...payload };
    case Types.UNSET:
      return initialState;
    case Types.REGISTER_PHONE:
    case Types.REGISTER_INITIAL_DATA:
    case Types.REGISTER_SESSION_TOKEN:
    case Types.REGISTER_CREATION_TOKEN:
    case Types.REGISTER_PERSONAL_DATA:
    case Types.IDENTIFICATION:
      return { ...state, ...payload };
    case Types.GET_PERSONAL_DATA:
      return { ...state, ...payload };
    case Types.COMPLETE_REGISTER_FIRST_STEP:
      return { ...state, ...payload };
    case Types.COMPLETE_REGISTER_SECOND_STEP:
      return { ...state, ...payload };
    case Types.COMPLETE_REGISTER_THIRD_STEP:
      return { ...state, ...payload };
    case Types.ERASE_IMAGE_BASE_64:
      return { ...state, ...payload };
    case Types.USER_DATA_LEBES:
      return { ...state, ...payload };
    case Types.SET_FINANCIAL_STATUS:
      return { ...state, ...payload };
    case Types.LAZY_LOGIN: 
      return { ...state, ...payload };
    default:
      return state;
  }
}

/* Action Creators start */
export function registerPhone(payload) {
  return {
    type: Types.REGISTER_PHONE,
    payload
  };
}

export function lazyLogin(payload){
  return {
    type: Types.LAZY_LOGIN,
    payload
  }
}

export function login(cpf, sessionToken, tokenJWT) {
  return {
    type: Types.LOGIN,
    payload: {
      cpf,
      sessionToken,
      tokenJWT,
      isLogged: sessionToken ? true : false
    }
  };
}

export function setUserIdentification(payload) {
  return {
    type: Types.IDENTIFICATION,
    payload: {
      ...payload
    }
  };
}

export function unsetUser() {
  return {
    type: Types.UNSET
  };
}

export function registerInitialData(payload) {
  return {
    type: Types.REGISTER_INITIAL_DATA,
    payload
  };
}

export function registerSessionToken(payload) {
  return {
    type: Types.REGISTER_SESSION_TOKEN,
    payload
  };
}

export function registerCreationToken(payload) {
  return {
    type: Types.REGISTER_CREATION_TOKEN,
    payload
  };
}

export function registerPersonalData(payload) {
  return {
    type: Types.REGISTER_PERSONAL_DATA,
    payload: payload
  };
}

export function getPersonalData(payload) {
  return {
    type: Types.GET_PERSONAL_DATA,
    payload
  };
}

export function completeRegisterFirstStep(payload) {
  payload.money = payload.money.toString();
  return {
    type: Types.COMPLETE_REGISTER_FIRST_STEP,
    payload: {
      ...payload
    }
  };
}

export function completeRegisterSecondStep(payload) {
  return {
    type: Types.COMPLETE_REGISTER_SECOND_STEP,
    payload: {
      ...payload
    }
  };
}

export function completeRegisterThirdStep(payload) {
  return {
    type: Types.COMPLETE_REGISTER_THIRD_STEP,
    payload: {
      ...payload
    }
  };
}

/**
 * @author Antônio D. F.
 * @copyright 06/2019
 * @description limpa o base64 armazenado na store
 */
export function eraseImageBase64() {
  return {
    type: Types.ERASE_IMAGE_BASE_64,
    payload: {
      imageBase64: ""
    }
  };
}

export function userDataLebes(payload) {
  return {
    type: Types.USER_DATA_LEBES,
    payload: {
      ...payload
    }
  };
}

/**@author Anderson O.
 * @copyright 04/2019
 * @return Action de atualizacao de dados financeiros
 * @description Atualiza estado de dados financeiros referentes a solicitacao de emprestimo
 */
export function setFinancialStatus(payload) {
  return {
    type: Types.SET_FINANCIAL_STATUS,
    payload
  };
}

/**@author Anderson O.
 * @copyright 04/2019
 * @return Thunk Action de consulta e atualizacao de dados financeiros
 * @description Consulta BFF de dados financeiros e atualiza estado dos dados referentes a solicitacao de emprestimo
 */
export function handleSetFinancialStatus() {
  return (dispatch, getState) => {
    const { user } = getState();
    const { cpf, sessionToken } = user;

    BFFWebCustomerLebes.getFinancialData(cpf, sessionToken).then(res => {
      const {
        respLimiteDisponivel,
        respValorDisponivel,
        statusUsuario,
        reproveTime,
        enderecoRetirada
      } = res.data;

      const ultimoStatus = res.data.ultimoStatus
        ? res.data.ultimoStatus[0]
          ? res.data.ultimoStatus[0].status
          : ""
        : "";

      const payload = {
        respLimiteDisponivel,
        respValorDisponivel,
        statusUsuario,
        ultimoStatus,
        reproveTime,
        enderecoRetirada
      };

      dispatch(setFinancialStatus(payload));
      dispatch(updateUserStatus());
    });
  };
}

export function updateUserStatus() {
  return (dispatch, getState) => {
    try {
      dispatch({ type: "loading/start" });
      const {
        user: { cpf }
      } = getState();
      ServicesBFF.getCustomerStats(cpf).then(({ data: { status } }) => {
        dispatch(setUserIdentification(status));
      });
      dispatch({ type: "loading/stop" });
    } catch (error) {
      dispatch({ type: "loading/stop" });
    }
  };
}

const navigateCreditRequest = createSelector(
  state => state.isLogged,
  state => state.respValorDisponivel,
  state => state.ultimoStatus,
  (isLogged, respValorDisponivel, ultimoStatus) => {
    return (
      isLogged &&
      Number(respValorDisponivel) > 0 &&
      ultimoStatus !== "P" &&
      ultimoStatus !== "A"
    );
  }
);
export { navigateCreditRequest };
