import React, { Component } from "react";
import { connect } from "react-redux";
import ServicesBFF from "../../Store/BFFWeb/BFFWeb";
import GridLayout from "Layouts/GridLayout";
import LoggedHeader from "Components/LoggedHeader";
import Button from "Components/Button";
import Select from "Components/Select";
import NumberInput from "Components/NumberInput";
import DialogDynamic from "Components/DialogDynamic";
import Radio from "Components/Radio";
import { Paths as TransferRoutes } from "Routes/TransferRoutes";
import { bindActionCreators } from "redux";
import { setDataBank } from "../../Store/Ducks/creditBankTransfer";
import { navigate } from "Helpers/Navigate";
import initializeReactGA from "Services/ReactGA";

class BankTransfer extends Component {
  state = {
    id: "",
    previousAccount: "",
    bank: "",
    branch: "",
    branchDigit: "",
    account: "",
    accountDigit: "",
    value: null,
    error: "",
    listBanks: [],
    bankAccounts: [],
    isShow: false,
    bankName: "",
    errorMessage: ""
  };

  mensagemErrorOps = mensagem => `Ops! ${mensagem.replace(/\[(H)\]/g, "")} :(`;

  componentDidMount() {
    initializeReactGA({
      pageName: "transfer/bank",
      category: "EMPRESTIMO-TRANSFERENCIA",
      action: "Acesso_a_tela_de_opcoes_de_transferencia",
      label: "Transferir_o_emprestimo_pessoal_para_conta_bancaria"
    });
    this.BFFBankInfo();
  }

  BFFBankInfo = async () => {
    await ServicesBFF.getBanks({
      sessionToken: this.props.user.sessionToken
    })
      .then(res => {
        let { listBanks } = res.data;
        this.setState({ listBanks });
      })
      .catch(err => {
        if (err.response) {
          let { data } = err.response;
          let { message } = data.status;
          alert(message);
        } else {
          alert(err);
        }
      });

    ServicesBFF.getCustomerBankAccounts({
      data: ["bankAccounts"],
      sessionToken: this.props.user.sessionToken
    })
      .then(res => {
        const { bankAccounts } = res.data;
        this.setState({ bankAccounts });
      })
      .catch(err => {
        if (err.response) {
          let { data } = err.response;
          let { message } = data.status;
          alert(message);
        } else {
          alert(err);
        }
      });
  };

  reduxStates = () => {
    let info = {
      id: this.state.id,
      bank: this.state.bank,
      bankName: this.state.bankName,
      branch: this.state.branch,
      branchDigit: this.state.branchDigit,
      account: this.state.account,
      accountDigit: this.state.accountDigit
    };
    return info;
  };

  onPress = async () => {
    await this.addBankAccount();
    if (this.state.id) {
      await this.props.setDataBank(this.reduxStates());
      if (this.isFormStateValid()) {
        navigate(this.props, TransferRoutes.TRANSFER_DATA_CONFIRM);
      }
    }
  };

  addBankAccount = async () => {
    let bank = this.state.bankAccounts.find(el => el.id === this.state.id);
    if (bank) return;

    const bankAccount = {
      id: this.state.id,
      bank: this.state.bank.replace(/[^\d]+/g, ""),
      bankName: this.state.bankName,
      branch: this.state.branch,
      branchDigit: this.state.branchDigit ? this.state.branchDigit : "0",
      account: this.state.account,
      accountDigit: this.state.accountDigit,
      sessionToken: this.props.user.sessionToken
    };

    await ServicesBFF.addBankAccount(bankAccount)

      .then(res => {
        const {
          data: { bankAccountId }
        } = res;
        if (bankAccountId) {
          this.setState({ id: bankAccountId });
        } else {
          this.setState({
            id: "",
            errorMessage: this.mensagemErrorOps(
              "Ocorreu algum erro ao gravar sua conta, tente novamente"
            )
          });
        }
      })
      .catch(err => {
        const {
          response: {
            data: {
              status: { message }
            }
          }
        } = err;

        this.setState({
          id: "",
          errorMessage: this.mensagemErrorOps(message)
        });
      });
  };

  isFormStateValid = () => {
    const { bank, branch, account, accountDigit } = this.state;
    return bank && branch && account && accountDigit;
  };

  /**
   * @author Anderson Oliveira, Leonardo Lucas dos Santos
   * @copyright 04/2019
   * @description Método de aplicação de máscara de CPF
   */
  formatCPF = cpf => {
    if (cpf) {
      cpf = cpf.replace(/[^\d]/g, "");
      cpf = cpf.substr(0, 11);
      cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
      cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
      cpf = cpf.replace(/(\d{3})(\d)/, "$1-$2");
    }
    return cpf;
  };

  handleDialog = () => {
    if (this.state.previousAccount) {
      let bank = this.state.bankAccounts.find(
        el => el.id === this.state.previousAccount
      );
      this.setState({
        id: bank.id,
        bank: bank.bank + " - " + bank.bankName,
        bankName: bank.bankName,
        branch: bank.branch,
        branchDigit: bank.branchDigit,
        account: bank.account,
        accountDigit: bank.accountDigit
      });
    }
  };

  radioPress = value => {
    this.setState({ previousAccount: value });
  };

  onClick = () => {
    initializeReactGA({
      pageName: "transfer/bank",
      category: "EMPRESTIMO-TRANSFERENCIA",
      action: "Acesso_a_tela_de_opcoes_de_transferencia",
      label: "Selecionar_transferencias_anteriores"
    });
    this.dialog.open();
  };

  render() {
    const { bank, branch, branchDigit, account, accountDigit } = this.state;

    const previousAccounts = this.state.bankAccounts.map(account => {
      return (
        <div
          key={account.id}
          className="row no-gutters justify-content-center mb-3"
        >
          <div className="col-2 text-right">
            <Radio
              checked={this.state.previousAccount}
              id={`transfer-${account.id}`}
              value={account.id}
              onChangeValue={value => {
                this.setState({ previousAccount: value });
              }}
            />
          </div>
          <div className="col-10">
            <strong>{account.bankName}</strong> <br />
            Agência {account.branch}
            <br />
            Conta {account.account}-{account.accountDigit}
          </div>
        </div>
      );
    });

    const content = (
      <>
        <DialogDynamic
          ref={el => (this.dialog = el)}
          onSubmit={() => {
            this.props.setDataBank({ id: this.state.previousAccount });
            this.handleDialog();
            this.dialog.close();
            // navigate(this.props, TransferRoutes.TRANSFER_DATA_CONFIRM);
          }}
          btnSubmit="Selecionar"
          title="Transferências anteriores"
        >
          {previousAccounts}
        </DialogDynamic>
        <div className="text-center mb-5">
          <p>
            Você só pode transferir para contas de sua própria titularidade, tá?
          </p>
          <p className="text-center">
            <b>CPF {this.formatCPF(this.props.user.cpf)}</b>
          </p>
          Ah, a transferência só pode ser realizada das 8h às 18h.
          <br />
          Taxa do TED: R$ 2,20
        </div>

        <Button
          className="btn-block my-4"
          color="Secondary"
          text="Utilizar transferências anteriores"
          onClick={() => this.onClick()}
        />
        <div className="form-group">
          <Select
            value={bank.replace(/[^0-9]/g, "")}
            options={this.state.listBanks}
            labelKey="code"
            label="name"
            placeholder="Banco"
            required
            name="bank"
            onChangeValue={bank => {
              let selectedBank = this.state.listBanks.find(
                el => el.code === bank
              );

              if (selectedBank) {
                this.setState({
                  bank: bank,
                  bankName: selectedBank.name
                });
              }
            }}
          />
        </div>
        <div className="form-row no-gutters mb-3">
          <div className="col-8 align-self-center">
            <NumberInput
              value={branch}
              required
              maxLength={4}
              placeholder="Agência"
              name="branch"
              onChangeValue={branch => this.setState({ branch })}
            />
          </div>
          <div className="col align-self-center">
            <NumberInput
              value={branchDigit}
              placeholder="DV"
              maxLength={2}
              name="branchDigit"
              onChangeValue={branchDigit => this.setState({ branchDigit })}
            />
          </div>
        </div>
        <div className="form-row no-gutters mb-3">
          <div className="col-8 align-self-center">
            <NumberInput
              value={account}
              required
              maxLength={15}
              placeholder="Conta"
              name="account"
              onChangeValue={account => this.setState({ account })}
            />
          </div>
          <div className="col align-self-center">
            <NumberInput
              value={accountDigit}
              required
              placeholder="DV"
              maxLength={2}
              name="accountDigit"
              onChangeValue={accountDigit => this.setState({ accountDigit })}
            />
          </div>
        </div>
        <div>
          <p className="ErrorText">
            {this.state.errorMessage === "OK" ? "" : this.state.errorMessage}
          </p>
        </div>
      </>
    );

    return (
      <GridLayout
        alignContent="center"
        alignFooter="center"
        alignHeader="center"
        header={<LoggedHeader text="Transferência" />}
        content={content}
        footer={
          <div className="row">
            <div className="col-12 text-center">
              <Button
                text="Continuar"
                disabled={!this.isFormStateValid()}
                onClick={this.onPress}
              />
            </div>
            <div className="col-12 text-center text-danger">
              {this.state.error}
            </div>
          </div>
        }
      />
    );
  }
}

const mapStateToProps = ({ user, creditBankTransfer }) => ({
  user,
  creditBankTransfer
});

const mapDispathToProps = dispatch =>
  bindActionCreators({ setDataBank }, dispatch);

export default connect(
  mapStateToProps,
  mapDispathToProps
)(BankTransfer);
