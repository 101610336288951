import React, { Component } from "react";
import FeedBack from "Containers/FeedBack/FeedBack";
import { getPersonalData } from "Store/Ducks/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Paths as LoanRequestPaths } from "Routes/LoanRequestRoutes";
import { Paths as UserPaths } from "Routes/UserRoutes";
import { navigate } from "Helpers/Navigate";
import initializeReactGA from "Services/ReactGA";
/**
 * Tela de Completar Cadastro
 * UI - 012
 */
class CompleteRegister extends Component {
  componentDidMount = () => {
    this.verifySessionToken();
    this.setState({
      cpf: this.props.cpf,
      fullName: this.props.fullName,
      birthdate: this.props.birthdate
    });
    initializeReactGA({
      pageName: "loanrequest",
      category: "CADASTRO_COMPLETAR_CADASTRO",
      action: "Acesso_a_tela_de_cadastro",
      label: "Completar_cadastro"
    });
  };

  state = {
    cpf: "",
    loginToken: "",
    birthdate: "",
    fullName: "",
    phoneNumber: ""
  };

  verifySessionToken = async () => {
    if (!this.props.sessionToken) {
      navigate(this.props, UserPaths.USER_LOGIN);
    }
  };

  onClickNext = () => {
    navigate(this.props, LoanRequestPaths.LR_COMP_REG_FIRST_STEP);
  };

  render() {
    let userName = this.props.fullName || "Usuário não logado";

    return (
      <div className="CompleteRegister">
        <FeedBack
          headerTitle="Validar cadastro"
          summary={
            <>
              <p>Olá, {userName}!</p>
              <p>
                Para fazer a simulação, primeiro precisamos completar o seu
                cadastro.
              </p>
              <p>São só alguns passos. Vamos lá?</p>
            </>
          }
          textButton="Próximo"
          submit={this.onClickNext}
        />
      </div>
    );
  }
}

const mapDispathToProps = dispatch =>
  bindActionCreators({ getPersonalData }, dispatch);

const mapStateToProps = store => ({
  cpf: store.user.cpf,
  token: store.user.token,
  birthdate: store.user.birthdate,
  fullName: store.user.fullName,
  phoneNumber: store.user.phoneNumber,
  sessionToken: store.user.sessionToken
});

export default connect(
  mapStateToProps,
  mapDispathToProps
)(CompleteRegister);
