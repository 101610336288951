import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./inputRadioBoxes.css";
import { formatMoney } from "Components/InputRange/Format";

/**
 * @description Componente que cria uma listagem de caixas
 * @param {*} props
 * @example
 * Formato exemplo de itens
 * {const  itens= [
 *   {
 *     isSelected: false,
 *     label: "1 parcela de ",
 *     text: "R$ 1555,00",
 *     value: 1555
 *   },
 *   {
 *     isSelected: false,
 *     label: "2 parcelas de ",
 *     text: "R$ 755,00",
 *     value: 755.0
 *   },...]
 * }
 * exemplo de render
 * {
 *   <InputRadioBoxes
 *         onChangeValue={this.onChangePayment}
 *         value={payment}
 *         itens={itens}
 *      />
 * }
 */
const InputRadioBoxes = props => {
  const [itens, setItens] = useState([]);

  useEffect(() => {
    const { value, itens } = props;
    setItens(
      itens.map(item =>
        item.value === value
          ? { ...item, isSelected: true }
          : { ...item, isSelected: false }
      )
    );
  }, [props.itens]);

  /**
   * @description valida de o valor e o método de change é valido
   * @param {newValue} newValue novo valor
   */
  const onChangeValid = newValue =>
    props.onChangeValue && (newValue || newValue === 0);

  /**
   *
   * @param {index do item selecionado} index
   */
  const onClick = (index, newValue) => {
    let newItens = itens.map((item, itemIndex) => {
      if (index === itemIndex) {
        onChangeValid(newValue) && props.onChangeValue(newValue);
      }
      return {
        ...item,
        isSelected: index === itemIndex ? true : false
      };
    });
    setItens(newItens);
  };

  /**
   * itensRender, itens
   */
  const opcoesBoxes = itens
    ? itens.map((item, index) => {
        let boxClasses = [
          "m-2 pl-2 pr-1 pb-1 pt-1 text-left",
          item.isSelected ? "selected" : "unselected"
        ].join(" ");
        return (
          <div
            key={index}
            onClick={() => onClick(index, item.value)}
            name="installment_option_box"
            className="col-6 p-0"
          >
            <div className={boxClasses}>
              <sub className="firstText">{item.label}x</sub>
              <div className="twoText">
                <div className="twoText-sifr">R$ </div>
                <div className="twoText-text">{formatMoney(item.text)}</div>
              </div>
            </div>
          </div>
        );
      })
    : [];

  return (
    <div className="InputRadioBoxes">
      <div className="row m-0 input-radio-boxexs-color">{opcoesBoxes}</div>
    </div>
  );
};

InputRadioBoxes.propTypes = {
  // itens a serem renderizados
  itens: PropTypes.array,
  // função de change value
  onChangeValue: PropTypes.func,
  // velue selecionado
  value: PropTypes.any
};

export default InputRadioBoxes;
