import React from "react";
import { connect } from "react-redux";

import { Paths as LoanPaths } from "Routes/LoanRoutes";
import { Paths as CreditRequestPaths } from "Routes/CreditRequestRoutes";

import { withRouter } from "react-router";

import initializeReactGA from "Services/ReactGA";
import ImageIcon from "Components/ImageIcon/ImageIcon";

export const Footer = ({ isMobile, user, history }) => {
  const navClassNames = [
    "navbar navbar-expand-lg navbar-light navbar-lebes",
    isMobile ? "fixed-bottom" : "d-none"
  ].join(" ");

  const accessCreditRequest = () => {
    initializeReactGA({
      pageName: "/",
      category: "HOME-ACESSO_RAPIDO",
      action: "clique_no_botao_simular_emprestimo",
      label: "visualizar_simulacao_e_contratacao_de_emprestimo"
    });

    history.push(CreditRequestPaths.CREDIT_REQUEST);
  };

  const accessStore = () => {
    initializeReactGA({
      pageName: "/",
      category: "HOME-ACESSO_RAPIDO",
      action: "clique_no_botao_comprar_on-line",
      label: "Acessar_o_e-commerce"
    });

    window.open("https://www.lebes.com.br/");
  };

  const accessInstallments = () => {
    initializeReactGA({
      pageName: "/",
      category: "HOME-ACESSO_RAPIDO",
      action: "clique_no_botao_pagar_parcelas",
      label: "visualizar_e_copiar_barras_para_pagamento_de_parcelas"
    });

    history.push(LoanPaths.INSTALLMENTS);
  };

  return (
    <nav className={navClassNames}>
      <div
        onClick={accessCreditRequest}
        className="col-3 link"
        to={CreditRequestPaths.CREDIT_REQUEST}
      >
        <ImageIcon size={20} src="calculator_white" className="footer-image" />
        <p className="footer-text">EMPRÉSTIMO</p>
      </div>
      <div className="col-3 link" onClick={accessStore}>
        <ImageIcon size={20} src="stall_white" className="footer-image" />
        <p className="footer-text">COMPRAR</p>
      </div>
      <div onClick={accessInstallments} className="col-3 link">
        <ImageIcon size={26} src="bill_white" className="footer-image" />
        <p className="footer-text">PARCELAS</p>
      </div>
    </nav>
  );
};

const mapStateToProps = store => ({
  user: store.user,
  loggedUser: store.loggedUser,
  userBalance: store.creditBankTransfer.userBalance
});

export default withRouter(connect(mapStateToProps)(Footer));
