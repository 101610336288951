import BFFWeb from "../BFFWeb/BFFWeb";

/* Action types */
export const Types = {
  SET: "loggedUser/SET",
  UNSET: "loggedUser/UNSET"
};

const initialState = {
  enderecoResidencial: {}
};

/* Reducer */
export default function loggedUser(state = initialState, { type, payload }) {
  switch (type) {
    case Types.SET:
      return { ...state, ...payload };
    case Types.UNSET:
      return initialState;
    default:
      return state;
  }
}

/* Action creators */
function setLoggedUser(payload) {
  return {
    type: Types.SET,
    payload
  };
}

export function unSetLoggedUser(payload) {
  return {
    type: Types.UNSET
  };
}

export function handleGetLoggedUser(payload) {
  return (dispatch, getState) => {
    const { user } = getState();

    if (user.cpf) {
      BFFWeb.searchCustomer(user.cpf).then(res => {
        const { consultaClienteList } = res.data;
        if (consultaClienteList) {
          const { cliente, credito } = consultaClienteList;
          const user = { ...cliente, ...credito };

          dispatch(setLoggedUser(user));
        }
      });
    }
  };
}
