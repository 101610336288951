import React, { PureComponent } from "react";
import { formatMoney } from "../../Helpers/Formatters";
import { PropTypes } from "prop-types";

/**
 * @author Guilherme Zordan
 * @description Coloca uma máscara monetária em um float não-formatado
 * @example
 * <TextMoney
 *  value="500.00"
 * />
 */
class TextMoney extends PureComponent {
  static propTypes = {
    /* valor a ser mostrado. Pode ser String ou Number */
    value: PropTypes.any.isRequired,
    /* deve renderizar cifrão pequeno? */
    smallUnit: PropTypes.bool,
    /* deve renderizar bold? */
    bold: PropTypes.bool
  };

  render() {
    const {
      value,
      smallUnit,
      bold,
      className,
      isNegative,
      ...rest
    } = this.props;
    let unit = "R$";

    let classNames = [];
    if (bold) classNames.push("font-weight-bold");
    if (className) classNames.push(className);
    classNames = classNames.join(" ");

    unit = smallUnit ? (
      <small className={bold ? "font-weight-bold" : ""}>{unit}</small>
    ) : (
      unit
    );

    let textMoney = formatMoney(isNegative ? -1 * value : value);

    return (
      <span className={classNames} {...rest}>
        {unit}
        {isNegative ? " -" : " "} {textMoney}
      </span>
    );
  }
}

TextMoney.defaultProps = {
  smallUnit: false,
  bold: false
};

export default TextMoney;
