import React from "react";
import PropTypes from "prop-types";

import moneybag from "images/moneybag.png";
import calendar from "images/calendar.png";
import wallet from "images/wallet.png";
import calculator_white from "../../images/calculadorawhite.png";
import stall_white from "../../images/tenda.png";
import bill_white from "../../images/extratowhite.png";

import "./ImageIcon.css";

/**
 * @author Henrique B
 * @copyright 07/2019
 * @description Componente para ícones de imagem
 * @example
 *   <ImageIcon
 *      name="CalendarIcon"
 *      alt="calendario"
 *      src="calendar"
 *      />
 */
const ImageIcon = props => {
  const { size, src, alt, name, className } = props;

  const icons = {
    moneybag,
    calendar,
    wallet,
    calculator_white,
    stall_white,
    bill_white
  };

  return (
    <div id={name} className={className}>
      <img
        style={{ width: size }}
        className="ImageIcon"
        alt={alt}
        src={icons[src]}
      />
    </div>
  );
};

ImageIcon.propTypes = {
  // Nome para mapeamento do botao no teste automatizado
  name: PropTypes.string,
  // Nome do componente
  alt: PropTypes.string,
  //Icone a ser exibido no componente
  src: PropTypes.string.isRequired,
  // classe de estilos para botão
  className: PropTypes.string,
  // tamanho da imagem a ser renderizada
  size: PropTypes.number
};

ImageIcon.defaultProps = {
  src: "",
  size: 47
};

export default ImageIcon;
