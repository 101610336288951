import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * @author Vicente Balaguer
 * @copyright 03/2019
 * Campo de Telefone, com aviso de preenchimento incorreto
 */
class PhoneInput extends Component {
  state = {
    phone: "",
    errorText: ""
  };

  /**
   * @author Guilherme Zordan
   * @description Verifica se o campo será inicializado com um valor da prop
   */
  componentDidMount() {
    if (this.props.phone) {
      this.setState({ phone: this.formatPhone(this.props.phone) });
      this.props.onChange(`55${this.props.phone}`);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.phone !== prevProps.phone)
      this.setState({ phone: this.formatPhone(this.props.phone) });
  }

  /**
   * @author Vicente Balaguer
   * @copyright 03/2019
   * @description Evento de atualização de estado ao digitar a Telefone, aceita somente números
   */
  onChange = e => {
    let phone = this.formatPhone(e.target.value);
    this.setState({ phone }, () => {
      if (!this.validate(phone)) {
        phone = "";
      }
      this.props.onChange(phone ? `55${phone.replace(/[^\d]+/g, "")}` : "");
    });
  };

  /**
   * @author Vicente Balaguer
   * @copyright 03/2019
   * @description Evento de validação e atualização de estado ao digitar o telefone, se válido chama propriedade vinculada ao Pai
   */
  onBlur = e => {
    this.onChange(e);
    if (this.props.onBlur) this.props.onBlur();
  };

  /**
   * @author Vicente Balaguer
   * @copyright 03/2019
   * @description Método de aplicação de máscara de Telefone
   * @param Valor para ser formatado
   * @return Retorna o valor no formato de Telefone
   */
  formatPhone = phone => {
    if (phone) {
      phone = phone.replace(/[^\d]/g, "");
      phone = phone.replace(/^(\d)/, "($1");
      phone = phone.replace(/(\d{2})(\d)/, "$1)$2");
      phone = phone.replace(/(\d{5})(\d)/, "$1-$2");
    }

    return phone;
  };

  /**
   * @author Vicente Balaguer
   * @copyright 03/2019
   * @description Método de validação de telefone
   * @param Valor para ser formatado
   * @return Retorna o valor no formato de telefone
   */
  validate = phone => {
    if (!phone && this.props.required) {
      this.setState({ errorText: "Ops! Telefone não foi preenchido :(" });
      return false;
    } else if (phone && phone.length < 14) {
      this.setState({ errorText: "Ops! Telefone inválido :(" });
      return false;
    } else {
      this.setState({ errorText: "OK" });
      return true;
    }
  };

  render() {
    const { placeholder, className, name, disabled } = this.props;
    const { phone, errorText } = this.state;

    return (
      <div className="form-group">
        <input
          className={`form-control ${
            !errorText || errorText === "OK" ? "" : "is-invalid"
          } ${className}`}
          type="text"
          maxLength="14"
          placeholder={placeholder}
          value={phone}
          onChange={this.onChange}
          onBlur={this.onBlur}
          name={name}
          disabled={disabled}
        />
        <div className="invalid-feedback">
          {errorText === "OK" ? "" : errorText}
        </div>
      </div>
    );
  }
}

PhoneInput.defaultProps = {
  placeholder: "Celular",
  /* Campo habilitado por valor padrao */
  disabled: false
};

PhoneInput.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  phone: PropTypes.string,
  errorText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  /* Desabilita campo se valor pre carregado */
  disabled: PropTypes.bool
};

export default PhoneInput;
