import React from "react";
import { Route } from "react-router";

import Transfer from "Containers/Transfer";
import BankTransfer from "Containers/BankTransfer";
import TransferStore from "Containers/TransferStore/TransferStore";
import TransferDataConfirm from "Containers/TransferDataConfirm";
import TransferSuccess from "Containers/TransferSuccess";
import TransferBankSuccess from "Containers/TransferBankSuccess";
import RequestTransferFail from "Containers/RequestTransferFail";
import TransferFail from "Containers/TransferFail";
import TransferStoreSuccess from "Containers/TransferStoreSuccess";
// import { Routes as PhoneTransferRoutes } from "./PhoneTransferRoutes";

import { Paths } from "./TransferRoutesPaths";

export { Paths };

export const Routes = (
  <>
    <Route exact path={Paths.TRANSFER_OPTIONS} component={Transfer} />
    <Route exact path={Paths.BANK_TRANSFER} component={BankTransfer} />
    <Route
      exact
      path={Paths.TRANSFER_DATA_CONFIRM}
      component={TransferDataConfirm}
    />
    <Route exact path={Paths.TRANSFER_SUCCESS} component={TransferSuccess} />
    <Route
      exact
      path={Paths.TRANSFER_BANK_SUCCESS}
      component={TransferBankSuccess}
    />
    <Route
      exact
      path={Paths.TRANSFER_REQUEST_FAIL}
      component={RequestTransferFail}
    />
    <Route exact path={Paths.TRANSFER_FAIL} component={TransferFail} />
    <Route exact path={Paths.STORE_TRANSFER} component={TransferStore} />
    <Route
      exact
      path={Paths.TRANSFER_STORE_SUCESS}
      component={TransferStoreSuccess}
    />
    {/* TODO:  */}
    {/* <Route path={Paths.TRANSFER_PHONE}>{PhoneTransferRoutes}</Route> */}
  </>
);
