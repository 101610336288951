import React from "react";
import Feedback from "Containers/FeedBack";
import { Paths } from "Routes";
import { navigate } from "Helpers/Navigate";

function NewPasswordSuccess(props) {
  const goToHome = async () => {
    navigate(props, Paths.HOME);
  };

  return (
    <Feedback
      title="Bem vindo"
      headerTitle="Cadastro Lebes"
      summary="à sua conta digital da Lebes!"
      emoji=":)"
      textButton="Continuar"
      submit={() => {
        goToHome();
      }}
    />
  );
}

export default NewPasswordSuccess;
