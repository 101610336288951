import React from "react";
import { Route } from "react-router";

import CreditRequest from "Containers/CreditRequest";
import CreditRequestSubmit from "Containers/CreditRequestSubmit";
import CreditTerms from "Containers/CreditTerms/CreditTerms";
import CreditRequestSuccess from "Containers/CreditRequestSuccess";
import CreditRequestFail from "Containers/CreditRequestFail";
import CreditRequestError from "Containers/CreditRequestError";
import PersonalCreditSimulation from "Containers/PersonalCreditSimulation/PersonalCreditSimulation";
import { Paths as RoutePaths } from "./MainPaths";

const { CREDIT } = RoutePaths;
export const Paths = {
  /* Seleção valor para simulação -> UI-019 */
  CREDIT_REQUEST: CREDIT + "/request",
  /* Seleção das parcelas -> UI-020 */
  CREDIT_INSTALLMENTS: CREDIT + "/installments",
  /* Confirmação com input senha -> UI-031 */
  CREDIT_REQUEST_SUBMIT: CREDIT + "/submit",
  /* Aceitação dos contratos -> UI-021 */
  CREDIT_TERMS: CREDIT + "/terms",
  /* Feedback de sucesso. -> UI-045 */
  CREDIT_REQUEST_SUCCESS: CREDIT + "/request-success",
  /* Feedback de falha. -> UI-046 */
  CREDIT_REQUEST_FAIL: CREDIT + "/request-fail",
  /* Feedback de não permitido. */
  CREDIT_REQUEST_ERROR: CREDIT + "/error"
};

export const Routes = (
  <>
    <Route exact path={Paths.CREDIT_REQUEST} component={CreditRequest} />
    <Route
      exact
      path={Paths.CREDIT_REQUEST_SUBMIT}
      component={CreditRequestSubmit}
    />
    <Route exact path={Paths.CREDIT_TERMS} component={CreditTerms} />
    <Route
      exact
      path={Paths.CREDIT_REQUEST_SUCCESS}
      component={CreditRequestSuccess}
    />
    <Route
      exact
      path={Paths.CREDIT_REQUEST_FAIL}
      component={CreditRequestFail}
    />
    <Route
      exact
      path={Paths.CREDIT_REQUEST_ERROR}
      component={CreditRequestError}
    />
    <Route
      exact
      path={Paths.CREDIT_INSTALLMENTS}
      component={PersonalCreditSimulation}
    />
  </>
);
