import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import calculadora from "images/icon_calculadora.png";
import localizacao from "images/drawer_compras.png";
import extrato from "images/icon_extrato.png";

import "./ImageButton.css";

/**
 * @author Anderson Oliveira
 * @copyright 04/2019
 * @description Componente de botão com icone de imagem
 * @example
 *   <ImageButton
 *      name="NameImageButton"
 *      route={CreditRequestPaths.CREDIT_REQUEST}
 *      src={calculadora}
 *      alt="calculadora"
 *      text="Simular Empréstimo"
 *      disabled={false}
 *      />
 */
const ImageButton = props => {
  const { text, src, alt, name, onClick } = props;

  const icons = {
    calculadora,
    localizacao,
    extrato
  };

  return (
    <div className="ImageButtonContainer">
      <div className="ImageButton" id={name} onClick={onClick}>
        <img alt={alt} src={icons[src]} />
      </div>
      <div className="TextImageButton mt-2">{text}</div>
    </div>
  );
};

ImageButton.propTypes = {
  // Nome para mapeamento do botao no teste automatizado
  name: PropTypes.string,
  // Rota ao efetuar o clique
  link: PropTypes.string.isRequired,
  // Nome do componente
  alt: PropTypes.string,
  // Texto que aparecera abaixo do botão
  text: PropTypes.object,
  //Icone a ser exibido no componente
  src: PropTypes.string.isRequired,
  // classe de estilos para botão
  className: PropTypes.string,
  // Habilita/Desabilita botao
  disabled: PropTypes.bool
};

ImageButton.defaultProps = {
  link: "",
  // Habilita/Desabilita botao
  disabled: false
};

export default withRouter(ImageButton);
