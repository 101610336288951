import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import React, { Component } from "react";

import marker from "../../images/marker.png";
export class MapContainer extends Component {
  renderMarkers(markers) {
    return markers.map(({ latitude, longitude }, key) => (
      <Marker
        key={key}
        position={{ lat: Number(latitude), lng: Number(longitude) }}
        icon={{
          url: marker,
          anchor: new this.props.google.maps.Point(32, 32),
          scaledSize: new this.props.google.maps.Size(28, 42)
        }}
      />
    ));
  }
  render() {
    const { markers, latitude, longitude, ...rest } = this.props;
    return (
      <Map
        {...rest}
        google={this.props.google}
        zoom={8}
        center={{
          lat: latitude,
          lng: longitude
        }}
        zoomControl={false}
        mapTypeControl={false}
        scaleControl={false}
        streetViewControl={false}
        rotateControl={false}
        fullscreenControl={false}
      >
        {this.renderMarkers(markers)}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCxEmz4S2__uncwUY_oKrecbK_19_KgX9A"
})(MapContainer);
