import React from "react";
import "./Loader.css";
import PropTypes from "prop-types";

/**
 * @description Componente de spinner que representa o loader da aplicação
 * @author Leonardo Lucas dos Santos
 * @param {boolean} props.loading propriedade que determina se o loading vai ser mostrado
 */
const Loader = props => {
  const { loading } = props;
  const contentClassNames = [
    loading
      ? [
          "justify-content-center SpinnerLoaderContent visible",
          document.body.classList.add("body-noscroll")
        ]
      : ["invisible", document.body.classList.remove("body-noscroll")]
  ];

  const spinnerClassNames = [
    loading
      ? "spinner-border text-success align-self-center SpinnerLoader visible"
      : "invisible"
  ];
  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className={contentClassNames}>
      <div className={spinnerClassNames} role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

Loader.propTypes = {
  // propriedade que indica se esta carregando o loader
  loading: PropTypes.any
};

export default Loader;
