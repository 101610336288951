import React, { Component } from "react";
import PropTypes from "prop-types";
import "./NumberInput.css";

/**
 * @author Márcio Geremia
 * @copyright 03/2019
 * Campo numérico, com aviso de preenchimento incorreto e vazio (quando requerido)
 * EX:
 *      <NumberInput
          value={number}
          onBlur={value => {
            this.setState({ number: value });
          }}
        />
 */
class NumberInput extends Component {
  state = {
    errorText: "",
    value: ""
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...prevState,
      value: nextProps.value ? nextProps.value : prevState.value
    };
  }

  componentDidMount = () => {
    this.setState({ value: this.props.value });
  };

  /**
   * @author Márcio Geremia
   * @copyright 03/2019
   * Evento de validação e atualização de estado ao sair do campo
   */
  onBlur = e => {
    this.onChange(e);
  };

  /**
   * @author Vicente Balaguer
   * @copyright 03/2019
   * @description Evento de validação de valor
   * @return Retorna verdadeiro se o valor for válido e falso caso seja requerido e estiver vazio
   */
  validate = value => {
    const { required, minLength, maxLength } = this.props;

    if (required && !value) {
      this.setState({ errorText: "Ops! O campo é obrigatório :(" });
      return false;
    } else if (minLength && value.length < minLength) {
      this.setState({
        errorText: `Ops! Valor deve ser maior que ${minLength - 1} dígitos :(`
      });
      return false;
    } else if (maxLength && value.length > maxLength) {
      this.setState({
        errorText: `Ops! Valor deve ser menor que ${maxLength + 1} dígitos :(`
      });
      return false;
    } else {
      this.setState({ errorText: "OK" });
      return true;
    }
  };

  /**
   * @author Márcio Geremia
   * @copyright 03/2019
   * Evento que executa a change somente quando o valor é um número ou é vazio
   */
  onChange = e => {
    let value = e.target.value.replace(/[^\d]/g, "");
    this.setState({ value }, () => {
      if (!this.validate(value)) {
        value = "";
      }
      this.props.onChangeValue(value);
    });
  };

  render() {
    const { placeholder, className, maxLength, required, name } = this.props;
    const { errorText, value } = this.state;
    return (
      <div>
        <input
          className={`form-control ${
            !errorText || errorText === "OK" ? "" : "is-invalid"
          } ${className ? className : ""}`}
          type="text"
          maxLength={maxLength}
          required={required}
          placeholder={placeholder}
          value={value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          name={name}
        />
        <div className="invalid-feedback">
          {errorText === "OK" ? "" : errorText}
        </div>
      </div>
    );
  }
}

NumberInput.defaultProps = {
  placeholder: "Número",
  required: false
};

NumberInput.propTypes = {
  value: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  onChangeValue: PropTypes.func.isRequired,
  required: PropTypes.bool
};

export default NumberInput;
