/* Action types */
export const Types = {
  START_LOADING: "loading/start",
  STOP_LOADING: "loading/stop"
};

/* Initial State */
const initialState = 0;

/* Reducer */
export default function loading(state = initialState, { type }) {
  switch (type) {
    case Types.START_LOADING:
      return state + 1;
    case Types.STOP_LOADING:
      return state - 1;
    default:
      return state;
  }
}
/* Action Creators start */
export function startLoading() {
  return {
    type: Types.START_LOADING
  };
}

export function stopLoading() {
  return {
    type: Types.STOP_LOADING
  };
}
