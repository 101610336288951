import React from "react";
import Feedback from "Containers/FeedBack";
import { withRouter } from "react-router";
import { Paths } from "Routes/Routes";
import { navigate } from "Helpers/Navigate";

function EmailInvalid(props) {
  return (
    <Feedback
      headerTitle="Ops..."
      summary="Caso o e-mail não esteja correto você pode atualizar indo até a loja mais próxima ou ligando para a nossa Central de Atendimento Facilita Lebes: (51) 3499-7236"
      emoji=":("
      textButton="Ir para Home"
      submit={() => {
        navigate(props, Paths.HOME);
      }}
    />
  );
}

export default withRouter(EmailInvalid);
