import React, { Component } from "react";
import Feedback from "Containers/FeedBack";
import PropTypes from "prop-types";
import { navigate } from "Helpers/Navigate";
import { Paths as MainPaths } from "Routes/MainPaths";

export default class FailScreen extends Component {
  submit = () => this.props.history.goBack();

  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.string
  };

  static defaultProps = {
    title: "Ops...",
    message: "Tivemos um erro temporário, tente novamente mais tarde."
  };

  render() {
    return (
      <Feedback
        bigSummaryText
        headerTitle={this.props.title}
        summary={this.props.message}
        emoji=":("
        textButton="OK"
        submit={this.submit}
      />
    );
  }
}
