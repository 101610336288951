import React, { useEffect } from "react";
import { withRouter } from 'react-router'
import Header from "./components/Header";
import Body from "./components/Body";
import StyleHelmet from './components/StyleHelmet';
import Barcode from 'react-barcode'

import { store } from "../../Store";

import moment from 'moment';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';

import { Paths } from "Routes/MainPaths";

import { formatDate } from '../../Helpers/Formatters';

const { IDENTIFY } = Paths;

let data = {
  banco: "",
  localDePagamento: "",
  beneficiarioNome: "",
  beneficiarioEndereco: "",
  beneficiarioCNPJ: "",
  especieDoc: "",
  aceite: "",
  nossoNumeroBoleto: "",
  usoDoBanco: "",
  especie: "",
  instrucoes: "",
  mensagemBoleto: "",
  mensagemPagemento: "",
  contratosParcelas: [],
  agenciaCodigoDoBeneficiario: "",
  agencia: "",
  codigoCedente: "",
  contaCorrente: "",
  carteira: "",
  nossoNumero: "",
  dvNossoNumero: "",
  dtEmissao: "",
  vencimento: "",
  fatorVencimento: "",
  numeroDocumento: "",
  sacadoNome: "",
  sacadoEndereco: "",
  sacadoCEP: "",
  sacadoCPF: "",
  linhaDigitavel: "",
  codigoBarras: "",
  valorTotal: "",
  caixaObs1: "",
  caixaObs2: "",
  caixaObs3: "",
  caixaObs4: ""
};

const Text = ({text, bold}) => (
  <span
    style={{
      fontSize: 10,
      display: 'block',
      fontWeight: bold ? 'bold' : ''
    }}
  >
    {text}
  </span>
)

const print = async () => {
  await html2canvas(document.getElementById("bankslip"), {
    scale: 0.9,
    width: 1000,
    height: 2000
    // windowWidth: document.getElementById("bankslip").scrollWidth,
    // windowHeight: document.getElementById("bankslip").scrollHeight
  })
    .then(async canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 20, 20);

      const date = moment().format("DD-MM-YYYY");

      await pdf.save(`boleto_lebes_${date}.pdf`);
      store.dispatch({ type: "loading/stop" });
    }
  )
}

const getBrBarcode = barcode => {
  return barcode.replace(/^(\d{4})(\d{5})\d{1}(\d{10})\d{1}(\d{10})\d{1}(\d{15})$/, '$1$5$2$3$4')
}

const BankSlip = (props) => {
  useEffect(() => {
    store.dispatch({ type: "loading/start" });
  })
  
  try {
    data = props.location.state.clientStallments;
  } catch (e){
    window.location.href = IDENTIFY;
    return <div />;
  }

  const dtVencimento = formatDate(data.vencimento);
  const dtEmissao = formatDate(data.dtEmissao);

  return (
    <div
      className="document"
    >

      <div
        id="bankslip"
        style={{width: 750, height: 1000 }}
      >
        <StyleHelmet />
        <Header
          linhaDigitavel={ data.linhaDigitavel }
          banco={data.banco}
          onImageLoad={print}
        />
        <Body
          dtVencimento={ dtVencimento }
          dtEmissao={ dtEmissao }
          agenciaCodigoDoBeneficiario={ data.agenciaCodigoDoBeneficiario }
          beneficiarioNome={ data.beneficiarioNome }
          beneficiarioEndereco={ data.beneficiarioEndereco }
          beneficiarioCNPJ={ data.beneficiarioCNPJ }
          numeroDocumento={ data.numeroDocumento }
          especie={ data.especie }
          aceite={ data.aceite }
          nossoNumeroBoleto={ data.nossoNumeroBoleto }
          carteira={ data.carteira }
          usoDoBanco={ data.usoDoBanco }
          valorTotal={ data.valorTotal }
          sacadoNome={ data.sacadoNome }
          sacadoEndereco={ data.sacadoEndereco }
          sacadoCEP={ data.sacadoCEP }
          sacadoCPF={ data.sacadoCPF }
          localDePagamento={ data.localDePagamento }
          instrucoes={
            <>
              <Text text={data.instrucoes}/>
              <Text text={data.mensagemBoleto} bold/>
              <Text text={data.mensagemPagemento} bold/>
              <Text text={ data.contratosParcelas.length > 1 
                           ? data.contratosParcelas.join(', ')
                           : data.contratosParcelas.map(item => item)} />
            </>
          }
          especieDoc={ data.especieDoc }
          caixaObs1={ data.caixaObs1 }
          caixaObs2={ data.caixaObs2 }
          caixaObs3={ data.caixaObs3 }
          caixaObs4={ data.caixaObs4 }
        />
    
        <hr/>
    
        <Header
          banco={data.banco}
          linhaDigitavel={ data.linhaDigitavel }
        />
        <Body
          dtVencimento={ dtVencimento }
          dtEmissao={ dtEmissao }
          agenciaCodigoDoBeneficiario={ data.agenciaCodigoDoBeneficiario }
          beneficiarioNome={ data.beneficiarioNome }
          beneficiarioEndereco={ data.beneficiarioEndereco }
          beneficiarioCNPJ={ data.beneficiarioCNPJ }
          numeroDocumento={ data.numeroDocumento }
          especie={ data.especie }
          aceite={ data.aceite }
          nossoNumeroBoleto={ data.nossoNumeroBoleto }
          carteira={ data.carteira }
          usoDoBanco={ data.usoDoBanco }
          valorTotal={ data.valorTotal }
          sacadoNome={ data.sacadoNome }
          sacadoEndereco={ data.sacadoEndereco }
          sacadoCEP={ data.sacadoCEP }
          sacadoCPF={ data.sacadoCPF }
          localDePagamento={ data.localDePagamento }
          instrucoes={
            <>
              <Text text={data.instrucoes}/>
              <Text text={data.mensagemBoleto} bold/>
              <Text text={data.mensagemPagemento} bold/>
              <Text text={data.contratosParcelas.map(item => item)} />
            </>
          }
          especieDoc={ data.especieDoc }
          codigoBarras={ data.codigoBarras }
          caixaObs1={ data.caixaObs1 }
          caixaObs2={ data.caixaObs2 }
          caixaObs3={ data.caixaObs3 }
          caixaObs4={ data.caixaObs4 }
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {
            data.codigoBarras && (
              <Barcode
                value={getBrBarcode(data.codigoBarras)}
                height={ 50 }
                width={ 2.9 }
                format={"ITF"}
                // displayValue={ false }
              />
            )
          }
        </div>
        <span
          style={{
            fontSize: 10,
            display: 'block',
            fontWeight: 'bold',
            textAlign:'right',
            right: 20
          }}>Autenticação Mecânica - Ficha de Compensação
        </span>
      </div>
    </div>
  );
};

export default withRouter(BankSlip);
