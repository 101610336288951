import ReactGA from "react-ga";

/**
 * @author Antônio D. F. e Gabriel R.
 * @description Função que chama o Analytics
 * @param {string} pageName - Nome da página que será rastreada. Útil para colocar no componentDidMount.
 * @param {string} category - É algo capaz de englobar vários eventos.
 * @param {string} action - O que disparou o evento.
 * @param {string} label - Uma explicação mais detalhada.
 */
export default function initializeReactGA({
  pageName = "",
  category,
  action,
  label
}) {
  const idAnalytics = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(idAnalytics);
  if (pageName) ReactGA.pageview(pageName);
  if (category && action && label) {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  }
}
