import React, { PureComponent } from "react";
import VMasker from "vanilla-masker";
import propTypes from "prop-types";

/**
 * @author Guilherme Zordan
 * @description Mascara um número de telefone, com opção de esconder os primeiros 4 dígitos
 */
export default class PhoneMask extends PureComponent {
  static propTypes = {
    // valor a ser mascarado
    value: propTypes.string.isRequired,
    // esconde os 4 primeiros digitos
    hideFirstDigits: propTypes.bool,
    // Define se o código do país será ocultado
    hideCountryCode: propTypes.bool
  };
  render() {
    const { value, hideFirstDigits, hideCountryCode } = this.props;
    if (hideCountryCode) {
      if (hideFirstDigits)
        return <>{VMasker.toPattern(value.slice(9), "XXXXX-9999")}</>;
      return <>{VMasker.toPattern(value, "(99) 99999-9999")}</>;
    } else {
      if (hideFirstDigits)
        return <>{VMasker.toPattern(value.slice(9), "(XX) XXXXX-9999")}</>;
      return <>{VMasker.toPattern(value, "+99 (99) 99999-9999")}</>;
    }
  }
}
