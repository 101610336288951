import React, { Component } from "react";
import Routes from "./Routes";
import { Provider } from "react-redux";
import { store, persistor } from "./Store";
import "./App.css";
import Loader from "Components/Loader";
import { PersistGate } from "redux-persist/integration/react";
import { connect } from "react-redux";
const mapStateToProps = ({ loading }) => ({
  loading
});

const LoadingConnected = connect(mapStateToProps)(Loader);

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
          <LoadingConnected />
        </PersistGate>
      </Provider>
    );
  }
}
