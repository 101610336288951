import React, { Component } from "react";
import { PropTypes } from "prop-types";

/**
 * @author Guilherme Zordan
 * @description Componente Select de browser
 * @example
 * <Select
 *  value={this.state.person}
 *  label="name"
 *  labelKey="id"
 *  placeholder="Selecione uma pessoa"
 *  required
 *  onChangeValue={person => this.setState({ person })}
 *  options={[
 *  { name: "Augusto", id: 9 },
 *  { name: "Jose", id: 10 },
 *  { name: "Adão", id: 8 }
 *  ]}
 * />
 */
class Select extends Component {
  state = { internalError: "" };

  static propTypes = {
    // index da option selecionada
    value: PropTypes.string,
    // chave para buscar o index do array
    labelKey: PropTypes.string,
    // chave para buscar a descricao da option
    label: PropTypes.string.isRequired,
    // option default
    placeholder: PropTypes.string,
    // mensagem de erro externa customizada
    error: PropTypes.string,
    // passa o evento para cima
    onChange: PropTypes.func,
    // passa o index da option selecionada para cima
    onChangeValue: PropTypes.func,
    // array de options
    options: PropTypes.array.isRequired,
    // campo requerido?
    required: PropTypes.bool
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...prevState,
      select: nextProps.select ? nextProps.select : prevState.select,

      value: nextProps.value ? nextProps.value : prevState.value,
      label: nextProps.label ? nextProps.label : prevState.label,
      labelKey: nextProps.labelKey ? nextProps.labelKey : prevState.labelKey
    };
  }

  onChange = e => {
    const { onChange, onChangeValue } = this.props;
    const { value } = e.target;

    this.validate(value);

    if (onChangeValue) onChangeValue(value || "");
    if (onChange) onChange(e);
  };

  validate = value => {
    const { required } = this.props;
    let internalError =
      required && !value ? "Ops! O campo é obrigatório :(" : "";
    this.setState({ internalError });
  };

  render() {
    const { internalError } = this.state;

    const {
      value,
      label,
      labelKey,
      placeholder,
      error,
      options,
      className,
      name,
      disabled,
      required
    } = this.props;

    /* constroi mensagem de erro à partir de dentro ou fora do componente */
    const errorMsg = internalError || error;

    const renderErrorMessage = errorMsg && (
      <div className="invalid-feedback">{errorMsg}</div>
    );

    const renderPlaceholder = placeholder && (
      <option value="">{placeholder}</option>
    );

    const renderOptions = () => {
      if (options.length > 0) {
        return options.map((option, index) => (
          <option key={index} value={option[labelKey]}>
            {option[label]}
          </option>
        ));
      }
      return <></>;
    };

    const renderClassNames = () => {
      let classNames = ["form-control"];
      classNames.push(errorMsg ? "is-invalid" : "");
      classNames.push(className);
      return classNames.join(" ");
    };

    return (
      <>
        <select
          name={name}
          value={value}
          required={required}
          disabled={disabled}
          onChange={this.onChange}
          className={renderClassNames()}
        >
          {renderPlaceholder}
          {renderOptions()}
        </select>
        {renderErrorMessage}
      </>
    );
  }
}

Select.defaultProps = {
  labelKey: "id",
  options: []
};

export default Select;
