import React from "react";
import "./DialogConfirm.css";
import Button from "../Button/Button";
import { PropTypes } from "prop-types";
import Text from "../Text/Text";
import Dialog from "../Dialog/Dialog";
/**
 * @author Guilherme Zordan, Leonardo Lucas dos Santos
 * @description Componente de caixa de diálogo com duas opções. Necessário referenciar com ref.
 * @example
 *   <DialogConfirm
 *      message="Quer confirmar o investimento?"
 *      ref={el => (this.dialog = el)}
 *      onConfirm={this.confirm}
 *      onReject={this.reject}
 *    />
 *
 */
const DialogConfirm = React.forwardRef((props, ref) => {
  const {
    onConfirm,
    onReject,
    message,
    btnConfirm,
    btnReject,
    showButtons
  } = props;
  const textBtnConfirm = btnConfirm || "Confirmar";
  const textBtnReject = btnReject || "Cancelar";

  const buttons = (
    <div className="row justify-content-center m-0">
      <Button
        text={textBtnConfirm}
        color="Primary"
        onClick={onConfirm}
        className="WidthAuto col-10 m-2"
      />
      <Button
        text={textBtnReject}
        color="Secondary"
        onClick={onReject}
        className="WidthAuto col-10 m-2"
      />
    </div>
  );

  return (
    <Dialog ref={ref}>
      <div className="row">
        <div className="col-12 mb-4">
          <Text>{message}</Text>
        </div>
      </div>
      {showButtons && buttons}
    </Dialog>
  );
});

DialogConfirm.propTypes = {
  // texto do diálogo
  message: PropTypes.string.isRequired,
  // callback de confirmacao
  onConfirm: PropTypes.func,
  // callback de rejeição
  onReject: PropTypes.func,
  btnConfirm: PropTypes.string,
  btnReject: PropTypes.string
};

DialogConfirm.defaultProps = {
  showButtons: true
};
export default DialogConfirm;
