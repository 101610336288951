import { formatMoney } from "Helpers/Formatters";
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import FeedBackCard from "Containers/FeedBackCard/FeedBackCard";
import { unsetCreditResquest } from "Store/Ducks/creditRequest";
import "./CreditRequestSuccess.css";
import ImageIcon from "Components/ImageIcon/ImageIcon";
import { navigate } from "Helpers/Navigate";
import { Paths } from "Routes/Routes";

class CreditRequestSuccess extends Component {
  submit = () => {
    this.props.unsetCreditResquest();
    navigate(this.props, Paths.HOME);
  };

  /**
   * @description Método que retorna o valor monetario formatado
   * @author Leonardo L
   * @copyright 04/2019
   */
  format = value => formatMoney(value, { unit: "R$" });

  /**
   * @author Antônio D. F.
   * @copyright 07/2019
   * @description Monta o conteúdo do card que será exibido
   */
  renderCardContent = () => {
    const {
      value,
      plots,
      valuePlots,
      firstInstallmentDate,
      lastInstallmentDate,
      insurance
    } = this.props.store;

    return (
      <>
        <div class="text-center">
          <ImageIcon name="MoneyIcon" alt="Dinheiro" src="moneybag" />
        </div>
        <div className="mt-1">
          <p className="mt-1 text-center fontSize18">
            Empréstimo pessoal no valor de <br /> Valor solicitado
            <span className="font-weight-bold fontSize18">
              {" "}
              {this.format(value)}{" "}
            </span>
            <br />
            <span className="font-weight-bold fontSize18">
              {plots}x {this.format(valuePlots)}
            </span>
          </p>
          <p className="text-center lineHeight1">
            <small>
              {insurance ? "Seguro incluso" : null}
              <br />
              Primeira parcela {firstInstallmentDate}
              <br />
              Última parcela {lastInstallmentDate}
              <br />
            </small>
          </p>
        </div>
      </>
    );
  };

  render() {
    return (
      <FeedBackCard
        cardContent={this.renderCardContent()}
        headerTitle="Fazer empréstimo"
        summary="Sua solicitação de empréstimo foi encaminhada para análise, aguarde o nosso retorno!"
        emoji=":)"
        textButton="Finalizar contratação"
        submit={this.submit}
      />
    );
  }
}
const mapStateToProps = store => {
  const { valorFinanciado, insurance, condicao } = store.creditRequest;
  const {
    valorTfc,
    taxaCetAno,
    taxaCet,
    qtdParcelas,
    parcelas,
    primeiroVencimento
  } = condicao;

  let parcela = {
    vctoParcela: "",
    valorParcela: "",
    primeiroVencimento: ""
  };
  if (parcelas && parcelas.length) parcela = parcelas[parcelas.length - 1];
  const { vctoParcela, valorParcela } = parcela;
  return {
    store: {
      value: valorFinanciado && Number(valorFinanciado).toFixed(2),
      plots: qtdParcelas,
      valuePlots: valorParcela,
      firstInstallmentDate:
        primeiroVencimento && moment(primeiroVencimento).format("DD/MM/YYYY"),
      lastInstallmentDate:
        vctoParcela && moment(vctoParcela).format("DD/MM/YYYY"),
      insurance: insurance,
      valorTfc: valorTfc,
      taxaCetAno: taxaCetAno,
      taxaCetMensal: taxaCet,
      user: store.user
    }
  };
};

export default connect(
  mapStateToProps,
  { unsetCreditResquest }
)(CreditRequestSuccess);
