import React from "react";
import Feedback from "Containers/FeedBack";
import { Paths } from "Routes";
import { withRouter } from "react-router";

const CreditRequestSuccess = props => {
  const goToHome = () => {
    props.history.push(Paths.HOME);
  };

  return (
    <Feedback
      headerTitle="Transferência"
      details="Seu depósito será efetivado em até 24 horas úteis."
      emoji=":)"
      summary="Sua transferência foi realizada com sucesso!"
      textButton="Voltar para home"
      submit={goToHome}
    />
  );
};

export default withRouter(CreditRequestSuccess);
