import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * @author Anderson Oliveira
 * @copyright 02/2019
 * @description Campo de texto, com aviso de preenchimento incorreto e quantidade mínima de caracteres
 */
const TextInput = props => {
  const [error, setError] = useState("");

  /**
   * @author Guilherme Zordan
   * @description Renderiza erro no input vindo do pai
   */
  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  /**
   * @author Guilherme Zordan
   * @description Executa validações internas e dá prioridade à mensagem de erro externa.
   */
  const validate = value => {
    const { required, minLength, maxLength } = props;
    let error = "";
    if (!value && required) {
      error = `Ops! Valor deve ser preenchido :(`;
    } else if (minLength && value.length < minLength) {
      error = `Ops! Valor deve ser maior que ${minLength - 1} caracteres :(`;
    } else if (maxLength && value.length > maxLength) {
      error = `Ops! Valor deve ser menor que ${maxLength} caracteres :(`;
    }
    setError(error);
    return error ? false : true;
  };

  /**
   * @author Anderson Oliveira
   * @copyright 02/2019
   * @description Evento de atualização de estado ao digitar o texto, caso textOnly for verdadeiro, força renderização de
   */
  const onChange = e => {
    const { textOnly, onChangeValue, specialCharacters } = props;
    let { value } = e.target;
    if (validate(value)) {
      if (!specialCharacters) {
        value = value.replace(/[^a-zà-üA-ZÀ-Ü0-9 ]/g, "");
      }
      if (textOnly) {
        value = value.replace(/[^a-zà-üA-ZÀ-Ü ]/g, "");
      }
    }
    onChangeValue(value);
  };

  const renderClassNames = () => {
    let classNames = ["form-control"];
    classNames.push(error ? "is-invalid" : "");
    classNames.push(props.className);
    return classNames.join(" ");
  };

  return (
    <div className="form-group" style={props.style}>
      <input
        type={props.type}
        value={props.value}
        onChange={onChange}
        onBlur={onChange}
        name={props.name}
        className={renderClassNames()}
        placeholder={props.placeholder}
        disabled={props.disabled}
      />
      <div className="invalid-feedback">{error}</div>
    </div>
  );
};

TextInput.defaultProps = {
  error: "",
  type: "text",
  textOnly: false,
  /* Campo habilitado por valor padrao */
  disabled: false,
  specialCharacters: true
};

TextInput.propTypes = {
  /* Remove os caracteres especiais */
  specialCharacters: PropTypes.bool,
  /* Valor inicial do input */
  value: PropTypes.string.isRequired,
  /* Callback de change com valor */
  onChangeValue: PropTypes.func.isRequired,
  /* Mensagem de erro */
  error: PropTypes.string,
  /* força a renderização de texto somente */
  textOnly: PropTypes.bool,
  /* classe para estilização */
  className: PropTypes.string,
  /* Desabilita campo se valor pre carregado */
  disabled: PropTypes.bool,
  /* Outros proptypes */
  name: PropTypes.string,
  placeholder: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number
  /* Outros proptypes */
};

export default TextInput;
