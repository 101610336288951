import React, { PureComponent } from "react";
import "./MenuItem.css";

export class MenuItem extends PureComponent {
  render() {
    const { icon, children, action, visible } = this.props;

    if (!visible) return <></>;

    return (
      <div className="item-nav" onClick={action}>
        <div
          style={{
            backgroundImage: `url("${icon}")`,
            height: 30,
            width: 30,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            marginRight: 20
          }}
        />
        {children}
      </div>
    );
  }
}

export default MenuItem;
