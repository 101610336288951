import React from "react";
import Feedback from "Containers/FeedBack";
import { withRouter } from "react-router";
import { Paths } from "Routes/UserRoutes";
import { navigate } from "Helpers/Navigate";

function NewPasswordFail(props) {
  return (
    <Feedback
      headerTitle="Erro"
      summary="Houve algum problema na comunicação com o servidor."
      emoji=":("
      textButton="Pronto"
      submit={() => {
        navigate(props, Paths.HOME);
      }}
    />
  );
}

export default withRouter(NewPasswordFail);
