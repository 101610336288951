import React from "react";
import { Route } from "react-router";
import { Paths as RoutePaths } from "./MainPaths";
import HOCProtect from "HighOrderComponent/HOCProtect";

import Installments from "Containers/Installments";
import InstallmentValueConfirmation from "Containers/InstallmentValueConfirmation";
import InstallmentsDelayed from "Containers/InstallmentsDelayed";

const { LOAN } = RoutePaths;

export const Paths = {
  EASY_INSTALLMENTS: LOAN + "/easy-installments",
  INSTALLMENTS: LOAN + "/installments",
  INSTALLMENT_VALUE_CONFIRMATION: LOAN + "/installmentvalueconfirmation",
  NEGOTIATE: LOAN + "/negotiate"
};

export const Routes = (
  <>
    <Route
      exact
      path={Paths.EASY_INSTALLMENTS}
      component={Installments}
    />
    <Route
      exact
      path={Paths.INSTALLMENTS}
      component={HOCProtect(Installments)}
    />
    <Route
      exact
      path={Paths.INSTALLMENT_VALUE_CONFIRMATION}
      component={InstallmentValueConfirmation}
    />
      <Route
      exact
      path={Paths.NEGOTIATE}
      component={InstallmentsDelayed}
    />
  </>
);
