import Api, { WEBBFFRoutes } from "./Api";

class BFFWebCustomerLebes {

  /**
   * Manda Notificação
   */
  sendNotification = async (fcmToken, notificationType, cpfCliente = undefined) => {
    let body = {
      fcmToken,
      cpfCliente,
      notificationType
    }
    
    const response = await Api.post(WEBBFFRoutes.sendNotification, body);
    return response;
  };

  /**
   * getParcelas
   */
  getParcelas = (cpf, valorFinanciado, dataPrimeiroVcto, contratarSeguro) => {
    const body = {
      cpf: cpf,
      valorFinanciado: valorFinanciado + "",
      dataPrimeiroVcto: dataPrimeiroVcto,
      contratarSeguro: contratarSeguro
    };
    return Api.post(WEBBFFRoutes.getParcelas, body);
  };

  getFinancialData = async cpf => {
    const response = await Api.post(WEBBFFRoutes.getFinancialData, { cpf });
    return response;
  };

  listChaordicProducts = async () => {
    const response = await Api.post(WEBBFFRoutes.listChaordicProducts, {
      produtos: true
    });

    return response;
  };

  defineWithdrawalLoan = async payload => {
    const response = await Api.post(WEBBFFRoutes.defineWithdrawalLoan, payload);
    return response;
  };

  searchTermsConditions = async () => {
    const response = await Api.post(WEBBFFRoutes.buscaTermosCondicoes, {
      termosecondicoes: true
    });
    return response;
  };
}

export default new BFFWebCustomerLebes();
