import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./IdentifierInput.css";

import {formatCPF, formatCNPJ, validateCPF, validateCNPJ} from 'Helpers/Formatters';

const formatInput = (maskType, value) => {
  const pureValue = value.replace(/[^\d]+/g, "");

  if(maskType === 'adaptable'){
    if(pureValue.length <= 11)
      return formatCPF(value);
    return formatCNPJ(value);  
  }
  if(maskType === 'CPF')
    return formatCPF(value)
  if(maskType === 'CNPJ')
    return formatCNPJ(value);
}

const inputErrorDetector = (maskType, value) => {
  if(value === "") 
    return "Valor não foi preenchido";

  const hasCpfError = false === validateCPF(value);
  const hasCnpjError = false === validateCNPJ(value);

  const errorText = "Valor inválido";

  if(maskType === 'adaptable'){
    if(hasCpfError && hasCnpjError)
      return errorText;
  }
  if(maskType === 'CPF' && hasCpfError)
    return errorText;
  if(maskType === 'CNPJ' && hasCnpjError)
    return errorText;
  return "";
}

function IdentifierInput(props) {
  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  const {
    placeholder,
    className,
    errorText,
    name,
    disabled,
    isBoxInput,
    maskType
  } = props;

  useEffect(() => {
    if (props.defaultValue) {
      setValue(formatInput(maskType, props.defaultValue));
    }
  }, [props.defaultValue]);


  /**
   * @author Anderson Oliveira, Leonardo Lucas dos Santos
   * @copyright 02/2019
   * @description Evento de atualização de estado ao digitar número de CPF
  */
  const onChange = e => {
    let newValue = formatInput(maskType, e.target.value);
    const newValid = inputErrorDetector(maskType, newValue);

    setValue(newValue);
    setError(newValid);

    const pureValue = newValue.replace(/[^\d]+/g, "");

    const validLength = pureValue.length === 11 || pureValue.length === 14;
    if (validLength && !newValid) {
      onChangeValue(newValue);
    } else {
      onChangeValue("");
    }
  };

  const onBlur = e => {
    onChange(e);
  };

  const onChangeValue = value => {
    props.onChangeValue && props.onChangeValue(value.replace(/[^\d]+/g, ""));
  };

  /**
   * @author Leonardo Lucas dos Santos
   * @description Método para montar as mensagens padronizadas
   */
  const errorMessage = message => `Ops! ${message} :(`;

  const joinClassNames = () => {
    return [
      isBoxInput ? "form-control" : "line-input",
      className,
      error || errorText ? "is-invalid" : "was-validated"
    ].join(" ");
  };

  const showError = () => 
    (error || errorText) && (
      <div className={isBoxInput ? "invalid-feedback" : "mt-4 text-error"}>
        {errorMessage(error || errorText)}
      </div>
    );

  //18 caso: adaptable, CNPJ
  const maxLength = maskType === "CPF" ? 14 : 18;

  return (
    <div>
      <input
        autoComplete="off"
        className={joinClassNames()}
        type="text"
        maxLength={maxLength}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        disabled={disabled}
      />
      {showError()}
    </div>
  );
};

IdentifierInput.defaultProps = {
  placeholder: "CPF",
  required: false,
  defaultValue: "",
  /* Campo habilitado por valor padrao */
  disabled: false,
  isBoxInput: true,
  maskType: 'adaptable'
};

IdentifierInput.propTypes = {
  // Campo utilizado no atributo placeholder do <input/>
  placeholder: PropTypes.string,
  // Campo utilizado no atributo class do <input/>
  className: PropTypes.string,
  // Campo utilizado no atributo required do <input/>
  required: PropTypes.bool,
  // Function utilizada para executar quando usuario informar valor valido.
  onChangeValue: PropTypes.func.isRequired,
  // Campo utilizado como valor de inicializacao
  defaultValue: PropTypes.string,
  /* Desabilita campo se valor pre carregado */
  disabled: PropTypes.bool,
  //Define se o input será em forma de caixa ou não
  isBoxInput: PropTypes.bool,

  maskType: PropTypes.oneOf(["adaptable", "CPF", "CNPJ"])
};

export default IdentifierInput;
