import React, { useState } from "react";
import "./DrawerMenu.css";
import { connect } from "react-redux";
import { MenuItem } from "./MenuItem";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router";

function renderItems(items = []) {
  return items.map((item, key) => <MenuItem {...item} key={key} />);
}

function DrawerMenu({ user, items, history }) {
  const [status, setStatus] = useState("close");

  function openNav() {
    setStatus("open");
  }

  function closeNav() {
    setStatus("close");
  }

  return (
    <>
      <div className="DrawerMenuNavButton" onClick={openNav}>
        &#9776;
      </div>

      <div className={`sidenav ${status}`}>
        {user.isLogged ? (
          <div className="LoggedUserContent">
            Olá,
            <div className="TextGreen">{user.fullName.toUpperCase()}!</div>
          </div>
        ) : (
          <div className="UserContent">
            <div className="TextGreen">Seja bem-vindo!</div>
            Use sua conta Lebes e aproveite todas as facilidades do nosso
            aplicativo
            <button
              onClick={() => history.push("/user/identification")}
              className="btn btn-green btn-block mt-4"
            >
              Fazer login
            </button>
          </div>
        )}
        {renderItems(items)}
      </div>

      <div className={`sidenavBack ${status}`} onClick={closeNav} />
    </>
  );

  // <Label style={styles.versionText}>
  //   {"Versão  " + app.expo.version}
  // </Label>
}

DrawerMenu.propTypes = {
  items: PropTypes.array
};

DrawerMenu.defaultProps = {
  items: []
};

const mapStateToProps = ({ user }) => ({ user });

export default withRouter(connect(mapStateToProps)(DrawerMenu));
