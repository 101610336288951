import React from "react";
import { Route } from "react-router";
import Login from "Containers/Login/Login";
import LoginPwd from "Containers/LoginPwd/LoginPwd";
import NewUser from "Containers/NewUser/NewUser";
import CodeConfirm from "Containers/CodeConfirm/CodeConfirm";
import CodeConfirmAlready from "Containers/CodeConfirmAlready/CodeConfirmAlready";
import Welcome from "Containers/Welcome";
import PasswordConfirm from "Containers/PasswordConfirm/PasswordConfirm";
import Cpf from "Containers/Cpf/Cpf";
import Profile from "Containers/Profile/Profile";
import NewPasswordFail from "Containers/NewPasswordFail/NewPasswordFail";
import NewPasswordSuccess from "Containers/NewPasswordSuccess/NewPasswordSuccess";
import PasswordRecoverySuccess from "Containers/PasswordRecoverySuccess/PasswordRecoverySuccess";
import { Paths as RoutePaths } from "./MainPaths";
import PhoneUnavailable from "Components/PhoneUnavailable/PhoneUnavailable";
import EmailInvalid from "Components/EmailInvalid/EmailInvalid";
import PrivacyPolicy from "../Containers/PrivacyPolicy/PrivacyPolicy";

const { USER } = RoutePaths;

export const Paths = {
  USER_IDENTIFICATION: USER + "/identification",

  USER_LOGIN: USER + "/login",

  USER_NEW: USER + "/new",

  USER_PROFILE: USER + "/profile",

  USER_WELCOME: USER + "/welcome",

  USER_CODE_CONFIRM: USER + "/codeconfirm",

  USER_CODE_CONFIRM_ALREADY: USER + "/codeconfirmalready",

  USER_PASSWORD_CONFIRM: USER + "/passwordconfirm",

  USER_PASSWORD_FAIL: USER + "/passwordfail",

  USER_PHONE_UNAVAILABLE: USER + "/phoneunavailable",

  USER_EMAIL_INVALID: USER + "/emailinvalid",

  USER_PASSWORD_SUCCESS: USER + "/passwordsuccess",

  USER_PASSWORD: USER + "/password",

  USER_CHANGED_PASSWORD: USER + "/changedpassword",

  USER_RECOVERY: USER + "/recovery",

  USER_PRIVACY_POLICY: USER + "/privacypolicy"
};

export const Routes = (
  <>
    <Route exact path={Paths.USER_IDENTIFICATION} component={Login} />
    <Route exact path={Paths.USER_LOGIN} component={LoginPwd} />
    <Route exact path={Paths.USER_NEW} component={NewUser} />
    <Route exact path={Paths.USER_PROFILE} component={Profile} />
    <Route exact path={Paths.USER_WELCOME} component={Welcome} />
    <Route exact path={Paths.USER_CODE_CONFIRM} component={CodeConfirm} />
    <Route
      exact
      path={Paths.USER_CODE_CONFIRM_ALREADY}
      component={CodeConfirmAlready}
    />
    <Route
      exact
      path={Paths.USER_PASSWORD_CONFIRM}
      component={PasswordConfirm}
    />
    <Route exact path={Paths.USER_PASSWORD_FAIL} component={NewPasswordFail} />
    <Route
      exact
      path={Paths.USER_PHONE_UNAVAILABLE}
      component={PhoneUnavailable}
    />
    <Route
      exact
      path={Paths.USER_PASSWORD_SUCCESS}
      component={NewPasswordSuccess}
    />
    <Route exact path={Paths.USER_EMAIL_INVALID} component={EmailInvalid} />
    <Route exact path={Paths.USER_PASSWORD} component={PasswordConfirm} />
    <Route
      exact
      path={Paths.USER_CHANGED_PASSWORD}
      component={PasswordRecoverySuccess}
    />
    <Route exact path={Paths.USER_RECOVERY} component={Cpf} />
    <Route exact path={Paths.USER_PRIVACY_POLICY} component={PrivacyPolicy} />
  </>
);
