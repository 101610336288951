import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import _ from "lodash";
import {
  setUserIdentification,
  userDataLebes,
  lazyLogin,
  unsetUser,
} from "../../Store/Ducks/user";
import { store } from "../../Store";
import ServicesBFF from "Store/BFFWeb/BFFWeb";
import { received } from "../../Store/Ducks/clientInstallments";
import Button from "Components/Button/Button";
import IdentifierInput from "Components/IdentifierInput/IdentifierInput";
import IdentifierPasswordInput from "Components/IdentifierPasswordInput";
import logo from "../../Components/Headerlogo/lebes-logo.png";
import GridLayout from "Layouts/GridLayout/GridLayout";
import { Paths as LoanPaths } from "Routes/LoanRoutes";
import { navigate } from "Helpers/Navigate";
import { Paths } from "Routes";

function AccessLebes(props) {
  const [cpf, setCpf] = useState("");
  const [password, setPassword] = useState("");
  const [errorCPF, setErrorCPF] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);

  const recaptchaRef = useRef(null);

  async function consultServiceIntallments() {
    try {
      store.dispatch({ type: "loading/start" });
      const response = await ServicesBFF.getClientInstallments(cpf, true);

      if (response.data && response.data.code === "38404") {
        store.dispatch({ type: "loading/stop" });
        setErrorCPF(response.data.message);
      } else if (response.data.cliente.situacaoCliente === "20") {
        store.dispatch(lazyLogin({ fullName: response.data.cliente.nome }));
        store.dispatch({ type: "loading/stop" });
        navigate(props, LoanPaths.NEGOTIATE);
      } else {
        store.dispatch(lazyLogin({ cpf: cpf }));
        store.dispatch({ type: "loading/stop" });
        store.dispatch(received(response.data));
        navigate(props, LoanPaths.EASY_INSTALLMENTS);
      }
    } catch (error) {
      store.dispatch({ type: "loading/stop" });
    }
  }

  async function handleLogin() {
    store.dispatch({ type: "loading/start" });

    try {
      const response = await ServicesBFF.receiveToken(cpf, password);
      const { code, message } = response.data;

      if (code == "5200" && message == "Authorized") {
        consultServiceIntallments();
      } else {
        setErrorPassword("Acesso negado!");
      }
    } catch (err) {
      if (err.message === "Request failed with status code 412") {
        setErrorPassword("Acesso negado! Insira uma senha correta");
      } else {
        setErrorPassword("Por favor, tente novamente mais tarde");
      }
    }

    store.dispatch({ type: "loading/stop" });
  }

  const onInputChangeCPF = (value) => {
    setCpf(value);
  };

  const onInputChangePassword = (value) => {
    setPassword(value);
  };

  async function onChangeReCAPTCHA() {
    let recaptchaValue = await recaptchaRef.current.getValue();

    setCaptchaToken(recaptchaValue);
  }

  return (
    <div className="LoginContainer">
      <div className="Layer">
        <GridLayout
          alignContent="center"
          alignFooter="center"
          alignHeader="center"
          content={
            <div className="mb-5">
              <div className="text-center">
                <NavLink to={Paths.HOME}>
                  <img src={logo} alt="logo" />
                </NavLink>
              </div>
              <div className="Message mb-4" style={{ fontSize: "24px" }}>
                Olá! Digite o <strong style={{ fontSize: "24px" }}>CPF</strong>{" "}
                ou
                <strong style={{ fontSize: "24px" }}> CNPJ </strong> para poder
                acessar as parcelas! :)
              </div>
              <div className="d-flex justify-content-center">
                <div
                  style={{ maxWidth: 350 }}
                  className="col p-0 justify-content-center w-100"
                >
                  <IdentifierInput
                    isBoxInput={false}
                    placeholder="Digite aqui o CPF ou CNPJ"
                    required={true}
                    className="mt-4 align-self-center"
                    onChangeValue={onInputChangeCPF}
                    defaultValue={props.cpf}
                    name="CpfInputLogin"
                    errorText={errorCPF}
                  />
                  <IdentifierPasswordInput
                    isBoxInput={false}
                    placeholder="Digite aqui sua senha de acesso"
                    required={true}
                    className="mt-4 align-self-center"
                    maxLength={50}
                    onChangeValue={onInputChangePassword}
                    defaultValue={password}
                    name="PassswordInputLogin"
                    errorText={errorPassword}
                    errorCPF={errorCPF}
                  />
                  <div className="d-flex justify-content-center p-2">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Lf9iucUAAAAAG9bQ7hbZ4xBkrfHMG63RDHfssPR"
                      onChange={onChangeReCAPTCHA}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
          footer={
            <Button
              text="Próximo"
              name="LoginProximo"
              onClick={() => handleLogin()}
              disabled={cpf > 0 && captchaToken && password ? false : true}
            />
          }
        />
      </div>
    </div>
  );
}

const mapStateToProps = (store) => ({
  cpf: store.user.cpf,
});

export default connect(mapStateToProps, {
  setUserIdentification,
  userDataLebes,
  unsetUser,
})(AccessLebes);
