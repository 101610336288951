import React, { Component } from "react";
import PropTypes from "prop-types";
import "./CodeInput.css";

/**
 * @author Anderson Oliveira
 * @copyright 02/2019
 * @description Campo de código limitado em 6 caracteres numéricos
 */
class CodeInput extends Component {
  state = {
    code: "",
    errorText: ""
  };

  /**
   * @author Anderson Oliveira
   * @copyright 02/2019
   * @description Evento de atualização de estado, controlando a inserção de somente números, ao digitar código
   */
  onChange = e => {
    this.setState({ code: e.target.value.replace(/[^\d-.()]/g, "") }, () => {
      const { code } = this.state;
      if (code && code.length === 6) this.blurToParent();
    });
  };

  /**
   * @author Vicente Balaguer
   * @copyright 03/2019
   * @description Evento de validação e atualização de estado ao digitar o código, se válido chama propriedade vinculado ao Pai
   */
  onBlur = e => {
    this.onChange(e);
  };

  /**
   * @author Vicente Balaguer
   * @copyright 03/2019
   * @description Evento de validação de código
   * @return Retorna verdadeiro se o código for válido e falso caso seja inválido
   */
  validate = code => {
    if (code.length === 6) {
      this.setState({ errorText: "OK" });
      return true;
    } else {
      this.setState({ errorText: "Código inválido" });
      return false;
    }
  };

  blurToParent = () => {
    const { code } = this.state;
    if (this.validate(code)) {
      this.props.onChange(code);
    }
    this.props.onChange("");
  };

  errorClasses() {
    const { className } = this.props;
    const { errorText } = this.state;
    return [
      (!errorText || errorText === "OK") && !this.props.errorText
        ? "CodeInputSuccess"
        : "CodeInputFail",
      className
    ].join(" ");
  }

  render() {
    const { placeholder, maxLength } = this.props;
    const { code, errorText } = this.state;
    return (
      <div>
        <input
          className={this.errorClasses()}
          type="text"
          maxLength={maxLength}
          placeholder={placeholder}
          value={code}
          onChange={this.onChange}
          onBlur={this.onBlur}
        />
        <div className="ErrorText">
          {this.props.errorText
            ? this.props.errorText
            : errorText === "OK"
            ? ""
            : errorText}
        </div>
      </div>
    );
  }
}

CodeInput.defaultProps = {
  maxLength: "6",
  className: ""
};

CodeInput.propTypes = {
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default CodeInput;
