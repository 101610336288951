import React, { Component } from "react";

import HOCProtect from "HighOrderComponent/HOCProtect";
import HOCProtectCreditRequest from "HighOrderComponent/HOCProtectCreditRequest";

import { setCreditRequest } from "Store/Ducks/creditRequest";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import InputRange from "Components/InputRange/InputRange";
import GridLayout from "Layouts/GridLayout/GridLayout";
import Text from "Components/Text/Text";
import Button from "Components/Button/Button";
import Select from "Components/Select/Select";
import LoggedHeader from "Components/LoggedHeader/LoggedHeader";
import CheckBox from "Components/CheckBox/CheckBox";
import DialogConfirm from "Components/DialogConfirm/DialogConfirm";

import { Paths } from "Routes/";
import { Paths as CreditRequestPaths } from "Routes/CreditRequestRoutes";
import { formatMoney } from "Helpers/Formatters";
import BFFWeb from "Store/BFFWeb/BFFWeb";
import { navigate } from "Helpers/Navigate";

import initializeReactGA from "Services/ReactGA";

import "./CreditRequest.css";
import Card from "Components/Card/Card";
import ImageIcon from "Components/ImageIcon";

class CreditRequest extends Component {
  state = {
    datePlaceholder: "",
    value: 0,
    firstInstallmentDate: "",
    insurance: false,
    dates: [],
    dialog: false,
    checkboxClass: ["div-check "]
  };

  modal = React.createRef();
  dialog = React.createRef();

  componentDidMount() {
    this.setState({ value: this.props.store.limitAvailable });
    this.getDates();
    initializeReactGA({
      pageName: "credit/request",
      category: "EMPRESTIMO-SIMULAR",
      action: "Acesso_a_tela_de_valores_liberados",
      label: "Simular_emprestimo"
    });
  }

  /**
   * @author Leonardo L, Guilherme Z
   * @description Gera as datas padrão para o select
   */
  getDates = async () => {
    let dates = await BFFWeb.getPrimeiroPagamento();
    dates = dates.map(day => ({
      id: day,
      date: day
        .split("-", 3)
        .reverse()
        .join("/")
    }));
    this.setState({
      dates,
      datePlaceholder: dates[0].date,
      firstInstallmentDate: dates[0].id
    });
  };

  /**
   * @description Submit do botão de proximo da view
   * @author Leonardo L
   */
  submit = () => {
    if (!this.state.insurance) this.dialog.current.open();
    else this.proceed(true);
  };

  /**
   * @author Leonardo L
   * @description Método parar confirmação, sava no redux e avança para proxima pagina
   */
  proceed = shouldApproveInsurance => {
    const { value, firstInstallmentDate } = this.state;
    this.props.setCreditRequest({
      valorFinanciado: value,
      firstInstallmentDate,
      insurance: shouldApproveInsurance
    });

    if (this.dialog) this.dialog.current.close();
    navigate(this.props, CreditRequestPaths.CREDIT_INSTALLMENTS);
  };

  /**
   * @author Leonardo L
   * @description Método de alteração de valor
   * @param {number} value
   */
  onChangeValue = value => {
    this.setState({
      checkboxClass: this.state.checkboxClass.concat([" animated-border"])
    });
    this.setState({ value });
  };

  /**
   * @author Leonardo L
   * @description Método de alteração de insurance
   * @param {boolean} insurance
   */
  onChangeInsurance = insurance => {
    this.setState({ insurance });
  };

  /**
   * @author Leonardo L
   * @description Método de alteração de firstInstallmentDate
   * @param {date} firstInstallmentDate
   */
  onChangefirstInstallmentDate = firstInstallmentDate =>
    this.setState({ firstInstallmentDate });

  /**
   * @author Leonardo L
   * @description Método de render do valor limite para emprestimo
   */
  renderLimit = () => {
    const { limitAvailable } = this.props.store;
    return <b>{formatMoney(limitAvailable, { unit: "R$" })}</b>;
  };

  renderDialog = () => (
    <>
      <DialogConfirm
        ref={this.dialog}
        message={
          <>
            <div className="font-weight-bold h5">Aproveite e contrate </div>
            <div className="font-weight-bold h5">agora o seguro ;)</div>
          </>
        }
        onConfirm={() => this.proceed(true)}
        onReject={() => this.proceed(false)}
        btnConfirm="Contratar"
        btnReject="Continuar sem seguro"
      />
      <DialogConfirm
        ref={this.modal}
        message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
        showButtons={false}
      />
    </>
  );

  renderContent = () => {
    const {
      firstInstallmentDate,
      value,
      insurance,
      dates,
      checkboxClass
    } = this.state;
    const { limitAvailable } = this.props.store;

    return (
      <div style={{ marginTop: "-5vh", marginBottom: "-7vh" }}>
        {this.renderDialog()}
        <Text className="credit-label mx-auto my-2" color="#ffffff">
          Você tem limite máximo aprovado de {this.renderLimit()}
        </Text>

        <Card padding={20} className="request-card mb-4">
          <ImageIcon src="moneybag" className="mx-auto" />
          <Text size={18} className="text-center">
            De qual <b style={{ fontSize: 18 }}>valor</b> você precisa?
          </Text>
          <InputRange
            min={100}
            step={50}
            value={Number(value) || Number(limitAvailable)}
            max={Number(limitAvailable)}
            onChangeValue={this.onChangeValue}
          />
        </Card>

        <div className={checkboxClass}>
          <CheckBox
            classNameConteiner="checkbox-container m-auto"
            label="Desejo contratar o seguro "
            checked={insurance}
            onChangeValue={this.onChangeInsurance}
          />
        </div>

        <Card padding={20} className="request-card my-4">
          <ImageIcon src="calendar" className="mx-auto mb-2" />
          <div className="m-3">
            <Text size={18} className="Text text-center">
              Qual <b style={{ fontSize: 18 }}>melhor dia</b> para você pagar?
            </Text>
          </div>
          <div className="col mt-1 d-flex justify-content-center">
            <Select
              className="w-50"
              value={firstInstallmentDate}
              required
              options={dates}
              labelKey="id"
              label="date"
              name="firstInstallmentDateSelect"
              onChangeValue={this.onChangefirstInstallmentDate}
            />
          </div>
        </Card>

        <div className="row mt-4 mb-4 align-items-center" />
      </div>
    );
  };

  render() {
    const disabled = () => {
      const { firstInstallmentDate } = this.state;
      if (firstInstallmentDate) {
        return false;
      }
      return true;
    };

    const renderButton = () => (
      <Button text="Próximo" disabled={disabled()} onClick={this.submit} />
    );

    const header = (
      <LoggedHeader
        navFunction={() => navigate(this.props, Paths.HOME)}
        text="Fazer empréstimo"
      />
    );

    return (
      <GridLayout
        coloredBackgroundSize={40}
        alignContent="center"
        alignFooter="center"
        alignHeader="center"
        header={header}
        content={this.renderContent()}
        footer={renderButton()}
      />
    );
  }
}

const mapStateToProps = ({ creditRequest, loggedUser }) => ({
  store: {
    creditRequest,
    limitAvailable:
      loggedUser && loggedUser.produtoFinanceiroList
        ? loggedUser.produtoFinanceiroList[0].valorDisponivel
        : "0.00",
    nome: loggedUser && loggedUser.nome
  }
});

const mapActionsToProps = dispatch =>
  bindActionCreators({ setCreditRequest }, dispatch);

export default HOCProtect(
  HOCProtectCreditRequest(
    connect(
      mapStateToProps,
      mapActionsToProps
    )(CreditRequest)
  )
);
