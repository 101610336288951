import React, { Component } from "react";
import PasswordInput from "Components/PasswordInput/PasswordInput";
import Button from "Components/Button/Button";
import Text from "Components/Text/Text";
import "./PasswordConfirm.css";
import GridLayout from "Layouts/GridLayout/GridLayout";
import BFFWeb from "Store/BFFWeb/BFFWeb.js";
import LoggedHeader from "Components/LoggedHeader/LoggedHeader";
import { Paths } from "Routes/UserRoutes";

import { connect } from "react-redux";
import { navigate } from "Helpers/Navigate";
import { registerSessionToken } from "Store/Ducks/user";
import { setUserIdentification } from "Store/Ducks/user";

import initializeReactGA from "Services/ReactGA";

import { store } from "../../Store";

class PasswordConfirm extends Component {
  state = {
    password: "",
    confirmPassword: "",
    validPassword: "",
    validConfirmPassword: "",
    validConfirm: ""
  };

  componentDidMount() {
    initializeReactGA({
      pageName: "user/passwordconfirm",
      category: "HOME-LOGIN-CADASTRO",
      action: "Acesso_a_tela_para_cadastro_ou_login",
      label: "cadastrar_Senha"
    });
    if (!this.props.user.creationToken)
      navigate(this.props, Paths.USER_PASSWORD_FAIL);
  }

  handleSubmit = () => {
    if (this.validateSamePassword()) this.submit();
  };

  isValidPassword(password) {
    return password && password.length === 6;
  }

  validatePassword = password => {
    if (this.isValidPassword(password)) {
      return "OK";
    }
    return "A Senha deve ter 6 dígitos numéricos.";
  };

  validateConfirmPassword = confirmPassword => {
    if (this.isValidPassword(confirmPassword)) {
      return "OK";
    }
    return "A Confirmação da Senha deve ter 6 dígitos numéricos.";
  };

  formInvalid = () => {
    const { password, confirmPassword } = this.state;
    if (
      this.validatePassword(password) !== "OK" ||
      this.validateConfirmPassword(confirmPassword) !== "OK"
    ) {
      return true;
    }
    return false;
  };

  validateSamePassword = () => {
    const { password, confirmPassword } = this.state;
    if (!this.formInvalid() && password === confirmPassword) {
      return true;
    }
    this.setState({
      validConfirm:
        "As senhas não correspondem, digite novamente no campo solicitado"
    });
    return false;
  };

  submit = () => {
    const { password } = this.state;
    let request = {
      creationToken: this.props.user.creationToken,
      challenge: this.props.user.challenge,
      password: password,
      accountData: {
        fullName: this.props.user.fullName,
        cpf: this.props.user.cpf,
        birthdate: this.props.user.birthdate
      }
    };
    BFFWeb.completeCustomerCreation(request)
      .then(async ({ data }) => {
        if (data.status.code === "3200") {
          await this.props.registerSessionToken({
            isLogged: true,
            password: password,
            tokenJWT: data.tokenJWT,
            sessionToken: data.tokenData.sessionToken
          });

          await this.insertOrUpdateCustomerLebes();
          initializeReactGA({
            pageName: "user/passwordsuccess",
            category: "HOME-LOGIN-CADASTRO",
            action: "Acesso_a_tela_para_cadastro_ou_login",
            label: "cadastrar_Senha"
          });
          navigate(this.props, Paths.USER_PASSWORD_SUCCESS);
        } else {
          this.setState({ validConfirm: data.status.message });
          console.warn(data.status.message);
        }
      })
      .catch(error => {
        initializeReactGA({
          pageName: "user/passwordfail",
          category: "HOME-LOGIN-CADASTRO",
          action: "Acesso_a_tela_para_cadastro_ou_login",
          label: "logar_ou_cadastrar_reprovacao_de_credito"
        });
        navigate(this.props, Paths.USER_PASSWORD_FAIL);
      });
  };

  /**
   * @author Anderson O., Leonardo L
   * @copyright 05/2019
   * @description Monta payload e envia requisição, para inserir ou editar dados cadastrais do usuário, no barramento da Lebes
   */
  insertOrUpdateCustomerLebes = async () => {
    const {
      fullName,
      cpf,
      birthdate,
      emailAddress,
      phoneNumber,
      lbs,
      lbs_completo
    } = this.props.user;

    let cliente = {
      codigoCliente: "",
      nome: fullName,
      cpf: cpf,
      sexo: "",
      dataNascimento: birthdate,
      nomeMae: "",
      identidade: "",
      email: emailAddress,
      telefoneList: this.getPhoneList(phoneNumber),
      enderecoResidencial: this.getEnderecoResidencial(),
      emprego: this.getEmprego(),
      referencias: this.getReferences()
    };

    let payload = {
      cliente
    };

    if (!lbs) await BFFWeb.insertCustomerLebes(payload);
    else if (!lbs_completo) await BFFWeb.updateCustomerLebes(payload);

    this.updateUserStatus(cpf);
  };

  /**
   * @author Anderson O., Leonardo L
   * @copyright 05/2019
   * @description Consulta status do cadastro do usuario e atualiza o redux
   */
  updateUserStatus = async cpf => {
    try {
      store.dispatch({ type: "loading/start" });
      const response = await BFFWeb.getCustomerStats(cpf);

      const { status } = response.data;

      if (status) this.props.setUserIdentification({ ...status });
      store.dispatch({ type: "loading/stop" });
    } catch (error) {
      store.dispatch({ type: "loading/stop" });
    }
  };

  onChangePassword = value => {
    this.setState({
      password: value,
      validConfirm: ""
    });
  };

  onChangeConfirmPassword = value => {
    this.setState({
      confirmPassword: value,
      validConfirm: ""
    });
  };

  getReferences() {
    return [
      {
        refNome: "",
        refParentesco: "",
        refTelefoneList: [
          {
            ddd: "",
            telefone: "",
            ramal: "",
            descricao: "CELULAR"
          }
        ]
      }
    ];
  }

  getEmprego() {
    return {
      profissao: "",
      renda: "",
      empresa: {
        razaoSocial: "",
        nomeFantasia: ""
      }
    };
  }

  getPhoneList(phoneNumber) {
    return [
      {
        ddd: "",
        telefone:
          phoneNumber.slice(0, 2) === "55" ? phoneNumber.slice(2) : phoneNumber,
        ramal: "",
        descricao: "CELULAR"
      }
    ];
  }

  getEnderecoResidencial() {
    return {
      ufCod: "",
      cidade: "",
      rua: "",
      numero: "",
      complemento: "",
      cep: ""
    };
  }

  render() {
    const header = <LoggedHeader text="Cadastro Lebes" hasNavigation={false} />;

    return (
      <GridLayout
        alignContent="center"
        alignFooter="center"
        content={this.renderContent()}
        footer={this.renderFooter()}
        header={header}
      />
    );
  }

  renderContent = () => {
    let { validConfirm, validPassword, validConfirmPassword } = this.state;

    validPassword = this.isValid(validPassword);

    validConfirmPassword = this.isValid(validConfirmPassword);

    return (
      <div className="PasswordConfirm">
        <Text className="Sumary">
          Pronto! Agora crie uma senha de 6 dígitos para tornar sua conta
          segura:
        </Text>
        <div className="d-flex justify-content-center">
          <div style={{ maxWidth: 350 }} className="col p-0">
            <div className="form-group">
              <PasswordInput
                placeholder="Senha"
                onChangeValue={this.onChangePassword}
                successValidation={!validPassword && !validConfirm}
                name="inputSenha"
              />
            </div>
            <PasswordInput
              placeholder="Confirmar Senha"
              onChangeValue={this.onChangeConfirmPassword}
              successValidation={!validConfirmPassword && !validConfirm}
              name="inputconfirm"
            />
          </div>
        </div>

        <Text color="#dc3545">{validConfirm}</Text>
      </div>
    );
  };

  isValid(validPassword) {
    return validPassword && validPassword !== "OK";
  }

  renderFooter() {
    return (
      <Button
        text={"Próximo"}
        disabled={this.formInvalid()}
        onClick={this.handleSubmit}
        name="btnProximo"
      />
    );
  }
}
const mapStateToProps = ({ user }) => ({ user });

export default connect(
  mapStateToProps,
  { registerSessionToken, setUserIdentification }
)(PasswordConfirm);
