import React from "react";
import Product from "Components/Product";

import { connect } from "react-redux";

const ProductCarousel = ({ section, products = [] }) => {
  const title = {
    destaquesParaVoce: "Destaques para Você",
    produtosRecomendados: "Produtos Recomendados",
    ofertasEspeciais: "Ofertas Especiais"
  };

  return (
    products.length > 0 && (
      <>
        <div
          style={{ fontSize: "1.2em", color: "#4a4a4a" }}
          className="font-weight-bold mb-2 pl-2"
        >
          {title[section]}
        </div>
        <div className="scrolling-wrapper mb-3 pl-2 pr-2">
          {products.map((product, index) => (
            <Product section={section} key={index} product={product} />
          ))}
        </div>
      </>
    )
  );
};

const mapStateToProps = store => ({
  store: {
    user: store.user,
    loggedUser: store.loggedUser,
    produtos: store.chaordicProducts
  }
});

export default connect(mapStateToProps)(ProductCarousel);
