import React, { Component } from "react";
import TextMoney from "../TextMoney/TextMoney";
import Button from "Components/Button/Button";

import { Paths } from "Routes";
import { Paths as UserPaths } from "Routes/UserRoutes";
import { Paths as CreditRequestPaths } from "Routes/CreditRequestRoutes";
import { Paths as LoanRequestPaths } from "Routes/LoanRequestRoutes";
import { Paths as TransferRoutesPaths } from "Routes/TransferRoutesPaths";

import { Link } from "react-router-dom";
import { withRouter } from "react-router";

import PropTypes from "prop-types";
import Card from "Components/Card/Card";
import "./Greetings.css";

/**
 * @author Anderson Oliveira
 * @copyright 03/2019
 * @returns Texto explicativo para o usuario
 * @description Controla a exibicao de texto explicativo ao usuário
 */
class Greetings extends Component {
  /**
   * @author Anderson Oliveira
   * @copyright 03/2019
   * @returns Texto explicativo ao usuario
   * @description Define texto explicativo e se tag de valores devem ser retornados
   */
  renderTextUserInfo = () => {
    if (this.props.isLogged) {
      return (
        <div>
          <div className="row">
            <div className="col-12 font-weight-bold h3">
              {this.props.fullName},
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">{this.defineExplainText()}</div>
          </div>
          {this.props.lebesUser &&
          (this.props.creditRequestStatus !== "P" &&
            this.props.creditRequestStatus !== "A" &&
            this.props.creditRequestStatus !== "AR") ? (
            <div className="row no-gutters mb-3 justify-content-center">
              <div className="col-6 text-center greetings-border-right">
                <TextMoney
                  className="greetings-number-color h3"
                  isNegative={this.props.limiteDisponivel < 0}
                  smallUnit
                  bold
                  value={this.props.limiteDisponivel}
                />
                <div>Cartão</div>
              </div>

              <div className="col-6 text-center greetings-border-left">
                <TextMoney
                  className="greetings-number-color h3"
                  isNegative={this.props.limiteDisponivel < 0}
                  smallUnit
                  bold
                  value={this.props.valorDisponivel}
                />
                <div>Empréstimo</div>
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>
      );
    } else {
      return <div className="text-center mb-2 h5">Bem-vindo ao App Lebes!</div>;
    }
  };

  /**
   * @author Antônio D. F.
   * @copyright 08/2019
   * @returns String em Title Case
   */
  titleCase = str => {
    return str.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  /**
   * @author Anderson Oliveira, Leonardo L
   * @copyright 03/2019
   * @returns Texto explicativo ao usuario
   * @description Valida situacao do usuario e define texto explicativo a ser retornado
   */
  defineExplainText = () => {
    const {
      userFinancialStatus,
      creditRequestStatus,
      valorDisponivel,
      lebesUser,
      reproveTime,
      enderecoRetirada
    } = this.props;

    if (userFinancialStatus === "R" && reproveTime)
      return "A sua solicitação de limite de crédito não foi aprovada desta vez. Para mais informações, vá até a loja mais próxima ou fale com a nossa Central de Atendimento (51) 3499-7321.";

    if (!lebesUser && userFinancialStatus !== "P")
      return `Tenha limite de crédito para compras e empréstimo.`;

    return [
      {
        P: `Seu cadastro foi efetivado com sucesso! Já já a gente te informa qual seu saldo para empréstimo.`,
        R: (
          <>
            Ops... Seu crédito não foi aprovado agora.
            <b> Tente novamente em 48h</b>
          </>
        )
      }[userFinancialStatus],
      {
        P: `A sua solicitação de empréstimo está sendo analisada, e logo logo você receberá uma notificação sobre seu pedido, viu? ;)`,
        A: (
          <>
            Uhuul! Seu empréstimo está<b> aprovadíssimo! </b>
            Aproveite e comece a usar seu dinheiro agora mesmo! :D
          </>
        ),
        AR: enderecoRetirada ? (
          <>
            Estamos aguardando sua retirada no endereço{" "}
            {this.titleCase(enderecoRetirada.rua)}, {enderecoRetirada.numero},
            na cidade {this.titleCase(enderecoRetirada.cidade)}
          </>
        ) : (
          <>
            Estamos aguardando sua
            <b> retirada na loja</b>
          </>
        ),
        R: `Desta vez sua solicitação de empréstimo não foi aprovada. Aproveite seu limite para compras.`
      }[creditRequestStatus],
      {
        0: (
          <>
            A sua solicitação de limite de crédito não foi aprovada desta vez!
            <b> Tente novamente mais tarde.</b>
          </>
        ),
        1: (
          <>
            Você possui um<b> limite disponível</b>
          </>
        )
      }[~~(Number(valorDisponivel) > 0)]
    ].filter(el => !!el)[0];
  };

  /**
   * @author Anderson Oliveira
   * @copyright 03/2019
   * @returns Botao foramatado
   * @description Valida situacao do usuario e define botao a ser retornado
   */
  renderButton = () => {
    return (
      this.props.userFinancialStatus !== "P" &&
      !(this.props.userFinancialStatus === "R" && this.props.reproveTime) && (
        <Link to={this.defineRoute()} className="text-decoration-none">
          <div className="greetings-center-button">
            <Button
              className="btn-block"
              type="button"
              disabled={this.disableButton()}
              text={this.defineTextButton()}
            />
          </div>
        </Link>
      )
    );
  };

  /**
   * @author Anderson O., Leonardo L
   * @copyright 03/2019
   * @returns {boolean}
   * @description Valida rota a ser setada ao botão e define se botao desabilita
   */
  disableButton = () => {
    const {
      userFinancialStatus,
      valorDisponivel,
      creditRequestStatus,
      userBalance
    } = this.props;

    return (
      userFinancialStatus === "P" ||
      (this.userValid() &&
        (this.isInvalidCredit(creditRequestStatus, userBalance) ||
          Number(valorDisponivel) === 0))
    );
  };

  /**
   * @author Leonardo L.
   * @copyright 07/2019
   * @param {string} creditRequestStatus
   * @param {string|number} userBalance
   * @returns {boolean} status
   * @description retorna verdadeiro se o status for ["P","AR"]
   */
  isInvalidCredit(creditRequestStatus, userBalance = 0) {
    return (
      creditRequestStatus === "P" ||
      creditRequestStatus === "AR" ||
      (creditRequestStatus === "A" && Number(userBalance) === 0)
    );
  }

  /**
   * @author Leonardo L.
   * @copyright 07/2019
   * @returns {boolean} status
   * @description Valida de o usuario esta logado e lebes user ok
   */
  userValid() {
    const { isLogged, lebesUser } = this.props;
    return isLogged && lebesUser;
  }

  /**
   * @author Anderson O.
   * @copyright 03/2019
   * @returns Texto do botao
   * @description Valida situacao do usuario e define texto do botao a ser retornado
   */
  defineTextButton = () => {
    if (this.props.isLogged) {
      if (this.props.userFinancialStatus === "P") return "Aguardando Aprovação";
      else if (!this.props.lebesUser || this.props.userFinancialStatus === "R")
        return "Validar cadastro";
      else if (this.props.creditRequestStatus === "P")
        return "Solicitação em análise";
      else if (this.props.creditRequestStatus === "A")
        return "Retirar dinheiro";
      else if (this.props.creditRequestStatus === "AR")
        return "Aguardando retirada";
      else if (
        this.props.creditRequestStatus &&
        Number(this.props.valorDisponivel) > 0
      )
        return "Solicitar Empréstimo";
      else return "Solicitar Empréstimo";
    } else return "Entre ou cadastre-se";
  };

  /**
   * @author Antônio D. F.
   * @copyright 04/2019
   * @returns Rota do botao
   * @description Valida situacao do usuario e define rota do botao a ser retornado
   */
  defineRoute = () => {
    if (this.props.isLogged) {
      if (!this.props.lebesUser || this.props.userFinancialStatus === "R")
        return LoanRequestPaths.LR_COMP_REG_FIRST_STEP;
      //LoanRequestPaths.LOAN_REQUEST
      else if (this.props.creditRequestStatus === "P") return Paths.HOME;
      else if (this.props.creditRequestStatus === "A")
        return TransferRoutesPaths.TRANSFER_OPTIONS;
      else if (
        this.props.creditRequestStatus === "R" &&
        Number(this.props.valorDisponivel) > 0
      )
        return CreditRequestPaths.CREDIT_REQUEST;
      else if (
        (!this.props.creditRequestStatus || this.props.creditRequestStatus) &&
        Number(this.props.valorDisponivel) > 0
      )
        return CreditRequestPaths.CREDIT_REQUEST;
      //CreditRequestPaths.CREDIT_REQUEST
      else return Paths.HOME;
    } else return UserPaths.USER_IDENTIFICATION; //UserPaths.USER_IDENTIFICATION
  };

  render() {
    return (
      <Card padding={20} className="greetings-card">
        {this.renderTextUserInfo()}
        {this.renderButton()}
      </Card>
    );
  }
}

Greetings.defaultProps = {
  // Aponta de usuario esta logado
  isLogged: false,
  // Limite disponivel na conta do usuario
  limiteDisponivel: "0.00",
  // Valor disponivel na conta do usuario
  valorDisponivel: "0.00"
};

Greetings.propTypes = {
  // Aponta de usuario esta logado
  isLogged: PropTypes.bool,
  // Nome do usuario
  fullName: PropTypes.string,
  // Aponta se usuario esta cadastrado na Lebes
  lebesUser: PropTypes.bool,
  // Aponta se usuario possui uma solicitacao de credito em analise
  creditRequest: PropTypes.bool,
  // Limite disponivel na conta do usuario
  limiteDisponivel: PropTypes.string,
  // Valor disponivel na conta do usuario
  valorDisponivel: PropTypes.string,
  // Endereco de retirada em loja
  enderecoRetirada: PropTypes.object
};

export default withRouter(Greetings);
