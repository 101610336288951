import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./LargeImageButton.css";
import {
  FaUniversity,
  FaMoneyBillWave,
  FaUserFriends,
  FaQuestion
} from "react-icons/fa";

/**
 * @author Anderson Oliveira
 * @copyright 04/2019
 * @description Componente de botão com icone
 * @example
 *  <LargeImageButton
 *      name="nomeBotao"
 *      icon="FaUniversity"
 *      text="Para a minha conta bancária"
 *      disabled={false}
 *      onCLick={onClick}
 *  />
 *
 */
class LargeImageButton extends PureComponent {
  /**
   * @author Anderson Oliveira
   * @copyright 04/2019
   * @returns Icone do botao
   * @description Define icone a ser retornado
   */
  renderIcon = () => {
    switch (this.props.icon) {
      case "FaUniversity":
        return <FaUniversity className="mr-2 mb-1" size="25" color="orange" />;
      case "FaMoneyBillWave":
        return (
          <FaMoneyBillWave className="mr-2 mb-1" size="25" color="orange" />
        );
      case "FaUserFriends":
        return <FaUserFriends className="mr-2 mb-1" size="25" color="orange" />;
      default:
        return <FaQuestion className="mr-2 mb-1" size="25" color="orange" />;
    }
  };

  render() {
    const { className, text, onClick, disabled, name } = this.props;
    return (
      <div className="col-6 my-3">
        <button
          className={`btn btn-block LargeImageButton ${className}`}
          title={text}
          disabled={disabled}
          onClick={onClick}
          name={name}
        >
          {this.renderIcon()}
          {text}
        </button>
      </div>
    );
  }
}

LargeImageButton.defaultProps = {
  // Texto que aparecera abaixo do botão
  text: "",
  // Habilita/Desabilita botao
  disabled: false,
  // Icone interno do componente botao
  icon: ""
};

LargeImageButton.propTypes = {
  // Nome para mapeamento do botao no teste automatizado
  name: PropTypes.string,
  // Evento de click
  onClick: PropTypes.func,
  // Nome do componente
  alt: PropTypes.string,
  // Texto que aparecera abaixo do botão
  text: PropTypes.string,
  //Icone a ser exibido no componente
  icon: PropTypes.string.isRequired,
  // classe de estilos para botão
  className: PropTypes.string,
  // Habilita/Desabilita botao
  disabled: PropTypes.bool
};

export default LargeImageButton;
