import React, { Component } from "react";
import PropTypes from "prop-types";
import GridLayout from "Layouts/GridLayout/GridLayout";
import LoggedHeader from "Components/LoggedHeader/LoggedHeader";
import "./FeedBackCardStyle.css";
import Card from "Components/Card/";
import Button from "Components/Button/Button";

class FeedBackCard extends Component {
  // onPress = () => {
  //   this.props.navigation.navigate(this.props.navTo);
  // };

  render() {
    const {
      headerTitle,
      title,
      summary,
      emoji,
      details,
      textButton,
      submit,
      errorMessage,
      cardContent
    } = this.props;

    return (
      <GridLayout
        coloredBackgroundSize={cardContent ? 34 : 0}
        header={<LoggedHeader hasNavigation={false} text={headerTitle} />}
        content={
          <div>
            {cardContent ? <Card padding={10}>{cardContent}</Card> : null}
            <div className="feedBackTitle">{title}</div>
            <div className="feedBackSummaryText">{summary}</div>
            <div className="feedBackEmoji">{emoji}</div>
            <div className="feedBackNormalText">{details} </div>
            <div className="errorText align">
              {errorMessage !== "OK" && errorMessage}
            </div>
          </div>
        }
        footer={
          <Button
            name="btnFeedBack"
            className="FeedBackButton"
            text={textButton}
            onClick={submit}
          />
        }
      />
    );
  }
}

FeedBackCard.propTypes = {
  // recebe um objeto com o conteúdo do card
  cardContent: PropTypes.element,
  // função para retornar tela
  goBack: PropTypes.func,
  // recebe o titulo para o aviso ou titulo do header
  title: PropTypes.string,
  // recebe um resumo para o evento
  summary: PropTypes.string,
  // recebe emoji para exibição
  emoji: PropTypes.string,
  // palavra do botão
  textButton: PropTypes.string,
  // conteudo do corpo do feedback
  details: PropTypes.any,
  // função que trato o click do botão
  submit: PropTypes.func
};

export default FeedBackCard;
