import React from "react";
import Feedback from "Containers/FeedBack";

function TransferFail(props) {
  return (
    <Feedback
      goBack={props.history.goBack}
      title="Ops"
      summary="Falha na solicitação de transferência, tente mais tarde"
      emoji=":("
      textButton="Pronto"
      submit={props.history.goBack}
    />
  );
}

export default TransferFail;
