import BFFWebCustomerLebes from "../BFFWeb/BFFWebCustomerLebes";

/* Action types */
export const Types = {
  SET_PRODUCTS: "chaordicProducts/SET"
};
/* Action types */

const initialState = {
  produtos: {}
}

/* Reducer */
export default function chaordicProducts(state = initialState, { type, payload }) {
  switch (type) {
    case Types.SET_PRODUCTS:
      return payload || initialState;
    default:
      return state;
  }
}
/* Reducer */

/* Action Creators start */
function setProducts(payload) {
  return { type: Types.SET_PRODUCTS, payload };
}
/* Action Creators start */

/* Thunk Action Creators start */
export function handleSetChaordicProducts() {
  return (dispatch) => {

    BFFWebCustomerLebes.listChaordicProducts().then(res => {

      const { produtos } = res.data;
      dispatch(setProducts(produtos));
    });
  };
}
/* Thunk Action Creators start */
