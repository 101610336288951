import LoggedHeader from "../../Components/LoggedHeader";
import CpfText from "../../Components/CpfText/CpfText";
import PhoneMask from "../../Components/PhoneMask";
import TextMoney from "../../Components/TextMoney";
import DateText from "../../Components/DateText";
import React, { Component } from "react";
import { connect } from "react-redux";
import VMasker from "vanilla-masker";
import "./Profile.css";

/*
 * @author Antônio D. F.
 * @description Tela de visualização do perfil do usuário
 * @copyright 06/2019
 */
class Profile extends Component {
  render() {
    const { user } = this.props;

    const personalData = () => {
      return (
        <div className="border m-3 rounded-sm p-3">
          <div className="title">Dados pessoais</div>
          <div className="row">
            <div className="col">
              <div className="property">Nome completo</div>
              <div className="value">{user.fullName}</div>
            </div>
            <div className="col">
              <div className="property d-flex justify-content-end">E-mail</div>
              <div className="value d-flex justify-content-end">
                {user.emailAddress}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="property">CPF</div>
              <CpfText className="value" value={user.cpf} />
            </div>
            <div className="col">
              <div className="property d-flex justify-content-end">RG</div>
              <div className="value d-flex justify-content-end">{user.rg}</div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="property">Celular</div>
              <PhoneMask
                hideCountryCode
                className="value"
                value={user.phoneNumber.substr(2)}
              />
            </div>
            <div className="col">
              <div className="property d-flex justify-content-end">
                Nascimento
              </div>
              <div className="value d-flex justify-content-end">
                <DateText value={user.birthdate} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="property">Profissão</div>
              <div className="value">{user.job}</div>
            </div>
            <div className="col">
              <div className="property d-flex justify-content-end">
                Empresa onde trabalha
              </div>
              <div className="value d-flex justify-content-end">
                {user.companyWork}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="property ">Renda mensal</div>
              <div className="value ">
                <TextMoney value={user.money} />
              </div>
            </div>
            <div className="col">
              <div className="property d-flex justify-content-end ">
                Nome da mãe
              </div>
              <div className="value d-flex justify-content-end ">
                {user.motherName}
              </div>
            </div>
          </div>
        </div>
      );
    };

    const homeAddress = () => {
      return (
        <div className="border m-3 rounded-sm p-3">
          <div className="title">Endereço residencial</div>

          <div className="row">
            <div className="col">
              <div className="property">CEP</div>
              <div className="value">{user.cep}</div>
            </div>
            <div className="col">
              <div className="property d-flex justify-content-end">
                Logradouro
              </div>
              <div className="value d-flex justify-content-end">
                {user.address}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="property">Número</div>
              <div className="value">{user.addressNumber}</div>
            </div>
            <div className="col ">
              <div className="property d-flex justify-content-end">Bairro</div>
              <div className="value d-flex justify-content-end">
                {user.district}
              </div>
            </div>
          </div>
          {user.addressComplement ? (
            <>
              <div className="property">Complemento</div>
              <div className="value">{user.addressComplement}</div>{" "}
            </>
          ) : null}
          <div className="row">
            <div className="col">
              <div className="property">Cidade</div>
              <div className="value">{user.city}</div>
            </div>
            <div className="col">
              <div className="property d-flex justify-content-end">UF</div>
              <div className="value d-flex justify-content-end">
                {user.state}
              </div>
            </div>
          </div>
        </div>
      );
    };

    const personalReferences = () => {
      return (
        <div className="border m-3 rounded-sm p-3">
          <div className="title">Referências pessoais</div>

          {(user.firstNameRefer && (
            <div className="row">
              <div style={{ marginLeft: 25, marginRight: 10 }}>
                <div className="property">1.</div>
              </div>
              <div>
                <div className="property">Nome</div>
                <div className="value">{user.firstNameRefer}</div>
                <div className="property">Telefone</div>
                <div className="value">
                  {VMasker.toPattern(user.firstPhoneRefer, "(99) 9999-9999")}
                </div>
              </div>
            </div>
          )) ||
            null}

          {(user.secondNameRefer && (
            <div style={{ marginTop: 10 }}>
              <div className="row">
                <div style={{ marginLeft: 25, marginRight: 10 }}>
                  <div className="property">2.</div>
                </div>
                <div>
                  <div className="property">Nome</div>
                  <div className="value">{user.secondNameRefer}</div>
                  <div className="property">Telefone</div>
                  <div className="value">
                    {VMasker.toPattern(user.secondPhoneRefer, "(99) 9999-9999")}
                  </div>
                </div>
              </div>
            </div>
          )) ||
            null}
        </div>
      );
    };

    return (
      <div>
        <LoggedHeader text="Meu perfil" hasNavigation={true} />
        <div
          style={{
            flex: 1,
            maxWidth: "720px",
            margin: "auto"
          }}
        >
          {personalData()}
          {homeAddress()}
          {personalReferences()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(Profile);
