import Api, { WEBBFFRoutes } from "./Api";
// @ts-check

class BFFWeb {
  getCustomerStats = async (cpf) => {
    const request = {
      identifier: {
        cpf: cpf,
      },
    };
    const response = await Api.post(WEBBFFRoutes.getCustomerStats, request)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  };

  /**
   * registerUser
   */
  startCustomerCreation = async (payloadRequest) => {
    const payload = Object.assign(payloadRequest);
    return await Api.post(WEBBFFRoutes.startCustomerCreation, payload)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        //let { data } = err.response;
      });
  };

  completeCustomerCreation = async (payloadRequest) => {
    const payload = Object.assign(payloadRequest);
    const response = await Api.patch(
      WEBBFFRoutes.completeCustomerCreation,
      payload
    );
    return response;
  };
  setPassword = async (payload) => {
    const response = await Api.patch(WEBBFFRoutes.setPassword, payload);
    return response;
  };
  customerLogin = async (payload) => {
    return Api.post(WEBBFFRoutes.customerLogin, {
      ...payload,
    });
  };
  passwordRecovery = async (payload) => {
    const response = await Api.post(WEBBFFRoutes.passwordRecovery, {
      ...payload,
    });
    return response;
  };
  status = async (payload) => {
    const response = await Api.get(WEBBFFRoutes.status);
    return response;
  };
  /**
   * getCustomerAccountData
   */
  getPersonalData = async (payloadRequest) => {
    let payload = {
      ...payloadRequest,
    };

    const response = await Api.post(WEBBFFRoutes.getPersonalData, payload);

    return response;
  };
  getJobList = async () => {
    const response = await Api.post(WEBBFFRoutes.getJobList, {
      listaProfissao: true,
    });
    return response;
  };
  /**
   * getAdress
   */
  adressList = async (cep) => {
    const response = await Api.post(WEBBFFRoutes.adressList, cep);
    return response;
  };
  /**
   * getListUf
   */
  stateList = async (listaUf) => {
    const response = await Api.post(WEBBFFRoutes.stateList, listaUf);
    return response;
  };
  /**
   * getListCity
   */
  cityList = async (listaCidade) => {
    const response = await Api.post(WEBBFFRoutes.cityList, listaCidade);
    return response;
  };
  /**
   * insertCustomerLebes
   */
  insertCustomerLebes = async (payload) => {
    const response = await Api.post(WEBBFFRoutes.insertCustomerLebes, payload);
    return response;
  };

  updateCustomerLebes = async (payload) => {
    const response = await Api.post(WEBBFFRoutes.updateCustomerLebes, payload);
    return response;
  };

  /**
   * searchCustomer
   */
  searchCustomer = (cpf) => Api.post(WEBBFFRoutes.searchCustomer, { cpf });
  /**
   * checkPassword
   */
  checkPassword = async (password, sessionToken) => {
    const response = await Api.post(WEBBFFRoutes.checkPassword, {
      password,
      sessionToken,
    });
    return response;
  };
  /*
   * getContract
   */
  getContract = (codigoTermo, cidade, parametros) =>
    Api.post(WEBBFFRoutes.getContract, { codigoTermo, cidade, parametros });
  /*
   * submitContract
   */
  submitContract = async (payload) => {
    const response = await Api.post(WEBBFFRoutes.submitContract, payload);
    return response;
  };

  getBanks = async (payload) => {
    payload = Object.assign(payload);
    const response = await Api.post(WEBBFFRoutes.listBanks, payload);
    return response;
  };

  addBankAccount = (payload) =>
    Api.post(WEBBFFRoutes.addBankAccount, {
      addBank: Object.assign(payload),
    });

  getCustomerBankAccounts = (payload) =>
    Api.post(WEBBFFRoutes.bankAccounts, Object.assign(payload));

  /**
   * client installments
   */
  getClientInstallments = (cpfCliente, easy) => {
    let route;
    if (easy) route = WEBBFFRoutes.getClientEasyInstalmments;
    else route = WEBBFFRoutes.getClientInstalmments;

    return Api.post(route, { cpfCliente });
  };
  generateTicket = async (
    cpfCliente,
    parcelas,
    dataVencimentoBoleto,
    taxaEmissaoBoleto,
    easy
  ) => {
    let route;
    if (easy) route = WEBBFFRoutes.generateEasyTicket;
    else route = WEBBFFRoutes.generateTicket;

    const response = await Api.post(route, {
      cpfCliente,
      parcelas,
      dataVencimentoBoleto,
      taxaEmissaoBoleto,
    });
    return response;
  };

  /**
   * @author Anderson O.
   * @copyright 04/2019
   * @description Chama BFF de submissao de transferencia via TED
   */
  submitBankTranfer = async (payload) => {
    const response = await Api.post(
      WEBBFFRoutes.submitBankTranfer,
      Object.assign(payload)
    );
    return response;
  };

  getBalance = (payload) => Api.post(WEBBFFRoutes.getBalance, payload);

  getPrimeiroPagamento = async () => {
    const { data } = await Api.post(WEBBFFRoutes.buscaPrimeiroPagamento, {
      buscaPrimeiroPagamento: true,
    });
    return data.datasPrimeiroPagamento;
  };

  lojasList = () => {
    return Api.post(WEBBFFRoutes.lojasList, {
      listaLoja: true,
    });
  };

  getBanner = async (platform = "mobile") => {
    const response = await Api.post(WEBBFFRoutes.getBanner, {
      banner: true,
      platform,
    });
    return response;
  };

  retirarEmprestimoLoja = (cpfCliente, sessionToken, codigoLoja) => {
    return Api.post(WEBBFFRoutes.retirarEmprestimo, {
      sessionToken,
      cpfCliente,
      formaRetiradaEmprestimo: {
        tipo: "1",
        codigoLoja,
        banco: "",
        agencia: "",
        tipoConta: "",
        numeroConta: "",
      },
    });
  };

  /**
   * @author Guilherme Z.
   * @copyright 05/2019
   * @description Completa reset de senha do usuário
   */
  completePasswordRecover = ({ token, emailAddress, newPassword }) =>
    Api.post(WEBBFFRoutes.completePasswordRecover, {
      token,
      emailAddress,
      newPassword,
    });

  /**
   * @author Anderson O.
   * @copyright 07/2019
   * @description Envia token do usuário para notificações do firebase, ao BFF
   */
  sendFcmToken = async (FCMToken, cpf) => {
    const response = await Api.post(WEBBFFRoutes.sendFcmToken, {
      FCMToken,
      cpf,
    });
    return response;
  };

  receiveToken = async (cpf, password) => {
    const response = await Api.post(WEBBFFRoutes.receiveToken, {
      cpf,
      password,
    });

    return response;
  };

  checkValidToken = async (loginToken, challenge) => {
    const response = await Api.post(WEBBFFRoutes.checkValidToken, {
      loginToken,
      challenge,
    });

    return response;
  };
}

export default new BFFWeb();
