import React from "react";
import Button from "../Button/Button";
import { PropTypes } from "prop-types";
import Dialog from "../Dialog/Dialog";
import "./DialogDynamic.css";

/**
 * @author Guilherme Zordan, Leonardo Lucas dos Santos
 * @description Componente de caixa de diálogo com corpo dinamico. Necessário referenciar com ref.
 * @example
 *   <DialogDynamic
 *      ref={el => (this.dialog = el)}
 *      onSubmit={this.confirm}
 *      title="Este é um modal"
 *    >
 *       {children}
 *     </DialogDynamic>
 *
 */
const DialogDynamic = React.forwardRef((props, ref) => {
  const { onSubmit, title, btnSubmit, children } = props;

  const buttons = btnSubmit && (
    <div className="row">
      <div className="mx-auto ModalFooter">
        <div className="text-center ">
          <Button text={btnSubmit} color="Primary" onClick={onSubmit} />
        </div>
      </div>
    </div>
  );

  return (
    <Dialog ref={ref}>
      <div className="row">
        <div className="col-12 text-center ">
          <h5 className="mb-0">{title}</h5>
          <hr />
        </div>
      </div>
      <div className="row ModalContent">
        <div className="col-12 mb-4">{children}</div>
      </div>
      {buttons}
    </Dialog>
  );
});

DialogDynamic.propTypes = {
  // callback de confirmacao
  onSubmit: PropTypes.func,
  // texto do button
  btnSubmit: PropTypes.string,
  // conteudo do modal
  children: PropTypes.any,
  // titulo do modal
  title: PropTypes.string
};

DialogDynamic.defaultProps = {
  title: "Title",
  children: <div />
};
export default DialogDynamic;
