/**
 * @author Márcio Geremia
 * @description Esta função tem o objetivo de formatar valores monetários.
 * @param {} amount - Valor que será formatado.
 * @param {} decimalCount - Numero de casas decimais.
 * @param {} decimal - Define se é vírgula ou ponto nas casas decimais.
 * @param {} thousands - Define se é vírgula ou ponto nos milhares.
 */
export function formatMoney(
  amount,
  decimalCount = 2,
  decimal = ",",
  thousands = "."
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}
