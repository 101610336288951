import React, { Component } from "react";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import CpfInput from "Components/CpfInput/CpfInput";
import Button from "Components/Button/Button";
import ServicesBFF from "Store/BFFWeb/BFFWeb";
import "./Login.css";
import { connect } from "react-redux";
import { Paths as UserPaths } from "Routes/UserRoutes";
import { navigate } from "Helpers/Navigate";
import {
  setUserIdentification,
  userDataLebes,
  unsetUser
} from "../../Store/Ducks/user";

import { Paths } from "Routes";

import initializeReactGA from "Services/ReactGA";
import GridLayout from "Layouts/GridLayout/GridLayout";

import logo from "../../Components/Headerlogo/lebes-logo.png";

import { store } from "../../Store";

/**
 * Tela de Login
 * UI - 001
 */
class Login extends Component {
  /**
   * cpf : CPF do usuário
   * loginToken :  Token de login retornado pelo BFF
   */
  state = {
    cpf: "",
    loginToken: ""
  };

  componentDidMount = () => {
    this.setState({ cpf: this.props.cpf });
    this.props.unsetUser();

    initializeReactGA({
      pageName: "user/identification",
      category: "HOME-LOGIN-CADASTRO",
      action: "Acesso_a_tela_para_cadastro_ou_login",
      label: "cadastrar_CPF"
    });

    initializeReactGA({
      pageName: "/",
      category: "HOME",
      action: "clique_no_botao__Entre_ou_cadastre-se",
      label: "visualizar_tela_de_login_ou_cadastro"
    });
  };

  /**
   * Método para alterar cpf
   * onChangeCPF
   */
  onChangeCPF = cpf => {
    this.setState({ cpf });
  };

  login = cpf => {
    this.props.login(cpf);
  };

  emailValid = async payload => {
    const res = await ServicesBFF.startCustomerCreation(payload);

    if (res && res.data && res.data.status && res.data.status.message) {
      if (res.data.status.message.includes("Email")) {
        return false;
      }
    }

    return true;
  };

  /**
   * @author Anderson Oliveira
   * @copyright 03/2019
   * @description Evento de consulta a serviço no BFF com resposta contendo token para o login
   */
  consultServiceCPF = async () => {
    try {
      store.dispatch({ type: "loading/start" });

      const { cpf } = this.state;
      // consulta os dados do usuario

      const response = await ServicesBFF.getCustomerStats(cpf);

      if (response.data) {
        if (!response || [404, 500].includes(response.status)) {
          return;
        }

        const { status } = response.data;
        const { _4ll, lbs, lbs_all_main_content } = status;

        let payload = {};
        if (response.data.loginData) {
          payload = { ...response.data.loginData };
        }

        if (response.data.status) {
          payload = { ...payload, ...response.data.status, cpf };
        }

        if (response.data.client) {
          payload = {
            ...payload,
            cpf: cpf,
            fullName: response.data.client.nome,
            phoneNumber: response.data.client.telefone,
            emailAddress: response.data.client.email,
            birthdate: response.data.client.dataNascimento
          };

          if (!(await this.emailValid(payload))) {
            navigate(this.props, UserPaths.USER_EMAIL_INVALID);
            return;
          }
        }

        this.props.setUserIdentification(payload);

        store.dispatch({ type: "loading/stop" });

        if (lbs && response.data.client && !response.data.client.telefone) {
          // Ao retornar os parâmetros  { telefone: "", lbs :true } deve navegar para /phoneunavailable
          navigate(this.props, UserPaths.USER_PHONE_UNAVAILABLE);
        } else if (_4ll) {
          // Ao retornar os parâmetros  { _4ll : false, lbs :true } deve navegar para /welcome UI-005
          navigate(this.props, UserPaths.USER_LOGIN);
        } else if (!_4ll && lbs && lbs_all_main_content) {
          // Ao retornar os parâmetros  { _4ll : true, hasPassword :true } deve navegar para /login UI-007
          navigate(this.props, UserPaths.USER_WELCOME);
        } else if ((!_4ll && !lbs) || (lbs && !lbs_all_main_content)) {
          // Ao retornar os parâmetros  { _4ll : false, lbs :false } deve navegar para /new UI-002
          navigate(this.props, UserPaths.USER_NEW);
        }
      }
    } catch (err) {
      store.dispatch({ type: "loading/stop" });
      // console.warn(err);
    }
  };

  render() {
    return (
      <div className="LoginContainer">
        <div className="Layer">
          <GridLayout
            alignContent="center"
            alignFooter="center"
            alignHeader="center"
            content={
              <div className="mb-5">
                <div className="text-center">
                  <NavLink to={Paths.HOME}>
                    <img src={logo} alt="logo" />
                  </NavLink>
                </div>
                <div className="Message mb-4" style={{ fontSize: "24px" }}>
                  Olá! Digite seu{" "}
                  <strong style={{ fontSize: "24px" }}>CPF</strong> para
                  encontrarmos seu cadastro
                  <br />
                  ou para criarmos uma conta juntos! :)
                  <br />
                </div>

                <div className="d-flex justify-content-center">
                  <div style={{ maxWidth: 350 }} className="col p-0">
                    <CpfInput
                      isBoxInput={false}
                      placeholder="Digite aqui seu CPF"
                      required={true}
                      className="mt-4 align-self-center"
                      onChangeValue={this.onChangeCPF}
                      defaultValue={this.props.cpf}
                      name="CpfInputLogin"
                    />
                  </div>
                </div>
              </div>
            }
            footer={
              <Button
                text="Próximo"
                name="LoginProximo"
                onClick={_.debounce(() => this.consultServiceCPF(), 6000, {
                  leading: true,
                  trailing: false
                })}
                disabled={!this.state.cpf}
              />
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  cpf: store.user.cpf
});

export default connect(
  mapStateToProps,
  { setUserIdentification, userDataLebes, unsetUser }
)(Login);
