import React from "react";
import "./CheckBox.css";

export default ({ label, id, checked, classNameConteiner, onChangeValue, disabled }) => {
  return (
    <div className={classNameConteiner}>
      <label className="CheckBox">
        <div className="LabelCheckBox">{label}</div>
        <input
          id={id}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={e => onChangeValue(e.target.checked)}
        />
        <span className="checkmark" />
      </label>
    </div>
  );
};
