import React, { Component } from "react";
import LoggedHeader from "Components/LoggedHeader";
import PasswordInput from "Components/PasswordInput";
import Button from "Components/Button";
import GridLayout from "Layouts/GridLayout";
import { Paths as TransferRoutes } from "Routes/TransferRoutes";
import { Paths as Routes } from "Routes/Routes";
import initializeReactGA from "Services/ReactGA";
import { connect } from "react-redux";
import TextMoney from "Components/TextMoney";
import BFFWeb from "Store/BFFWeb/BFFWeb";
import BFFWebCustomerLebes from "Store/BFFWeb/BFFWebCustomerLebes";
import { navigate } from "Helpers/Navigate";
import VMasker from "vanilla-masker";

class TransferDataConfirm extends Component {
  state = {
    password: null,
    validPassword: "",
    errorValidation: "",
    transferTax: 2.2
  };

  componentDidMount() {
    initializeReactGA({
      pageName: "transfer/dataconfirm",
      category: "EMPRESTIMO - TRANSFERENCIA",
      action: "Acesso_a_tela_de_opcoes_de_transferencia",
      label: "Transferir_o_emprestimo_pessoal"
    });
  }

  mensagemErrorOps = message => {
    if (message !== "OK")
      return `Ops! ${message && message.replace(/\[.*?\]/g, "")} :(`;
    return "";
  };

  /**
   * @author Anderson O.
   * @copyright 04/2019
   * @description Chama rota apos conferencia de dados
   */
  onClick = () => {
    const payload = {
      bankAccountId: this.props.id,
      amount: this.props.userBalance,
      password: this.state.password,
      authorization: this.props.sessionToken
    };
    BFFWeb.submitBankTranfer(payload)
      .then(async res => {
        let { success } = res.data;
        if (success) {
          const payload = {
            cpfCliente: this.props.cpf,
            formaRetiradaEmprestimo: {
              tipo: "2",
              codigoLoja: "",
              banco: this.props.bank.replace(/[^\d]+/g, ""),
              agencia: this.props.branch.replace("-", ""),
              tipoConta: "",
              numeroConta: this.props.account.replace("-", "")
            }
          };
          await BFFWebCustomerLebes.defineWithdrawalLoan(payload);
          initializeReactGA({
            pageName: "transfer/success",
            category: "EMPRESTIMO-TRANSFERENCIA",
            action: "Acesso_a_tela_de_opcoes_de_transferencia",
            label: "Sucesso_na_transferencia_do_emprestimo_pessoal"
          });
          navigate(this.props, TransferRoutes.TRANSFER_BANK_SUCCESS);
        } else {
          const {
            data: { status: message }
          } = res;
          this.setState({
            errorValidation: this.mensagemErrorOps(message)
          });
        }
      })
      .catch(error => {
        const { response } = error;
        if (response) {
          let { message } = response.data;
          if (message)
            this.setState({
              errorValidation: this.mensagemErrorOps(message)
            });
          else {
            initializeReactGA({
              pageName: "transfer/fail",
              category: "EMPRESTIMO-TRANSFERENCIA",
              action: "Acesso_a_tela_de_opcoes_de_transferencia",
              label: "Reprovacao_de_emprestimo"
            });
            navigate(this.props, Routes.FAIL);
          }
        } else {
          navigate(this.props, Routes.HOME);
        }
      });
  };

  /**
   * @author Leonardo L
   * @copyright 04/2019
   * @description Atualiza estado do password e trata mensagem caso o campo esteja vazio
   */
  onChangePasswordInput = password => {
    if (password) this.setState({ password, validPassword: "" });
    else
      this.setState({
        password,
        validPassword: "Ops! Senha não foi preenchida :(",
        errorValidation: ""
      });
  };

  /**
   * @author Leonardo L
   * @copyright 04/2019
   * @returns valor booleano se password está preenchido
   * @description Valida se password foi preenchido
   */
  isPasswordInputValid = () => {
    const { validPassword } = this.state;
    return validPassword === "OK";
  };

  /**
   * @author Leonardo L
   * @copyright 04/2019
   * @returns string com mensagem de erro caso password não esteja preenchido
   * @description Retorna mensagem de erro para o caso de password não estar preenchido
   */
  getPasswordInputErrorText = () => {
    const { validPassword } = this.state;
    return !validPassword;
  };

  /**
   * @author Leonardo L
   * @copyright 04/2019
   * @returns valor booleano para habilitar botao
   * @description Valida se password foi preenchido para habilitar botao
   */
  isFormStateValid = () => {
    const { validPassword, password } = this.state;
    let validateInfo = !validPassword && password && password.length === 6;
    return validateInfo;
  };

  /**
   * @author Anderson O.
   * @copyright 04/2019
   * @description Renderiza informacoes para transferencia a serem conferidas
   */
  renderTransferData = (name, title, value) => {
    return (
      <div
        className="my-2 mx-0 row justify-content-between border-bottom"
        id={name}
      >
        <div className="col-0">{title}</div>
        <div className="col-0">{value}</div>
      </div>
    );
  };

  /**
   * @author Anderson Oliveira, Leonardo Lucas dos Santos
   * @copyright 02/2019
   * @description Método de aplicação de máscara de CPF
   */
  formatCPF = cpf => {
    if (cpf) {
      return VMasker.toPattern(cpf, "999.999.999-99");
    }
    return cpf;
  };

  renderContent = () => (
    <>
      <div className="row mb-4 mx-0">
        <div className="col-0">Confirme os dados da tranferência</div>
      </div>
      <div>
        {this.renderTransferData("BankInfo", "Banco", this.props.bankName)}
        {this.renderTransferData("AgencyInfo", "Agência", this.props.branch)}
        {this.renderTransferData("AccountInfo", "Conta", this.props.account)}
        {this.renderTransferData(
          "CPFInfo",
          "CPF",
          this.formatCPF(this.props.cpf)
        )}
        {this.renderTransferData(
          "MoneyInfo",
          "Valor",
          <TextMoney value={this.props.userBalance / 100} />
        )}
        {this.renderTransferData(
          "TaxInfo",
          "Taxa",
          <TextMoney isNegative value={this.state.transferTax} />
        )}
        {this.renderTransferData(
          "ResultMoneyInfo",
          "Total",
          <TextMoney
            value={this.props.userBalance / 100 - this.state.transferTax}
          />
        )}
      </div>
      <div className="mt-4">
        <PasswordInput
          name="passwordInput"
          placeholder="Senha"
          isRequired={true}
          maxLength={6}
          password={this.state.password}
          onChangeValue={this.onChangePasswordInput}
          successValidation={this.isPasswordInputValid() || "OK"}
          errorText={this.getPasswordInputErrorText()}
        />
      </div>
      <div className="col-12 text-center text-danger">
        {this.state.errorValidation}
      </div>
    </>
  );
  renderFooter = () => (
    <div className="row">
      <div className="col-12 text-center">
        <Button
          name="TranferButton"
          text="Transferir"
          onClick={this.onClick}
          disabled={!this.isFormStateValid()}
        />
      </div>
    </div>
  );

  render() {
    const header = <LoggedHeader text="Transferência" />;
    return (
      <GridLayout
        alignContent="center"
        alignFooter="center"
        alignHeader="center"
        content={this.renderContent()}
        header={header}
        footer={this.renderFooter()}
      />
    );
  }
}

const mapStateToProps = ({
  creditBankTransfer: {
    bank,
    bankName,
    branch,
    branchDigit,
    account,
    accountDigit,
    userBalance,
    id
  },
  user: { sessionToken, cpf }
}) => ({
  sessionToken: sessionToken,
  bank: bank,
  bankName: bankName,
  branch: branch + "-" + branchDigit,
  account: account + "-" + accountDigit,
  cpf: cpf,
  userBalance: userBalance,
  id: id
});

export default connect(mapStateToProps)(TransferDataConfirm);
