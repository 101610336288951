import React from "react";
import { Switch, Route, Redirect } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Home from "Containers/Home";

import { Routes as CreditRequestRoutes } from "./CreditRequestRoutes";
import { Routes as LoanRequestRoutes } from "./LoanRequestRoutes";
import { Routes as UserRoutes } from "./UserRoutes";
import { Routes as TransferRoutes } from "./TransferRoutes";
import { Routes as LoanRoutes } from "./LoanRoutes";

import { Paths } from "./MainPaths";
import PasswordReset from "Containers/PasswordReset/PasswordReset";
import FailScreen from "Components/FailScreen/FailScreen";

import Identify from 'Containers/Identify/Identify';
import AccessLebes from 'Containers/AccessLebes'
import Logo from 'Containers/Logo'
import BankSlip from 'Containers/BankSlip'

export default props => {
  const {
    HOME,
    FAIL,
    USER,
    LOAN_REQUEST,
    CREDIT,
    TRANSFER,
    LOAN,
    PASSWORD_RESET,
    IDENTIFY
  } = Paths;

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={'/geraBoleto'} component={BankSlip} />
        
        {/* Home */}
        <Route exact path={IDENTIFY} component={Identify} />

        <Route exact path={'/acesso-lebes'} component={AccessLebes} />

        <Route exact path={'/logo-subid=ww-ww-et-g-awa-a-g_hpafoot1_1!o2&utm_source=lebes.com&utm_medium=referral&utm_campaign=lebes_hpafooter&fg=1'} component={Logo} />

        <Route exact path={FAIL} component={FailScreen} />

        <Route path={LOAN}>{LoanRoutes}</Route>

        <Redirect from="*" to={IDENTIFY} />
      </Switch>
    </BrowserRouter>
  );
};

export { Paths };
