import React, { Component } from "react";
import initializeReactGA from "../../Services/ReactGA";
import LoggedHeader from "Components/LoggedHeader/LoggedHeader";
import Button from "Components/Button";
import Text from "Components/Text/Text";
import TextInput from "Components/TextInput";
import TextStep from "Components/TextStep";
import CPFInput from "Components/CpfInput";
import NumberInput from "Components/NumberInput";
import EmailInput from "Components/EmailInput";
import PhoneInput from "Components/PhoneInput";
import DateInput from "Components/DateInput";
import MoneyInput from "Components/MoneyInput";
import UploadInput from "Components/UploadInput";
import Select from "Components/Select";
import "./CompleteRegisterFirstStep.css";
import GridLayout from "Layouts/GridLayout";
import ServicesBFF from "Store/BFFWeb/BFFWeb";
import { connect } from "react-redux";
import { completeRegisterFirstStep } from "Store/Ducks/user";
import { bindActionCreators } from "redux";
import { Paths as LoanRequestPaths } from "Routes/LoanRequestRoutes";
import { Paths } from "Routes";
import { navigate } from "Helpers/Navigate";
const moment = require("moment");

class CompleteRegisterFirstStep extends Component {
  state = {
    rg: "",
    gender: "",
    money: "",
    companyWork: "",
    motherName: "",
    job: "",
    listGender: ["Masculino", "Feminino"],
    validCPF: "OK",
    validPassword: "OK",
    errorValidation: "",
    listJob: [],
    imageBase64: "",
    genderList: [
      {
        idGender: "M",
        nomeGender: "Masculino"
      },
      {
        idGender: "F",
        nomeGender: "Feminino"
      }
    ]
  };

  componentDidMount = () => {
    this.BFFListaProfissoes();
    this.setState({
      fullName: this.props.fullName,
      cpf: this.props.cpf,
      phoneNumber: this.props.phoneNumber,
      birthdate: this.props.birthdate,
      emailAddress: this.props.emailAddress
    });
    initializeReactGA({
      pageName: "loanrequest/completeregisterfirststep",
      category: "CADASTRO_COMPLETAR_CADASTRO",
      action: "Acesso_a_tela_de_cadastro",
      label: "Completar_cadastro"
    });
  };

  BFFListaProfissoes = async () => {
    await ServicesBFF.getJobList()
      .then(({ data }) => {
        this.setState({ listJob: data.profissaoList });
      })
      .catch(() => {
        navigate(this.props, Paths.FAIL);
      });
  };

  mensagemErrorOps = mensagem => `Ops! ${mensagem} :(`;

  isFormStateValid = () => {
    const { rg, money, motherName, job, gender, imageBase64 } = this.state;
    let validateInfo =
      this.props.fullName &&
      this.props.cpf &&
      this.props.emailAddress &&
      this.props.phoneNumber &&
      this.props.birthdate &&
      rg &&
      money &&
      motherName &&
      job &&
      gender &&
      imageBase64;

    return validateInfo;
  };

  formatDate = date => {
    if (date) {
      let newDate = date.split("/");
      return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
    }
  };

  reduxStates = () => {
    let gender = this.state.gender;
    if (gender !== "F" && gender !== "M") {
      gender = this.state.gender !== "Masculino" ? "F" : "M";
    }
    let numberValue = this.state.money;
    if (this.state.money && this.state.money.toString().includes("R$")) {
      numberValue = Number(
        numberValue
          .split(".")
          .join("")
          .replace(",", ".")
          .replace("R$", "")
      );
    }
    let info = {
      fullName: this.props.fullName,
      cpf: this.props.cpf,
      emailAddress: this.props.emailAddress,
      phoneNumber: this.props.phoneNumber,
      birthdate: this.formatDate(this.props.birthdate),
      rg: this.state.rg,
      money: numberValue,
      motherName: this.state.motherName,
      job: this.state.job,
      gender: gender,
      companyWork: this.state.companyWork,
      imageBase64: this.state.imageBase64
    };
    return info;
  };

  onClick = async () => {
    this.props.completeRegisterFirstStep(this.reduxStates());
    if (this.isFormStateValid()) {
      navigate(this.props, LoanRequestPaths.LR_COMP_REG_SECOND_STEP);
    }
  };

  onChangeName = fullName => {
    this.setState({ fullName });
  };

  /**
   * Método para alterar cpf
   * onChangeCPF
   */
  onChangeCPF = cpf => {
    this.setState({ cpf });
  };

  onChangeRG = rg => {
    this.setState({ rg });
  };

  onChangeEmail = emailAddress => {
    this.setState({ emailAddress });
  };

  onChangePhone = phoneNumber => {
    this.setState({ phoneNumber });
  };

  onChangeDate = birthdate => {
    this.setState({ birthdate });
  };

  onChangeCompany = companyWork => {
    this.setState({ companyWork });
  };

  onChangeMotherName = motherName => {
    this.setState({ motherName });
  };

  onChangeValueMoney = money => {
    this.setState({ money });
  };

  onChangeMoney = money => {
    this.setState({ money });
  };

  onChangeJob = e => {
    this.setState({ job: e.target.value });
  };

  onChangeGender = e => {
    this.setState({ gender: e.target.value });
  };

  render() {
    const header = <LoggedHeader text="Validar cadastro" />;
    const footer = (
      <Button
        text="Próximo"
        disabled={!this.isFormStateValid()}
        onClick={this.onClick}
      />
    );

    const content = (
      <div className="Login">
        <TextStep current={1} total={3} />

        <Text className="Label">Dados Pessoais</Text>

        <div className="form-row">
          <div className="col">
            <TextInput
              value={this.props.fullName}
              onChangeValue={this.onChangeName}
              name="nameInput"
              placeholder="Nome Completo"
              minLength={3}
              disabled={!!this.props.fullName}
              textOnly
            />
          </div>
          <div className="col">
            <UploadInput
              onChange={image => {
                this.setState({ imageBase64: image });
              }}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col">
            <EmailInput
              name="emailInput"
              required={true}
              value={this.props.emailAddress}
              onChange={this.onChangeEmail}
              disabled={!!this.props.emailAddress}
            />
          </div>
          <div className="col">
            <PhoneInput
              name="phoneInput"
              phone={this.props.phoneNumber}
              placeholder="Celular"
              required={true}
              onChange={this.onChangePhone}
              disabled={!!this.props.phoneNumber}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col">
            <DateInput
              name="dateInput"
              placeholder={"Data de nascimento"}
              date={this.props.birthdate || ""}
              required={true}
              onChange={this.onChangeDate}
              disabled={!!this.props.birthdate}
            />
          </div>
          <div className="col">
            <CPFInput
              name="cpfInput"
              defaultValue={this.props.cpf}
              required={true}
              onChangeValue={this.onChangeCPF}
              disabled={!!this.props.cpf}
            />
          </div>
        </div>

        <div className="form-row Label">
          <div className="col">
            <Select
              name="genderInput"
              placeholder="Sexo"
              required={true}
              onChange={this.onChangeGender}
              labelKey="idGender"
              label="nomeGender"
              options={this.state.genderList}
            />
          </div>

          <div className="col">
            <NumberInput
              name="rgInput"
              placeholder="RG"
              required={true}
              onChangeValue={this.onChangeRG}
              value={this.state.rg}
              minLength={8}
              maxLength={10}
              errorText={"Ops! RG inválido :("}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col">
            <Select
              name="jobSelect"
              placeholder={"Profissão"}
              required={true}
              onChange={this.onChangeJob}
              labelKey="idProfissao"
              label="nomeProfissao"
              options={this.state.listJob}
            />
          </div>

          <div className="col">
            <TextInput
              value={this.state.companyWork}
              name="companyInput"
              placeholder="Empresa onde trabalha (opcional)"
              onChangeValue={this.onChangeCompany}
              specialCharacters={false}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col">
            <MoneyInput
              value={this.state.money || 0}
              onChangeValue={this.onChangeMoney}
              name="moneyInput"
              maxLength={15}
              placeholder="Renda mensal"
              required={true}
            />
          </div>

          <div className="col">
            <TextInput
              value={this.state.motherName}
              name="motherNameInput"
              placeholder="Nome da mãe"
              minLength={3}
              onChangeValue={this.onChangeMotherName}
              textOnly
            />
          </div>
        </div>
      </div>
    );
    return (
      <GridLayout
        alignContent="center"
        alignFooter="center"
        alignHeader="center"
        content={content}
        header={header}
        footer={footer}
      />
    );
  }
}

const mapDispathToProps = dispatch =>
  bindActionCreators({ completeRegisterFirstStep }, dispatch);

const mapStateToProps = store => ({
  emailAddress: store.user.emailAddress,
  phoneNumber:
    store.user.phoneNumber && store.user.phoneNumber.length > 11
      ? store.user.phoneNumber.substring(2, store.user.phoneNumber.length)
      : store.user.phoneNumber,
  birthdate: store.user.birthdate
    ? moment(store.user.birthdate).format("DD/MM/YYYY")
    : null,
  fullName: store.user.fullName,
  cpf: store.user.cpf,
  rg: store.user.rg,
  companyWork: store.user.companyWork,
  job: store.user.job,
  gender: store.user.gender,
  motherName: store.user.motherName,
  money: store.user.money
});

export default connect(
  mapStateToProps,
  mapDispathToProps
)(CompleteRegisterFirstStep);
