import React, { Component } from "react";
import Feedback from "Containers/FeedBack";
import { connect } from "react-redux";
import { Paths } from "Routes";
import VMasker from "vanilla-masker";
import Text from "../../Components/Text/Text";
import { navigate } from "Helpers/Navigate";

import initializeReactGA from "Services/ReactGA";

class TransferStoreSuccess extends Component {
  componentDidMount() {
    initializeReactGA({
      pageName: "transfer/transferstoresucess",
      category: "EMPRESTIMO-TRANSFERENCIA",
      action: "Acesso_a_tela_de_opcoes_de_transferencia",
      label: "Confirmacao_da_retirada_na_loja"
    });
  }

  goToHome = () => {
    navigate(this.props, Paths.HOME);
  };
  render() {
    const { store } = this.props;
    return (
      <Feedback
        headerTitle="Transferência"
        summary="Uhuul!"
        details={
          <Text>
            Seu dinheiro estará disponível na loja Lebes {store.endereco.cidade}{" "}
            em até 24h.{"\n"}Qualquer dúvida nos ligue
            {"\n"}
            {VMasker.toPattern(store.telefone.telefone, "(99) 9999-9999")}
          </Text>
        }
        emoji="\o/"
        textButton="Voltar para home"
        submit={this.goToHome}
      />
    );
  }
}
const mapStateToProps = ({ stores }, { location }) => {
  const store = stores.find(
    ({ codigoLoja }) => codigoLoja === location.state.codigoLoja
  );
  return { store };
};

export default connect(mapStateToProps)(TransferStoreSuccess);
