import React from "react";
import logo from "./lebes-logo.png";
import "./HeaderLogo.css";
import { NavLink } from "react-router-dom";
import { Paths } from "Routes";
import DrawerContent from "Containers/DrawerContent/DrawerContent";

function HeaderLogo(props) {
  return (
    <div>
      <div className={`HeaderLogo ${props.isHome ? "HeaderLogo-sticky" : ""}`}>
        <DrawerContent />
        <div className="Logo">
          <NavLink className="Image" to={Paths.HOME}>
            <img src={logo} alt="logo" />
          </NavLink>
          {process.env.REACT_APP_NODE_ENV === "development" && (
            <div className="Text text-danger text-weight-bold ml-3">
              Homologação
            </div>
          )}
        </div>
      </div>
      <div className={`${props.isHome ? "Header-padding" : ""}`}></div>
    </div>
  );
}

export default React.memo(HeaderLogo);
