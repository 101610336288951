import BFFWeb from "../BFFWeb/BFFWeb";

import NavigationService from "../../Services/NavigationService";

export const Types = {
  INSTALLMENTS_RECEIVED: "installments/received",
  INSTALLMENTS_RESET: "installments/reset",
  INSTALLMENTS_RESET_CONTRATOS: "installments/resetContratos",
  LOADED: "installments/loaded",
  SET: "installments/set",
  INSTALLMENT_ERROR_MESSAGE: "installments/error"
};

/* Initial State */
const initialState = {
  banco: "",
  localDePagamento: "",
  beneficiarioNome: "",
  beneficiarioEndereco: "", 
  beneficiarioCNPJ: "",
  especieDoc: "",
  aceite: "",
  nossoNumeroBoleto: "",
  usoDoBanco: "",
  especie: "",
  instrucoes: "",
  mensagemBoleto: "",
  mensagemPagemento: "",
  contratosParcelas: [],
  agenciaCodigoDoBeneficiario: "",
  agencia: "",
  codigoCedente: "",
  contaCorrente: "",
  carteira: "",
  nossoNumero: "",
  dvNossoNumero: "",
  dtEmissao: "",
  vencimento: "",
  fatorVencimento: "",
  numeroDocumento: "",
  sacadoCPF: "",
  sacadoNome: "",
  sacadoEndereco: "",
  sacadoCEP: "",
  linhaDigitavel: "",
  codigoBarras: "",
  valorTotal: "",
  installmentsData: {
    taxaEmissaoBoleto: "",
    contratos: []
  },
  error: null,
  errorMobile: "",
  loaded: false,
  caixaObs1: "",
  caixaObs2: "",
  caixaObs3: "",
  caixaObs4: ""
};

/* Reducer */
export default function clientInstallments(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case Types.INSTALLMENTS_RECEIVED:
      return { ...state, installmentsData: payload };
    case Types.SET:
      return { ...state, ...payload };
    case Types.LOADED:
      return { ...state, loaded: true };
    case Types.INSTALLMENTS_RESET_CONTRATOS:
      return {
        ...state,
        installmentsData: { ...state.installmentsData, contratos: [] },
        errorMobile: "",
        loaded: false
      };
    case Types.INSTALLMENTS_RESET:
      return initialState;
    case Types.INSTALLMENT_ERROR_MESSAGE: {
      return { ...state, errorMobile: payload };
    }
    default:
      return state;
  }
}

/* Action Creators */
export function reset(payload) {
  return {
    type: Types.INSTALLMENTS_RESET,
    payload
  };
}

export function resetContratos() {
  return {
    type: Types.INSTALLMENTS_RESET_CONTRATOS
  };
}

export function received(payload) {
  return {
    type: Types.INSTALLMENTS_RECEIVED,
    payload
  };
}

function set(payload) {
  return {
    type: Types.SET,
    payload
  };
}

/* Handlers */
export function handleFetch() {
  return (dispatch, getState) => {
    const { user } = getState();

    const isEasy = true;

    BFFWeb.getClientInstallments(user.cpf, isEasy)
      .then(({ data }) => {
        dispatch(received(data));
        dispatch({ type: Types.LOADED });
      })
      .catch(e => {
        // console.warn(e);
      });
  };
}

export function emiteBoleto(parcelas, vencimento) {
  return (dispatch, getState) => {
    const { user, clientInstallments } = getState();
    const { installmentsData } = clientInstallments;

    const isEasy = true;

    return BFFWeb.generateTicket(
      user.cpf,
      parcelas,
      vencimento,
      installmentsData.taxaEmissaoBoleto,
      isEasy
    )
      .then(res => {
        const { 
          valorTotal, 
          linhaDigitavel, 
          banco,
          localDePagamento,
          beneficiarioNome,
          beneficiarioEndereco,
          beneficiarioCNPJ,
          especieDoc,
          aceite,
          nossoNumeroBoleto,
          usoDoBanco,
          especie,
          instrucoes,
          mensagemBoleto,
          mensagemPagemento,
          contratosParcelas,
          agenciaCodigoDoBeneficiario,
          agencia,
          codigoCedente,
          contaCorrente,
          carteira,
          nossoNumero,
          dvNossoNumero,
          dtEmissao,
          vencimento,
          fatorVencimento,
          numeroDocumento,
          sacadoCPF,
          sacadoNome,
          sacadoEndereco,
          sacadoCEP,
          codigoBarras,
          caixaObs1,
          caixaObs2,
          caixaObs3,
          caixaObs4,
        } = res.data;

        dispatch(set({
          valorTotal, 
          linhaDigitavel, 
          banco,
          localDePagamento,
          beneficiarioNome,
          beneficiarioEndereco,
          beneficiarioCNPJ,
          especieDoc,
          aceite,
          nossoNumeroBoleto,
          usoDoBanco,
          especie,
          instrucoes,
          mensagemBoleto,
          mensagemPagemento,
          contratosParcelas,
          agenciaCodigoDoBeneficiario,
          agencia,
          codigoCedente,
          contaCorrente,
          carteira,
          nossoNumero,
          dvNossoNumero,
          dtEmissao,
          vencimento,
          fatorVencimento,
          numeroDocumento,
          sacadoCPF,
          sacadoNome,
          sacadoEndereco,
          sacadoCEP,
          codigoBarras,
          caixaObs1,
          caixaObs2,
          caixaObs3,
          caixaObs4,
        }));

        NavigationService.navigate("InstallmentValueConfirmation");
      })
      .catch(e => {
        const error = "Erro ao gerar o boleto. Por favor tente mais tarde";
        dispatch({
          type: Types.INSTALLMENT_ERROR_MESSAGE,
          payload: error
        });
      });
  };
}
/* Handlers */
