import React from "react";
import PropTypes from "prop-types";
import "./Card.css";

/**
 * @param {object} props
 * @param {any} props.children
 * @param {string} props.className
 * @param {object} props.style
 * @param {number} props.padding
 */
const Card = ({ children, className, padding, style }) => (
  <div className={`card bg-white ${className}`} style={{ padding, ...style }}>
    {children}
  </div>
);

Card.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  padding: PropTypes.number,
  style: PropTypes.object
};

export default Card;
