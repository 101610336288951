import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FaPlus, FaMinus } from "react-icons/fa";
import { formatMoney } from "./Format";
import "./InputRange.css";

/**
 * @author Márcio Geremia, Leonardo Lucas dos Santos
 * Exemplo de uso:
        <InputRange
          value={range}
          onChangeValue={range => {
            this.setState({ range });
          }}
        />
 */
const InputRange = props => {
  const [value, setValue] = useState(0);
  const [options, setOptions] = useState([0]);

  const { onChangeValue, name } = props;

  /**
   * @author Márcio Geremia, Leonardo Lucas dos Santos
   * @description Esta função tem o objetivo definir o comportamento da cor gradiente utilizada no range.
   * @param {} value - Valor atual do range.
   * @param {} min - Mínimo configurado no range.
   * @param {} max - Mínimo configurado no range.
   */
  const calculateBackgroundImageEffect = () => {
    let position = value / (options.length - 1);
    return {
      backgroundImage: [
        "-webkit-gradient(",
        "linear, ",
        "left top, ",
        "right top, ",
        "color-stop(" + position + ", #05AD36), ",
        "color-stop(" + position + ", #C5C5C5)",
        ")"
      ].join("")
    };
  };

  function createInitialValue(initialValue, min) {
    if (min && initialValue !== Number(min)) return [Number(min), initialValue];
    return [initialValue];
  }

  function createEndValue(endValue, maxValue) {
    if (endValue === Number(maxValue)) return [endValue];
    return [endValue, Number(maxValue)];
  }

  function mapValues(value, step, min) {
    const length = value / step;
    let mapValue = [];
    for (let i = 1; i <= length; i++) {
      mapValue.push(step * i);
    }
    let endValue = createEndValue(mapValue.pop(), value);
    mapValue = mapValue.filter(item => item >= min);
    return [
      ...createInitialValue(mapValue.shift(), min),
      ...mapValue,
      ...endValue
    ];
  }

  function getIndexFromValue(options, value) {
    let el = options.findIndex(el => el === value);
    if (el < 0) return 0;
    return el;
  }

  useEffect(() => {
    const options = mapValues(props.max, props.step, props.min);
    setOptions(options);
    setValue(getIndexFromValue(options, Number(props.value)));
  }, [props.value, props.step, props.max, props.min]);

  /**
   * @author Márcio Geremia, Leonardo Lucas dos Santos
   * @description Esta função tem o objetivo de aplicar a alteração na color do bg e também aplicar o valor no state do pai.
   * @param {} value - Valor atual do range.
   */
  const onChange = e => setNewValue(e.target.value);

  /**
   * @author Leonardo Lucas dos Santos
   * @description Função para setar valores e fazer callbacks de valor numéricos
   * @param {*} value
   */
  const setNewValue = value => {
    let newValue = Number(value);
    setValue(newValue);
    onChangeValue && onChangeValue(options[newValue]);
  };

  /**
   * @author Márcio Geremia, Leonardo Lucas dos Santos
   * @description Esta função tem o objetivo de subtrair o step do valor da range.
   */
  const onClickMinus = () => value > 0 && setNewValue(value - 1);

  /**
   * @author Márcio Geremia, Leonardo Lucas dos Santos
   * @description Esta função tem o objetivo de somar o step no valor da range.
   */
  const onClickPlus = () =>
    value < options.length - 1 && setNewValue(value + 1);

  function renderValor(options, value) {
    return ` ${formatMoney(options[value].toFixed(2))}`;
  }

  return (
    <>
      <div className="col-12 TextMoneyRange text-center">
        <strong style={{ color: "rgb(197 197 197)" }}>R$</strong>
        <strong className="value-range">{renderValor(options, value)}</strong>
      </div>
      <div
        className="row mb-2 ml-0 mr-0 justify-content-around"
        style={{ marginTop: "-20px" }}
      >
        <div className="col-6 text-left">
          <FaMinus
            size="15pt"
            onClick={onClickMinus}
            className="cursor-pointer"
            color="#05AC36"
          />
        </div>
        <div className="col-6 text-right">
          <FaPlus
            size="15pt"
            onClick={onClickPlus}
            className="cursor-pointer"
            color="#05AC36"
          />
        </div>
      </div>
      <div className="row m-0">
        <input
          className="form-control-range"
          type="range"
          min={0}
          max={options.length - 1}
          step={1}
          style={calculateBackgroundImageEffect()}
          value={value}
          onChange={onChange}
          name={name}
        />
      </div>
    </>
  );
};

InputRange.propTypes = {
  // Valor inicial para o input range
  value: PropTypes.number,
  // Tamanho para salto do valor
  step: PropTypes.number,
  // Valor minimo para input
  min: PropTypes.number,
  // Valor maximo para input
  max: PropTypes.number,
  // Evento de atualização de valor
  onChangeValue: PropTypes.func
};

InputRange.defaultProps = {
  step: 1,
  min: 0,
  max: 100,
  value: 1
};

export default InputRange;
