export default function redirectToErrorPage(error) {
  if (error.response) {
    if ([404, 500].includes(error.response.status)) {
      window.location.href = "/fail";
    }

    if (error.response.data.auth) {
     // window.location.href = "/user/identification";
    }
  } else {
    window.location.href = "/fail";
  }
}
