import React from "react";
import Feedback from "Containers/FeedBack";

const CreditRequestError = props => {
  const goToHome = () => {
    window.location.href = "/";
  };

  return (
    <Feedback
      bigSummaryText
      headerTitle="Ops..."
      summary="Para fazer um empréstimo você precisa ter crédito aprovado"
      emoji=":("
      textButton="OK"
      submit={goToHome}
    />
  );
};

export default CreditRequestError;
