import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { navigateCreditRequest } from "Store/Ducks/user";
import Loader from "Components/Loader/Loader";
import { navigate } from "Helpers/Navigate";

/**
 * @author Guilherme Z
 * @description HOC que valida usuário com permissão de fazer empréstimo
 * @copyright 05/2019
 */
const protectCreditRequest = CreditRequest => props => {
  const { shouldNavigateCreditRequest, ...rest } = props;

  if (shouldNavigateCreditRequest) {
    return <CreditRequest {...rest} />;
  }
  navigate(props, "/credit/error");

  return <Loader loading={true} />;
};

const mapStateToProps = ({ user }) => ({
  shouldNavigateCreditRequest: navigateCreditRequest(user)
});

const composedProtectCreditRequest = compose(
  connect(mapStateToProps),
  protectCreditRequest
);

export default composedProtectCreditRequest;
