import React from "react";
import Feedback from "Containers/FeedBack";

function RequestTransferFail(props) {
  return (
    <Feedback
      goBack={props.history.goBack}
      title="Ops"
      summary="Você não possui saldo em carteira para solicitar transferência"
      emoji=":("
      textButton="Pronto"
      submit={props.history.goBack}
    />
  );
}

export default RequestTransferFail;
