import React from "react";
import Feedback from "Containers/FeedBack";
import { Paths } from "Routes";
import { withRouter } from "react-router";

const CreditRequestSuccess = props => {
  const goToHome = () => {
    props.history.push(Paths.HOME);
  };

  return (
    <Feedback
      headerTitle="Transferência para contato"
      details="Prontinho! Sua transferência foi realizada com sucesso!"
      emoji=":)"
      summary=""
      textButton="Voltar para home"
      submit={goToHome}
    />
  );
};

export default withRouter(CreditRequestSuccess);
