import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./CpfInput.css";

const CpfInput = props => {
  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (props.defaultValue) {
      setValue(formatCPF(props.defaultValue));
    }
  }, [props.defaultValue]);

  /**
   * @author Anderson Oliveira, Leonardo Lucas dos Santos
   * @copyright 02/2019
   * @description Evento de atualização de estado ao digitar número de CPF
   */
  const onChange = e => {
    let newValue = formatCPF(e.target.value);
    const newValid = validate(newValue);

    setValue(newValue);
    setError(newValid);

    if (newValue.length === 14 && !newValid) {
      onChangeValue(newValue);
    } else {
      onChangeValue("");
    }
  };

  /**
   * @author Anderson Oliveira, Leonardo Lucas dos Santos
   * @copyright 02/2019
   * @description Evento de atualização de estado ao digitar número de CPF
   */
  const onBlur = e => {
    onChange(e);
  };

  /**
   * @author Anderson Oliveira, Leonardo Lucas dos Santos
   * @copyright 02/2019
   */
  const onChangeValue = value => {
    props.onChangeValue && props.onChangeValue(value.replace(/[^\d]+/g, ""));
  };

  /**
   * @author Anderson Oliveira, Leonardo Lucas dos Santos
   * @copyright 02/2019
   * @description Método de aplicação de máscara de CPF
   */
  const formatCPF = cpf => {
    cpf = cpf.replace(/[^\d]/g, "");
    cpf = cpf.substr(0, 11);
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1-$2");
    return cpf;
  };

  /**
   * @author Leonardo Lucas dos Santos
   * @description Método para montar as mensagens padronizadas
   */
  const errorMessage = message => `Ops! ${message} :(`;

  /**
   * @author Anderson Oliveira, Leonardo Lucas dos Santos
   * @copyright 02/2019
   * @description Método de validação de CPF válido
   */
  const validate = value => {
    let errorText = "";
    if (props.required && !value) {
      errorText = "CPF não foi preenchido";
    } else if (value) {
      let calc;
      let rest;
      let i;
      let CPF = value.replace(/[^\d]+/g, "");
      calc = 0;

      if (CPF % "11111111111" === 0) {
        errorText = "CPF inválido";
      }

      for (i = 1; i <= 9; i++)
        calc = calc + parseInt(CPF.substring(i - 1, i)) * (11 - i);
      rest = (calc * 10) % 11;

      if (rest === 10 || rest === 11) rest = 0;
      if (rest !== parseInt(CPF.substring(9, 10))) {
        errorText = "CPF inválido";
      }

      calc = 0;
      for (i = 1; i <= 10; i++)
        calc = calc + parseInt(CPF.substring(i - 1, i)) * (12 - i);
      rest = (calc * 10) % 11;

      if (rest === 10 || rest === 11) rest = 0;
      if (rest !== parseInt(CPF.substring(10, 11))) {
        errorText = "CPF inválido";
      }
    }
    return errorText;
  };

  const {
    placeholder,
    className,
    errorText,
    name,
    disabled,
    isBoxInput
  } = props;

  const joinClassNames = () => {
    return [
      isBoxInput ? "form-control" : "line-input",
      className,
      error || errorText ? "is-invalid" : "was-validated"
    ].join(" ");
  };

  const showError = () => 
    (error || errorText) && (
      <div className={isBoxInput ? "invalid-feedback" : "mt-4 text-error"}>
        {errorMessage(error || errorText)}
      </div>
    );

  return (
    <div>
      <input
        autoComplete="off"
        className={joinClassNames()}
        type="text"
        maxLength="14"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        disabled={disabled}
      />
      {showError()}
    </div>
  );
};

CpfInput.defaultProps = {
  placeholder: "CPF",
  required: false,
  defaultValue: "",
  /* Campo habilitado por valor padrao */
  disabled: false,
  isBoxInput: true
};

CpfInput.propTypes = {
  // Campo utilizado no atributo placeholder do <input/>
  placeholder: PropTypes.string,
  // Campo utilizado no atributo class do <input/>
  className: PropTypes.string,
  // Campo utilizado no atributo required do <input/>
  required: PropTypes.bool,
  // Function utilizada para executar quando usuario informar valor valido.
  onChangeValue: PropTypes.func.isRequired,
  // Campo utilizado como valor de inicializacao
  defaultValue: PropTypes.string,
  /* Desabilita campo se valor pre carregado */
  disabled: PropTypes.bool,
  //Define se o input será em forma de caixa ou não
  isBoxInput: PropTypes.bool
};

export default CpfInput;
