import React, { Component } from "react";
import PhoneInput from "../PhoneInput/PhoneInput";

import { FaEdit } from "react-icons/fa";
import { PropTypes } from "prop-types";

import "./PhoneInputEditable.css";

/**
 * @author Guilherme Zordan
 * @description Exibição de número de telefone com possibilidade de ser editado
 * @example
 *    ...
 *    state = { phone: "(54) 99999-9999" } o ideal é que inicie com um valor
 *    ...
 *   <PhoneInputEditable
 *   phone={this.state.phone}
 *   onBlur={phone => this.setState({phone})}
 *   />
 *
 */
export default class PhoneInputEditable extends Component {
  state = {
    editing: false
  };

  static propTypes = {
    phone: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  };

  save = () => this.setState({ editing: false });

  edit = () => this.setState({ editing: true });

  /**
   * @author Vicente Balaguer
   * @copyright 03/2019
   * @description Método de aplicação de máscara de Telefone
   * @param Valor para ser formatado
   * @return Retorna o valor no formato de Telefone
   */
  formatPhone = phone => {
    if (phone) {
      phone = phone.replace(/[^\d]/g, "");
      phone = phone.replace(/^(\d)/, "($1");
      phone = phone.replace(/(\d{2})(\d)/, "$1)$2");
      phone = phone.replace(/(\d{5})(\d)/, "$1-$2");
    }

    return phone;
  };

  onChange = value => {
    if (value) this.props.onChange(value.replace(/[^\d]+/g, ""));
  };

  render() {
    const { edit } = this;
    const { editing } = this.state;
    const { phone, className, errorText, name } = this.props;

    if (editing) {
      return (
        <div className="PhoneInputEditable">
          <div>
            <PhoneInput
              className={className ? className : ""}
              phone={this.formatPhone(phone.slice(2))}
              onChange={this.onChange}
              onBlur={this.save}
              name={name}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="PhoneInputEditable">
            <div className="Phone">{this.formatPhone(phone.slice(2))}</div>
            <div className="EditButton">
              <FaEdit onClick={edit} />
            </div>
          </div>
          <div className="ErrorText">{errorText === "OK" ? "" : errorText}</div>
        </div>
      );
    }
  }
}
