import React from "react";
import Feedback from "Containers/FeedBack";

const CreditRequestFail = props => {
  const goToHome = () => {
    window.location.href = "/";
  };
  return (
    <Feedback
      headerTitle="Crédito Pessoal Lebes"
      details="Falha na solicitação de empréstimo, tente mais tarde"
      summary="Ops.."
      emoji=":("
      textButton="Voltar para home"
      submit={goToHome}
    />
  );
};

export default CreditRequestFail;
