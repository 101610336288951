import React, { Component } from "react";
import { connect } from "react-redux";
import DrawerMenu from "Components/DrawerMenu/DrawerMenu";
import { withRouter } from "react-router";
import { navigate } from "Helpers/Navigate";
import { Paths as CreditRequestPaths } from "Routes/CreditRequestRoutes";
import { Paths as LoanPaths } from "Routes/LoanRoutes";
import { Paths as UserPaths } from "Routes/UserRoutes";
import { Paths as ExternalPaths } from "Routes/ExternalPaths";

import calculadora from "../../images/drawer_calculadora.png";
import perfil from "../../images/drawer_perfil.png";
import extrato from "../../images/drawer_extrato.png";
import compras from "../../images/drawer_compras.png";
import pin from "../../images/drawer_pin.png";
import logout from "../../images/drawer_logout.png";
import politica from "../../images/drawer_politica.png";
import contato from "../../images/contato.png";

/**
 * @author Leonardo L
 * @description Componente que renderiza o menu
 * @copyright 05/2019
 */
class DrawerContent extends Component {
  /**
   * @author Leonardo L
   * @copyright 05/2019
   * @description Metodo para navegação externa
   */
  navigateToScreenExternal = route => {
    window.location.assign(route);
  };

  /**
   * @author Leonardo L
   * @copyright 05/2019
   * @description Rota de navegação para compras
   */
  buy = () => this.navigateToScreenExternal(ExternalPaths.BUY);

  /**
   * @author Leonardo L
   * @copyright 05/2019
   * @description Rota de navegação para lojas
   */
  stores = () => this.navigateToScreenExternal(ExternalPaths.STORES);

  /**
   * @author Leonardo L
   * @copyright 05/2019
   * @description Rota de navegação para atendimentos
   */
  attendance = () => this.navigateToScreenExternal(ExternalPaths.ATTENDANCE);

  logout = () => {
    this.props.dispatch({ type: "user/UNSET" });
    navigate(this.props, "Home");
  };

  /**
   * @author Leonardo Lnavigate
   * @copyright 05/2019
   * @description Metodo de renderização do corpo do menu
   */
  options = [
    {
      icon: perfil,
      action: () => navigate(this.props, UserPaths.USER_PROFILE),
      children: "Visualizar perfil",
      visible: this.props.user.isLogged
    },
    {
      icon: calculadora,
      action: () => navigate(this.props, CreditRequestPaths.CREDIT_REQUEST),
      children: "Fazer empréstimo",
      visible: true
    },
    {
      icon: compras,
      action: () => this.buy(),
      children: "Comprar online",
      visible: true
    },
    {
      icon: extrato,
      action: () => navigate(this.props, LoanPaths.INSTALLMENTS),
      children: "Pagar parcelas",
      visible: true
    },
    {
      icon: pin,
      action: () => this.stores(),
      children: "Nossas Lojas",
      visible: true
    },
    {
      icon: contato,
      action: () => this.attendance(),
      children: "Precisa de ajuda?",
      visible: true
    },
    {
      icon: politica,
      action: () => navigate(this.props, UserPaths.USER_PRIVACY_POLICY),
      children: "Política de privacidade e termo de uso",
      visible: true
    },
    {
      icon: logout,
      action: () => this.logout(),
      children: "Sair",
      visible: this.props.user.isLogged
    }
  ];

  /**
   * @author Leonardo L
   * @description redireciona mapDispathToPropspara login caso nao esteja logado
   * @param {*} isLogged
   */
  redirectToLogin(isLogged) {
    if (!isLogged) {
      navigate(this.props, "Login");
      return true;
    }
    return false;
  }

  render() {
    return <DrawerMenu items={this.options} user={this.props.user} />;
  }
}

const mapStateToProps = ({ user }) => ({
  user: {
    name: user.fullName,
    isLogged: user.isLogged
  }
});

export default withRouter(connect(mapStateToProps)(DrawerContent));
