import React, { PureComponent } from "react";
import Button from "../../Components/Button";
import Text from "../../Components/Text/Text";
import LoggedHeader from "../../Components/LoggedHeader";
import GridLayout from "Layouts/GridLayout";
import "./FeedBack.css";
import PropTypes from "prop-types";

/**
 * @author Leonardo Lucas dos Santos
 * @description Componente que monta o conteiner de mensagem de erro
 * @param {*} props
 * @example
 *      <FeedBack
 *         goBack={props.history.goBack}
 *         title="Bom dia"
 *         summary="Mensagem basica"
 *         emoji=":)"
 *         textButton="Pronto"
 *         details={<div>Pode ser uma string</div>}
 *       />
 */
export default class FeedBack extends PureComponent {
  static propTypes = {
    // função para retornar tela
    goBack: PropTypes.func,
    // recebe o titulo para o aviso ou titulo do header
    title: PropTypes.string,
    // recebe um resumo para o evento
    summary: PropTypes.string,
    // define se o tamanho do texto do sumário será grande ou não
    bigSummaryText: PropTypes.bool,
    // recebe emoji para exibição
    emoji: PropTypes.string,
    // palavra do botão
    textButton: PropTypes.string,
    // conteudo do corpo do feedback
    details: PropTypes.any,
    // função que trato o click do botão
    submit: PropTypes.func
  };

  render() {
    const {
      title,
      headerTitle,
      summary,
      bigSummaryText,
      emoji,
      details,
      submit,
      textButton,
      goBack
    } = this.props;

    const loggedHeaderTittle = headerTitle ? headerTitle : title;
    const feedBackHeader =
      loggedHeaderTittle && goBack ? (
        <LoggedHeader text={loggedHeaderTittle} navFunction={goBack} />
      ) : (
        <LoggedHeader text={loggedHeaderTittle} hasNavigation={false} />
      );

    const feedBackTitle =
      title && !goBack ? <Text className="FeedBackTitle">{title}</Text> : "";

    const feedBackButton = textButton && (
      <Button
        className="FeedBackButton"
        text={textButton}
        onClick={() => submit()}
        name="btnFeedBack"
      />
    );

    const summaryClassName = "FeedBackSummary ".concat(
      bigSummaryText ? "summary-large" : ""
    );

    const feedback = (
      <div className="FeedBack">
        <div className="FeedBackContent">
          {feedBackTitle}
          <Text className={summaryClassName}>{summary}</Text>
          <Text className="FeedBackEmoji">{emoji}</Text>
          <Text>{details}</Text>
        </div>
      </div>
    );
    return (
      <GridLayout
        alignContent="center"
        alignFooter="center"
        alignHeader="center"
        content={feedback}
        header={feedBackHeader}
        footer={feedBackButton}
      />
    );
  }
}
