import GridLayout from "Layouts/GridLayout/GridLayout";
import { Paths as LoanPaths } from "Routes/LoanRoutes";
import TextMoney from "Components/TextMoney/TextMoney";
import CheckBox from "Components/CheckBox/CheckBox";
import LoggedHeader from "Components/LoggedHeader";
import initializeReactGA from "Services/ReactGA";
import Button from "Components/Button/Button";
import Dialog from "../../Components/Dialog";
import { navigate } from "Helpers/Navigate";
import FilterDialog from "./FilterDialog";
import React, { Component } from "react";
import Select from "Components/Select";
import { connect } from "react-redux";
import { Paths } from "Routes/";
import "./Installments.css";
import moment from "moment";
import {
  handleFetch,
  emiteBoleto,
  reset
} from "../../Store/Ducks/clientInstallments";

import { formatDate } from '../../Helpers/Formatters';

const valueFormat = "YYYY-MM-DD";

class Installments extends Component {
  state = {
    selectedInstallments: [],
    vencimentoBoleto: "",
    filters: {},
    options: []
  };

  componentDidMount() {
    try {
      this.props.reset();
      this.props.handleFetch();
    } catch(e){}
  }

  /**
   * @author Leonardo L, Guilherme Z
   * @description onChange de valor selecionado
   * @copyright 05/2019
   */
  check = (value, installment) => {
    this.setState(state => this.setChecked(state, value, installment));
  };

  /**
   * @author Anderson S, Guilherme Z
   * @copyright 04/2019
   */
  emitirBoleto = async dataVencimento => {
    const { vencimentoBoleto, selectedInstallments } = this.state;

    dataVencimento = vencimentoBoleto ? vencimentoBoleto : dataVencimento;

    this.props.emiteBoleto(selectedInstallments, dataVencimento);
    this.setState({ selectedInstallments: [] });
  };

  /**
   * @author Anderson S, Guilherme Z
   * @copyright 04/2019
   */
  submit = () => {
    const { selectedInstallments } = this.state;

    if (selectedInstallments.length > 1) {
      if (selectedInstallments.length) {
        if (
          selectedInstallments.every(
            selectedInstallments => selectedInstallments["isDelayed"] === true
          )
        ) {
          let dataVencAtual = moment().format(valueFormat);
          this.emitirBoleto(dataVencAtual);
        }
        let primeiraParcela;
        selectedInstallments
          .filter(({ isDelayed }) => !isDelayed)
          .forEach(currInstallment => {
            if (
              !primeiraParcela ||
              moment(currInstallment.vctoParcela).isBefore(
                primeiraParcela.vctoParcela
              )
            ) {
              primeiraParcela = currInstallment;
            }
          });

        if (primeiraParcela) {
          primeiraParcela = {
            vctoParcela: primeiraParcela.vctoParcela,
            vctoParcelaLabel: formatDate(primeiraParcela.vctoParcela)
          };
          const dateOptions = [
            {
              vctoParcela: moment().format("YYYY-MM-DD"),
              vctoParcelaLabel: moment().format("DD/MM/YYYY")
            },
            primeiraParcela
          ];

          this.setState({ options: dateOptions });
          this.dateDialog.open();
        }
      }
    } else {
      if (
        selectedInstallments.every(
          selectedInstallments => selectedInstallments["isDelayed"] === true
        )
      ) {
        let dataVencAtual = moment().format("YYYY-MM-DD");
        this.emitirBoleto(dataVencAtual);
      } else
        selectedInstallments.map(parcelaSel => {
          let dataVenc = parcelaSel.vctoParcela;
          return this.emitirBoleto(dataVenc);
        });
    }
  };
  /**
   * @author Leonardo L, Guilherme Z
   * @description Ação de seleção de de itens
   * @copyright 05/2019
   */
  setChecked(state, value, installment) {
    let selectedInstallments = [];
    if (value) {
      selectedInstallments = [...state.selectedInstallments, installment];
    } else {
      //Desmarca parcelas posteriores do mesmo contrato;
      const filterFn = selected => {
        const isNotSameInstallment = selected !== installment;
        const isNotSameContract = selected.numeroContrato !== installment.numeroContrato;
        const isPrevious = selected.seqParcela < installment.seqParcela;

        return (isNotSameInstallment && isPrevious) || isNotSameContract;
      }

      selectedInstallments = state.selectedInstallments.filter(filterFn);
    }
    return { ...state, selectedInstallments };
  }

  /**
   * @author Leonardo L, Guilherme Z
   * @description Render de corpo da tela
   * @copyright 05/2019
   */
  renderBody() {
    const filteredContratos = this.getFilteredContratos()

    return !this.props.loading ? (
      <>
        <div className="row">
          <div className="col-12 text-center mb-4">
            {filteredContratos.length === 0 ? (
              <div className="FeedBackEmoji">:)</div>
            ) : (
              "Selecione para gerar o boleto:"
            )}
          </div>
        </div>
        <div className="row">
          <div
            className="col-12"
            style={{ maxHeight: "54vh", overflowY: "auto" }}
          >
            {this.renderAllInstallments()}
          </div>
        </div>
        <div className="row">
          <div className="col">
            {filteredContratos.length !== 0 && (
              <div className="text-right mt-3 mb-3">
                <strong>
                  Subtotal <TextMoney value={this.calculaTotal()} />
                </strong>
              </div>
            )}
          </div>
        </div>
      </>
    ) : (
      <></>
    );
  }

  /**
   *  @author Antônio D. F.
   *  @param {object} numContrato - Objeto com todos os dados do contrato
   *  @description Calcula qual o numero maximo de parcelas de cada contrato
   *  @copyright 05/2019
   */
  getTotalInstallments(numContrato) {
    let { contratos } = this.props;
    let maxParcela = 0;

    contratos = contratos.filter(
      contrato => contrato.numeroContrato === numContrato
    );

    contratos.forEach(contrato => {
      maxParcela =
        contrato.seqParcela > maxParcela ? contrato.seqParcela : maxParcela;
    });
    return maxParcela;
  }

  getFilteredContratos(){
    return this.props.contratos
      .filter(contrato => {
        return typeof this.state.filters.month === "number"
          ? moment(contrato.vctoParcela, valueFormat).month() === this.state.filters.month
          : true;
      })
  }

  /**
   *  @author André Hoffmann
   *  @param {Array} contratos - Array preenchido de objetos do tipo contrato
   *  @description Cria um objeto, que organiza a próxima parcela a ser paga, por contrato
   *  @copyright 06/2020
   */
  getNextInstallments = (contratos) => { 
    const { selectedInstallments } = this.state;    

    //Seleciona a próxima parcela, para os contratos com parcelas selecionadas
    const nextFromSelected = {};
    selectedInstallments.forEach( sel => {
      const contratoIn = sel.numeroContrato in nextFromSelected;
      if( false === contratoIn )
        //Necessária mudança para casos em que o usuário pagou parcelas avançadas
        nextFromSelected[sel.numeroContrato] = Number(sel.seqParcela) + 1;
      
      if(Number(sel.seqParcela) >= nextFromSelected[sel.numeroContrato])
        //Necessária mudança para casos em que o usuário pagou parcelas avançadas
        nextFromSelected[sel.numeroContrato] = Number(sel.seqParcela) + 1;
    })

    const out = nextFromSelected;

    //Preenche com a primeira parcela dos contratos sem parcelas selecionadas
    contratos.forEach( ({ numeroContrato, seqParcela }) => {
      const inOut = numeroContrato in out;
      if( false === inOut )
        out[numeroContrato] = Number(seqParcela);
    });
    return out;
  }

  /**
   * @author Leonardo L, Guilherme Z
   * @description Render de parcela
   * @copyright 05/2019
   */
  renderAllInstallments() {
    const nextInstallments = this.getNextInstallments(this.props.contratos);
    const contratos = this.getFilteredContratos();

    const installments = contratos.map((installment, index) => {
        const checked = this.isInstallmentSelected(installment);

        const disabled = nextInstallments[installment.numeroContrato] < Number(installment.seqParcela);
        
        return (
          <div className="row no-gutters" key={index}>
            <div className="col-1">
              <CheckBox
                checked={checked}
                disabled={disabled}
                onChangeValue={value => this.check(value, installment)}
              />
            </div>
            <div
              className="col-11"
              style={{
                cursor: disabled? 'auto':'pointer'
              }}
              onClick={() => {
                if(disabled) return;
                this.check(!checked, installment)
              }}
            >
              <div className="row">
                <div
                  className={`col-8 ${installment.isDelayed && "text-danger"}`}
                >
                  <strong>Carnê {installment.numeroContrato}</strong>
                </div>
                <div className="col-4 text-right ">
                  <TextMoney
                    bold
                    className={`${installment.isDelayed && "text-danger"}`}
                    value={
                      parseFloat(installment.valorParcela) +
                      parseFloat(installment.valorEncargo)
                    }
                  />
                </div>
                <div className="col-6">
                  <small>
                    Parcela {installment.seqParcela} de{" "}
                    {this.getTotalInstallments(installment.numeroContrato)}
                  </small>
                </div>
                <div className="col-6 text-right">
                  <small>
                    {formatDate(installment.vctoParcela)}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-12">
              <hr />
            </div>
          </div>
        );
      });

    return installments.length ? (
      installments
    ) : (
      <div className="text-center">Nenhuma parcela encontrada.</div>
    );
  }

  /**
   * @author Leonardo L, Guilherme Z
   * @description Verificação de parcela checada para render
   * @copyright 05/2019
   */
  isInstallmentSelected(installment) {
    return !!this.state.selectedInstallments.find(
      selected => installment === selected
    );
  }

  /**
   * @author Leonardo L, Guilherme Z
   * @description Render do Footer
   * @copyright 05/2019
   */
  renderFooter() {
    return !this.props.loading ? (
      <div className="row">
        {this.props.error && (
          <div className="col-12 text-center">
            <span className="text-danger">{this.props.error}</span>
          </div>
        )}
        <div className="col-12 text-center">
          {this.getFilteredContratos().length !== 0 ? (
            <Button
              text="Gerar boleto"
              onClick={this.submit}
              disabled={this.state.selectedInstallments.length === 0}
            />
          ) : null}
        </div>
      </div>
    ) : (
      <></>
    );
  }

  /**
   * @author Leonardo L, Guilherme Z
   * @description Calculo do valor total de itens selecionados
   * @copyright 05/2019
   */
  calculaTotal = () => {
    return this.state.selectedInstallments
      .reduce(
        (value, { valorParcela, valorEncargo }) =>
          value + (Number(valorParcela) + Number(valorEncargo)),
        0
      )
      .toFixed(2);
  };

  /**
   * @author Guilherme Z, Henrique B
   * @description Renderização do Dialog de seleção de data
   * @copyright 06/2019
   */
  renderDateDialog = () => {
    const { options } = this.state;

    return (
      <Dialog ref={el => (this.dateDialog = el)}>
        <p className="text-center">
          Selecione uma data para vencimento do boleto
        </p>

        <Select
          value={this.state.vencimentoBoleto}
          placeholder="Selecione"
          labelKey="vctoParcela"
          label="vctoParcelaLabel"
          options={options}
          onChangeValue={vencimentoBoleto =>
            this.setState({ vencimentoBoleto })
          }
        />

        {this.props.error && (
          <p className="text-danger text-center">{this.props.error}</p>
        )}

        <Button
          text="Prosseguir"
          className="btn-block mt-3"
          disabled={!this.state.vencimentoBoleto}
          onClick={this.emitirBoleto}
        />
      </Dialog>
    );
  };

  render() {
    return (
      <>
        {this.renderDateDialog()}
        <FilterDialog
          onFilter={filters =>
            this.setState({ filters, selectedInstallments: [] })
          }
          ref={el => (this.filterDialog = el)}
        />

        <GridLayout
          alignContent="center"
          alignFooter="center"
          alignHeader="center"
          header={
            <LoggedHeader
              navFunction={() => navigate(this.props, Paths.HOME)}
              onClickOptions={() => this.filterDialog.open()}
              text=""
            />
          }
          content={this.renderBody()}
          footer={this.renderFooter()}
        />
      </>
    );
  }
}

/**
 *  @author Moises L, Guilherme Z
 *  @description Gerar diferença em dias de 2 datas
 *  @copyright 05/2019
 *  @param {string} initialDate default today
 *  @param {string} nextDate
 */
const getDateDiference = (nextDate, initialDate = new Date()) => {
  const now = moment(initialDate).utcOffset("-03:00");
  const duration = moment.duration(now.diff(nextDate));
  return duration.asDays();
};

const mapStateToProps = ({ clientInstallments, loading }, props) => {
  const { linhaDigitavel, installmentsData, error } = clientInstallments;
  let { contratos, taxaEmissaoBoleto } = installmentsData;

  if (linhaDigitavel && !error) {
    props.history.push(LoanPaths.INSTALLMENT_VALUE_CONFIRMATION);
  }

  contratos = contratos.map(contrato => {
    return getDateDiference(contrato.vctoParcela) > 0
      ? { ...contrato, isDelayed: true }
      : contrato;
  });

  return {
    loading,
    error,
    contratos,
    linhaDigitavel,
    taxaEmissaoBoleto
  };
};

export default connect(
  mapStateToProps,
  { handleFetch, emiteBoleto, reset }
)(Installments);
