import VMasker from "vanilla-masker";

export const formatMoney = (value, params) =>
  VMasker.toMoney(Number(value).toFixed(2), params);


/**
 * @author Anderson Oliveira, Leonardo Lucas dos Santos
 * @copyright 02/2019
 * @description Método de aplicação de máscara de CPF
*/
export const formatCPF = cpf => {
  cpf = cpf.replace(/[^\d]/g, "");
  cpf = cpf.substr(0, 11);
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1-$2");
  return cpf;
};

/**
 * @description Método de aplicação de máscara de CNPJ
 */
export const formatCNPJ = cpf => {
  cpf = cpf.replace(/[^\d]/g, "");
  cpf = cpf.substr(0, 18);
  cpf = cpf.replace(/(\d{2})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1/$2");
  cpf = cpf.replace(/(\d{4})(\d)/, "$1-$2");
  return cpf;
};

/**
   * @author Anderson Oliveira, Leonardo Lucas dos Santos
   * @copyright 02/2019
   * @description Método de validação de CPF válido
  */
 export function validateCPF(value) {
  let calc;
  let rest;
  let i;
  let CPF = value.replace(/[^\d]+/g, "");
  calc = 0;

  if(CPF.length !== 11)
    return false

  if (CPF % "11111111111" === 0)
    return false;

  for (i = 1; i <= 9; i++)
    calc = calc + parseInt(CPF.substring(i - 1, i)) * (11 - i);
  rest = (calc * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(CPF.substring(9, 10)))
    return false;

  calc = 0;
  for (i = 1; i <= 10; i++)
    calc = calc + parseInt(CPF.substring(i - 1, i)) * (12 - i);
  rest = (calc * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(CPF.substring(10, 11)))
    return false;

  return true;
};

const validaAlgarismos = (tamanho, numeros) => {
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
  }
  return soma;
}

const verificaDigito = (soma) => {
  return soma % 11 < 2 ? 0 : 11 - soma % 11;
}


/**
  * @description Método de validação de CNPJ válido
  */
export function validateCNPJ(value) {
    
  if(value == '') return false;

  const cnpj = value.replace(/[^\d]+/g,'');
   
  if (cnpj.length != 14) return false;

  if(cnpj % "11111111111111" === 0) return false;
       
  const digitos = cnpj.substring(12,14);

  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0,tamanho);    
  const validacaoAlgorismosPrimeiroDV = validaAlgarismos(tamanho, numeros);   
  const validaPrimeiroDV = verificaDigito(validacaoAlgorismosPrimeiroDV); 
  
  if (validaPrimeiroDV != digitos.charAt(0)) return false;
       
  tamanho++;
  numeros = cnpj.substring(0,tamanho);
  const validacaoAlgorismosSegundoDV = validaAlgarismos(tamanho, numeros)
  const validaSegundoDV = verificaDigito(validacaoAlgorismosSegundoDV);
  
  
  if (validaSegundoDV != digitos.charAt(1)) return false;
         
  return true;
}


/**
 * @author André Hoffmann
 * @description Modifica uma string de data do formato YYYY-MM-DD para o formato DD/MM/YYYY
 * @copyright 09/2020
 */

export const formatDate = date => {
  const [
      year,
      month,
      day
  ] = date.split("-");

  return `${day} / ${month} / ${year}`
}

export const formatName = (name = 'Cliente Lebes') => {
  const nameSplitted = name.toLowerCase().split(' ')[0];
  const normalizeName =
    nameSplitted.charAt(0).toUpperCase() +
    nameSplitted.substring(1, nameSplitted.length).toLowerCase();

  return normalizeName;
};
