import React, { Component } from "react";
import CpfInput from "Components/CpfInput/CpfInput";
import LoggedHeader from "Components/LoggedHeader";
import Button from "Components/Button";
import Text from "Components/Text/Text";

import "./Cpf.css";
import GridLayout from "Layouts/GridLayout";
import _ from "lodash";
import ServicesBFF from "Store/BFFWeb/BFFWeb";
import { connect } from "react-redux";
import { Paths as UserPaths } from "Routes/UserRoutes";
import { navigate } from "Helpers/Navigate";
import { unsetUser } from "../../Store/Ducks/user";
import initializeReactGA from "Services/ReactGA";

class Cpf extends Component {
  constructor(props) {
    super(props);
    if (props.cpf) {
      this.state.cpf = props.cpf;
    }
  }

  state = {
    cpf: "",
    link: "",
    validCPF: "OK",
    errorValidation: ""
  };

  componentDidMount = () => {
    initializeReactGA({
      pageName: "user/recovery",
      category: "HOME-LOGIN-CADASTRO",
      action: "Acesso_a_tela_para_cadastro_ou_login",
      label: "logar_redefinicao_de_senha"
    });
  };

  onClickBtnRecovery = async () => {
    const { cpf } = this.state;
    if (await this.BFFPasswordRecovery(cpf)) {
      initializeReactGA({
        pageName: "user/changedpassword",
        category: "HOME-LOGIN-CADASTRO",
        action: "Acesso_a_tela_para_cadastro_ou_login",
        label: "logar_redefinicao_de_senha"
      });
      navigate(this.props, UserPaths.USER_CHANGED_PASSWORD);
    } else {
      this.setState({
        errorValidation: this.mensagemErrorOps("Identificacao invalida.")
      });
    }
  };

  /**
   * TODO BFF
   */
  BFFPasswordRecovery = cpfRecover => {
    // Validação BFF
    let passwordRecoveryResponse = ServicesBFF.passwordRecovery({
      cpf: cpfRecover
    });
    return passwordRecoveryResponse;
  };

  onBlurCpfInput = cpf => {
    this.setState({ cpf: cpf, errorValidation: "" });
  };

  isCpfInputValid = () => {
    const { validCPF, errorValidation, cpf } = this.state;
    return validCPF === "OK" && errorValidation === "" && !_.isEmpty(cpf);
  };

  render() {
    const { cpf, validCPF } = this.state;

    const content = (
      <div className="Container">
        <div className="Message mt-4">
          <Text>
            Informe seu CPF para iniciarmos a recuperação da sua senha
          </Text>
        </div>
        <div className="form-group">
          <CpfInput
            className="form-control"
            text={cpf}
            defaultValue={this.props.cpf}
            onChangeValue={this.onBlurCpfInput}
            successValidation={!validCPF ? "OK" : validCPF}
            errorText={validCPF === "OK" ? "" : validCPF}
          />
        </div>
      </div>
    );

    const header = <LoggedHeader text="Recuperar senha" />;
    const footer = (
      <Button
        text="Próximo"
        disabled={!this.isCpfInputValid()}
        onClick={this.onClickBtnRecovery}
      />
    );

    return (
      <GridLayout
        alignContent="center"
        alignFooter="center"
        alignHeader="center"
        content={content}
        header={header}
        footer={footer}
      />
    );
  }
}

const mapStateToProps = store => ({
  cpf: store.user.cpf
});

export default connect(
  mapStateToProps,
  { unsetUser }
)(Cpf);
