import React from "react";
import Feedback from "Containers/FeedBack";
import { Paths } from "Routes";
import { navigate } from "Helpers/Navigate";

function PasswordRecoverySuccess(props) {
  const goToHome = () => {
    navigate(props, Paths.HOME);
  };
  return (
    <Feedback
      headerTitle="Recuperar Senha"
      summary="Enviamos um e-mail para redefinição de senha. Confira sua caixa de entrada!"
      emoji=":)"
      textButton="Ok"
      submit={goToHome}
    />
  );
}

export default PasswordRecoverySuccess;
