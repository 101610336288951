import React, { Component } from "react";
import PropTypes from "prop-types";
import "./CepInput.css";

/**
 * @author Márcio Geremia
 * @copyright 03/2019
 * @description Campo de CEP
 * @example   <CepInput
                value={cep}
                onBlur={cep => {
                  this.setState({ cep });
                }}
              />
 */
class CepInput extends Component {
  state = {
    value: "",
    errorText: ""
  };

  /**
   * @author Anderson Oliveira
   * @copyright 03/2019
   * @description Método de aplicação de máscara de CEP
   */
  formatCEP = cep => {
    cep = cep.replace(/[^\d]/g, "");
    cep = cep.substr(0, 8);
    cep = cep.replace(/(\d{5})(\d)/, "$1-$2");
    return cep;
  };

  /**
   * @author Márcio Geremia
   * @copyright 03/2019
   * @description Evento de atualização de estado do componente,
   * controlando a inserção de somente números, ao digitar o CEP
   */
  onChange = e => {
    let value = this.formatCEP(e.target.value);
    this.setState({ value }, () => {
      if (this.validate(value)) {
        this.props.onChange(value);
      }
    });
  };

  /**
   * @author Márcio Geremia
   * @copyright 03/2019
   * @description Função responsável por inicializar o state do componente a partir do valor fornecido pelo pai
   */
  componentDidMount() {
    if (this.props.value) this.setState({ value: this.props.value });
  }

  /**
   * @author Márcio Geremia
   * @copyright 03/2019
   * @description Evento de validação e atualização de estado ao digitar o CEP,
   * se válido chama propriedade vinculado ao Pai
   */
  onBlur = e => {
    let errorText = "OK";
    const value = e.target.value;
    if (value.length < 9) {
      errorText = "Ops! O CEP deve conter 8 digitos. :(";
    }
    this.setState({ errorText });
  };

  /**
   * @author Márcio Geremia
   * @copyright 03/2019
   * @description Evento de validação de CEP
   * @return Retorna verdadeiro se o CEP for válido e falso caso seja inválido
   */
  validate = value => {
    let errorText = "OK";
    if (!value && this.props.required) {
      errorText = "Ops! Campo obrigatório. :(";
    } else if (value.length < 9) {
      errorText = "";
    }
    this.setState({ errorText });
    return errorText === "OK";
  };

  render() {
    const {
      placeholder,
      className,
      required,
      name,
      errorText: errorTextProps
    } = this.props;
    const { value, errorText: errorTextState } = this.state;
    const errorText = errorTextProps || errorTextState;
    return (
      <div className="form-group">
        <input
          className={`form-control ${
            !errorText || errorText === "OK" ? "" : "is-invalid"
          } ${className ? className : ""}`}
          type="text"
          maxLength="9"
          required={required}
          placeholder={placeholder}
          value={value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          name={name}
        />
        <div className="invalid-feedback">
          {errorText === "OK" ? "" : errorText}
        </div>
      </div>
    );
  }
}

CepInput.defaultProps = {
  className: "",
  placeholder: "CEP",
  required: false
};

CepInput.propTypes = {
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  errorText: PropTypes.string
};

export default CepInput;
