import React, { Component } from "react";
import LoggedHeader from "../../Components/LoggedHeader/LoggedHeader";
import "./PrivacyPolicy.css";
import ServicesBFFCustomerLebes from "Store/BFFWeb/BFFWebCustomerLebes";
import { navigate } from "Helpers/Navigate";
import { Paths } from "Routes/MainPaths";

export default class PrivacyPolicy extends Component {
  state = {
    terms: ``
  };

  componentDidMount = () => {
    this.getTerms();
  };

  getTerms = async () => {
    await ServicesBFFCustomerLebes.searchTermsConditions()
      .then(({ data }) => {
        this.setState({ terms: data });
      })
      .catch(() => {
        navigate(this.props, Paths.FAIL);
      });
  };

  render() {
    const renderTerms = () => {
      return { __html: this.state.terms };
    };

    return (
      <div>
        <LoggedHeader hasNavigation={false} text="Termos de uso" />
        <div
          className="container TermContainer"
          dangerouslySetInnerHTML={renderTerms()}
        />
      </div>
    );
  }
}
