const CRYPTOGRAPHY_CONSTANTS = {
  ORIGIN_PWA: "ORIGIN_PWA",
  ORIGIN_BFF: "ORIGIN_BFF",
  ENCODING: "base64",
  OAEP_HASH: "sha1",
  CHUNK_SIZE: 180,
  HOMOLOGATION: {
    ACTIVE: false,
    ENCRYPT_KEY:
      "-----BEGIN PUBLIC KEY-----\n" +
      "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxyZMSs6wQhen4UKj1iuB\n" +
      "0bqFlak3C95Qx3zDzJWujU1MIL68ZM8ejPh0ePTGY+oOarkkDpDwC8ACk3xm78vv\n" +
      "69jIH2HbX0hERXIFMx1tkOwnnuWom6eZVXwwOGVS6r//E1e4pJrFfY7/6RlDKiet\n" +
      "ENerLhwzbFPonc5JgufZ8zhr5QRyDcN2CgPVxPrhLWWP6L//6C7DPhA/5gcF9KG6\n" +
      "7DP/98UCgGF3K7GE7D+mi+lPkLrW3KcJr10mKtruTEpfGfjmKcj7/XU7NPe9Pk5P\n" +
      "LoTglUgddVByWwYUnKqO4LvLukBSM+wCLjJ9hPLPt0kkW/rIId1zOmFHS5bbLDHP\n" +
      "WQIDAQAB\n" +
      "-----END PUBLIC KEY-----\n",
    DECRYPT_KEY: "WRKslAcnbULpKS2rxNKGeAywxedLmKRT",
  },
  PRODUCTION: {
    ACTIVE: true,
    ENCRYPT_KEY:
      "-----BEGIN PUBLIC KEY-----\n" +
      "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4DDiV+oECMWbCn3rhECH\n" +
      "Nq61EMNAJOQg6J74A6rQWHc2xwfL8c2iSRlGGyLtFHQpop6FI2TammTg5fT/eX6a\n" +
      "5AiEiX/Rv2zurdz34y+M23hx6M8iE+Gk3QhnJnpdNqVy7PmCLa8texr9spjhvabc\n" +
      "xO8Uh7AB+BCpJ0xsBpaZnk8kz+ihLSr+MCSL41cHanRVG62I4+bc/aq89rmhrv7G\n" +
      "EHtPSJP2R52ypHMtRBZ1Qym6Ad6Z1yRrLk2BUlw21gepEh2pYAHAyQAfkEYt+S2C\n" +
      "0jz6uXzkqY3NSb/Y56do/TNqd40nuQtVEjamA+F9KbJznzfGcZHf76u8RfRMagHb\n" +
      "TQIDAQAB\n" +
      "-----END PUBLIC KEY-----\n",
    DECRYPT_KEY: "9yQ0LzbCW9ZIHJS4StwMap2Q1jZK9DHl",
  },
};

export default CRYPTOGRAPHY_CONSTANTS;
