/* Action types */
import BFFWeb from "../../Store/BFFWeb/BFFWeb";

export const Types = {
  SET_DATA_BANK: "creditBankTransfer/SET_DATA_BANK"
};

/* Initial State */
const initialState = {
  id: "",
  bank: "",
  agency: "",
  branch: "",
  branchDigit: "",
  account: "",
  accountDigit: "",
  value: null,
  userBalance: 0,
  bankName: ""
};

/* Reducer */
export default function creditBankTransfer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case Types.SET_DATA_BANK:
      return { ...state, ...payload };
    default:
      return state;
  }
}

/* Action Creators*/
export function setDataBank(payload) {
  return {
    type: Types.SET_DATA_BANK,
    payload
  };
}

export function handleAddBankAccount(payload) {
  return (dispatch, getState) => {
    const { user } = getState();
    return BFFWeb.addBankAccount({
      ...payload,
      sessionToken: user.sessionToken
    }).then(res => {
      const { bankAccountId } = res.data;
      if (bankAccountId) {
        const dataBank = { ...payload, id: bankAccountId };
        dispatch(setDataBank(dataBank));
      }
    });
  };
}

export function getBalance() {
  return (dispatch, getState) => {
    return BFFWeb.getBalance().then(res => {
      const { userBalance } = res.data;

      if (userBalance)
        dispatch(setDataBank({ userBalance: Number(userBalance) }));
    });
  };
}
