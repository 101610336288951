import React, { Component } from "react";

import { connect } from "react-redux";
import LoggedHeader from "Components/LoggedHeader/";
import BFFWeb from "Store/BFFWeb/BFFWeb";
import { handleGetStores } from "Store/Ducks/stores";

import Map from "Components/Map/Map";
import SelectStore from "Components/SelectStore";
import Button from "Components/Button";
import { navigate } from "Helpers/Navigate";
import { Paths } from "Routes/TransferRoutes";
import initializeReactGA from "Services/ReactGA";
import "./TransferStore.css";

class TransferStore extends Component {
  state = {
    codigoLoja: "",
    errorMessage: "",
    latitude: -29.77955,
    longitude: -53.091131
  };

  componentDidMount() {
    this.props.handleGetStores();
    initializeReactGA({
      pageName: "transfer/store",
      category: "EMPRESTIMO-TRANSFERENCIA",
      action: "Acesso_a_tela_de_opcoes_de_transferencia",
      label: "Buscar_emprestimo_na_loja"
    });
  }

  changeCodigoLoja = (codigoLoja, latitude, longitude) => {
    this.setState({
      codigoLoja,
      latitude: Number(latitude),
      longitude: Number(longitude)
    });
  };

  mensagemErrorOps = mensagem => `Ops! ${mensagem} :(`;

  retirarEmprestimo = () => {
    const { user } = this.props;
    const { codigoLoja } = this.state;

    BFFWeb.retirarEmprestimoLoja(user.cpf, user.sessionToken, codigoLoja)
      .then(res => {
        const {
          data: {
            status: { message }
          }
        } = res;
        if (message === "OK") {
          navigate(this.props, {
            pathname: Paths.TRANSFER_STORE_SUCESS,
            state: { codigoLoja }
          });
        } else {
          this.setState({
            errorMessage: this.mensagemErrorOps(message)
          });
        }
      })
      .catch(error => {
        const {
          response: {
            data: { message }
          }
        } = error;

        if (message) {
          this.setState({
            errorMessage: this.mensagemErrorOps(message)
          });
        }
      });
  };

  getLocations = stores => stores.map(({ geolocalizacao }) => geolocalizacao);

  render() {
    const { codigoLoja, latitude, longitude, errorMessage } = this.state;
    const { stores } = this.props;

    return (
      <>
        <LoggedHeader text="Transferência" />
        <div className="store-content mx-auto">
          <div className="map">
            <Map
              markers={this.getLocations(stores)}
              latitude={latitude}
              longitude={longitude}
            />
          </div>
          <div className="store-select">
            <hr />
            <SelectStore
              stores={stores}
              selected={codigoLoja}
              onChangeValue={this.changeCodigoLoja}
            />
          </div>
        </div>

        <div className="footer-store">
          {errorMessage && (
            <div className="text-danger text-center">{errorMessage}</div>
          )}

          <Button
            onClick={this.retirarEmprestimo}
            disabled={!codigoLoja}
            text="Continuar"
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user, stores }) => ({ user, stores });

export default connect(
  mapStateToProps,
  { handleGetStores }
)(TransferStore);
