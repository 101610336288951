import React, { Component } from "react";
import LoggedHeader from "Components/LoggedHeader";
import LargeImageButton from "Components/LargeImageButton";
import GridLayout from "Layouts/GridLayout";
import { Paths as TransferRoutes } from "Routes/TransferRoutes";
// import { Paths as PhoneTransferRoutes } from "Routes/PhoneTransferRoutes";
import { connect } from "react-redux";
import TextMoney from "Components/TextMoney";
import { navigate } from "Helpers/Navigate";
import initializeReactGA from "Services/ReactGA";

class Transfer extends Component {
  componentDidMount() {
    initializeReactGA({
      pageName: "transfer/options",
      category: "EMPRESTIMO-TRANSFERENCIA",
      action: "Acesso_a_tela_de_opcoes_de_transferencia",
      label: "Transferir_o_emprestimo_pessoal"
    });
  }

  renderHeader = () => <LoggedHeader text="Transferência" />;

  render() {
    return (
      <GridLayout
        alignContent="center"
        alignFooter="center"
        alignHeader="center"
        content={this.renderContent()}
        header={this.renderHeader()}
      />
    );
  }

  renderContent = () => (
    <div className="form-group">
      <div className="col-12 mb-4">
        Prontinho, você já tem{" "}
        <b>
          <TextMoney value={this.props.userBalance} />
        </b>{" "}
        na sua carteira Lebes, o que você deseja fazer com esse saldo?
      </div>
      {this.renderButtons()}
    </div>
  );

  renderButtons() {
    return (
      <>
        <LargeImageButton
          name="BankTransferButton"
          icon="FaUniversity"
          text="Para a minha conta bancária"
          disabled={false}
          onClick={() => navigate(this.props, TransferRoutes.BANK_TRANSFER)}
        />
        <LargeImageButton
          name="MoneyTransferButton"
          icon="FaMoneyBillWave"
          text="Retirar em uma loja física"
          disabled={false}
          onClick={() => navigate(this.props, TransferRoutes.STORE_TRANSFER)}
        />
        {/* <LargeImageButton
          name="CardTransferButton"
          icon="FaUserFriends"
          text="Para outro cartão Lebes"
          disabled={false}
          onClick={()=> navigate(this.props, PhoneTransferRoutes.TRANSFER_NUMBER )}
        /> */}
      </>
    );
  }
}

const mapStateToProps = ({ creditBankTransfer }) => ({
  userBalance: creditBankTransfer.userBalance / 100
});

export default connect(mapStateToProps)(Transfer);
