export const Paths = {
  HOME: "/",
  FAIL: "/fail",
  USER: "/user",
  LOAN_REQUEST: "/loanrequest",
  CREDIT: "/credit",
  TRANSFER: "/transfer",
  LOAN: "/loan",
  PASSWORD_RESET: "/redefinirsenha",
  IDENTIFY: "/identify"
};
