import BFFWebCustomerLebes from "../BFFWeb/BFFWebCustomerLebes";

/* Action types */
export const Types = {
  SET_CONDITIONS: "conditions/SET",
  UNSET_CONDITIONS: "conditions/UNSET"
};
/* Action types */
const initialState = [];

/* Reducer */
export default function conditions(state = initialState, { type, payload }) {
  switch (type) {
    case Types.SET_CONDITIONS:
      return payload || initialState;
    case Types.UNSET_CONDITIONS:
      return initialState;
    default:
      return state;
  }
}
/* Reducer */

/* Action Creators start */
function setConditions(payload) {
  return { type: Types.SET_CONDITIONS, payload };
}
/* Action Creators start */

/* Thunk Action Creators start */
export function handleGetConditions() {
  return (dispatch, getState) => {
    const { creditRequest, user } = getState();

    const { valorFinanciado, insurance, firstInstallmentDate } = creditRequest;
    const { cpf } = user;

    BFFWebCustomerLebes.getParcelas(
      cpf,
      valorFinanciado,
      firstInstallmentDate,
      +insurance + ""
    ).then(res => {
      const { condicoes } = res.data;
      dispatch(setConditions(condicoes));
    });
  };
}
/* Thunk Action Creators start */
