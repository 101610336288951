import { Paths as RoutePaths } from "./MainPaths";

const { TRANSFER } = RoutePaths;

export const Paths = {
  /* Seleção do tipo de transferencia -> Transfer.js */
  TRANSFER_OPTIONS: TRANSFER + "/options",
  /* UI-027 - Cadastro de dados bancarios para transferencia -> BankTransfer.js */
  BANK_TRANSFER: TRANSFER + "/bank",
  /* Seleção do tipo de transferencia -> TransferDataConfirm.js */
  TRANSFER_DATA_CONFIRM: TRANSFER + "/dataconfirm",
  /* Sucesso na transferencia -> TransferSuccess.js */
  TRANSFER_SUCCESS: TRANSFER + "/success",
  /* Fluxo da UI-40 até UI-043 */
  TRANSFER_PHONE: TRANSFER + "/phone",
  /* Falha ao tentar entrar no fluxo de transferencia */
  TRANSFER_REQUEST_FAIL: TRANSFER + "/transferfail",
  /* UI-029 - Sucesso na transferencia por conta bancaria -> TransferBankSuccess.js */
  TRANSFER_BANK_SUCCESS: TRANSFER + "/transferbanksuccess",
  /* UI-046 - Apresenta tela de erro -> TransferFail.js */
  TRANSFER_FAIL: TRANSFER + "/fail",
  /* UI-025 - Retirada em loja -> TransferStore.js */
  STORE_TRANSFER: TRANSFER + "/store",
  /* UI-026 - Sucesso na retirada em loja -> TransferStoreSuccess.js */
  TRANSFER_STORE_SUCESS: TRANSFER + "/transferstoresuccess"
};
