import React, { Component } from "react";

import PhoneInput from "Components/PhoneInput/PhoneInput";
import EmailInput from "Components/EmailInput/EmailInput";
import DateInput from "Components/DateInput/DateInput";
import Radio from "Components/Radio";
import Button from "Components/Button/Button";
import TextInput from "Components/TextInput/TextInput";
import ServicesBFF from "Store/BFFWeb/BFFWeb";
import GridLayout from "Layouts/GridLayout";
import LoggedHeader from "Components/LoggedHeader/LoggedHeader";

import { registerInitialData } from "Store/Ducks/user";
import { connect } from "react-redux";
import { Paths } from "Routes/UserRoutes";
import moment from "moment";
import { navigate } from "Helpers/Navigate";

import initializeReactGA from "Services/ReactGA";

class NewUser extends Component {
  state = {
    fullName: "",
    phoneNumber: "",
    emailAddress: "",
    birthdate: "",
    termAcceptance: false,
    message: "OK"
  };

  componentDidMount() {
    initializeReactGA({
      pageName: "user/new",
      category: "HOME-LOGIN-CADASTRO",
      action: "Acesso_a_tela_para_cadastro_ou_login",
      label: "cadastrar_Dados_Pessoais"
    });
  }

  formatDate = date => {
    if (date && date.includes("/")) {
      let newDate = date.split("/");
      return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
    } else return date;
  };

  submit = () => {
    const { emailAddress, phoneNumber, fullName, birthdate } = this.state;
    const { cpf } = this.props.user;

    let initialData = {
      fullName,
      cpf,
      birthdate: this.formatDate(birthdate),
      emailAddress,
      phoneNumber: phoneNumber,
      sendSms: true,
      sendEmail: false
    };

    ServicesBFF.startCustomerCreation(initialData)
      .then(res => {
        const { status } = res.data;

        const { message } = status;
        this.setState({ message });
        if (
          res.data &&
          res.data.tokenData &&
          res.data.tokenData.creationToken
        ) {
          initialData.creationToken = res.data.tokenData.creationToken;
        }
        if (message === "OK") {
          this.props.registerInitialData(initialData);

          navigate(this.props, Paths.USER_CODE_CONFIRM);
        } else {
          console.warn(message);
        }
      })
      .catch(res => {
        this.setState({
          message: "Ops! Ocorreu algum problema, por favor tente novamente."
        });
      });
  };

  static getDerivedStateFromProps(props, state) {
    const { user } = props;

    return {
      fullName: state.fullName || user.fullName,
      phoneNumber: state.phoneNumber || user.phoneNumber,
      emailAddress: state.emailAddress || user.emailAddress,
      birthdate: state.birthdate || user.birthdate
    };
  }

  render() {
    const {
      fullName,
      phoneNumber,
      emailAddress,
      birthdate,
      message,
      termAcceptance
    } = this.state;

    const { user } = this.props;

    const formValid =
      fullName && phoneNumber && emailAddress && birthdate && termAcceptance;

    const content = (
      <div className="form-group">
        <div className="col-8 m-auto text-center">
          <p>Para sua conta ser criada, digite seus dados pessoais:</p>
        </div>
        <div className="form-row">
          <div className="col">
            <TextInput
              placeholder="Nome Completo"
              name="inputName"
              value={fullName}
              minLength={3}
              disabled={!!user.fullName}
              onChangeValue={fullName => this.setState({ fullName })}
            />
          </div>
          <div className="col">
            <PhoneInput
              name="inputPhone"
              phone={user.phoneNumber}
              required={true}
              disabled={!!user.phoneNumber}
              onChange={phoneNumber => this.setState({ phoneNumber })}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col">
            <EmailInput
              name="inputEmail"
              value={user.emailAddress}
              required={true}
              disabled={!!user.emailAddress}
              onChange={emailAddress => this.setState({ emailAddress })}
            />
          </div>
          <div className="col">
            <DateInput
              name="inputDate"
              date={user.birthdate}
              disabled={!!user.birthdate}
              required={true}
              onChange={birthdate => this.setState({ birthdate })}
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <Radio
            name="radioAccept"
            id="termAcceptance"
            checked={termAcceptance}
            value={termAcceptance}
            onChangeValue={() => this.setState({ termAcceptance: true })}
          >
            <div>
              Li e aceito os{" "}
              <a
                href="/user/privacypolicy"
                rel="noopener noreferrer"
                target="blank"
              >
                <u>Termos e condições e políticas de privacidade.</u>
              </a>
            </div>
          </Radio>
        </div>
      </div>
    );

    const footer = (
      <div className="row">
        <div className="col-12 text-center">
          <Button
            disabled={!formValid}
            onClick={this.submit}
            text="Próximo"
            name="newUser"
          />
        </div>
        <div className="col-12 text-center text-danger">
          {message !== "OK" && message}
        </div>
      </div>
    );

    const header = <LoggedHeader text="Cadastro Lebes" hasNavigation={false} />;

    return (
      <GridLayout
        alignContent="center"
        alignFooter="center"
        alignHeader="center"
        content={content}
        footer={footer}
        header={header}
      />
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user: {
      cpf: user.cpf,
      fullName: user.fullName || "",
      phoneNumber: user.phoneNumber ? user.phoneNumber.substring(2) : "",
      emailAddress: user.emailAddress || "",
      birthdate: user.birthdate
        ? moment(user.birthdate).format("DD/MM/YYYY")
        : ""
    }
  };
};

export default connect(
  mapStateToProps,
  { registerInitialData }
)(NewUser);
