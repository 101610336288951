import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import VMasker from "vanilla-masker";
import "./MoneyInput.css";
/**
 * @author Leonardo Lucas dos Santos
 * @example
 *       <MoneyInput
 *           // value={23.444}
 *           onBlur={(string, value) => console.log(string, value)}
 *           required={true}
 *         />
 */
const MoneyInput = props => {
  const [value, setValue] = useState(0);
  const [errorText, setErrorText] = useState("");

  /**
   * @author Giuseppe M Pereira
   * @description Método useEffect que seta o valor recebido
   * por props, para o state @var value e aplica mascara.
   * Listener para @param prop.value e @param prop.errorText.
   * @param {e} none
   */
  useEffect(() => {
    let { value, errorText } = props;
    value = Number(value) || "";
    let e = {
      target: {
        value: value ? value.toFixed(2) : ""
      }
    };
    onChange(e);
    setErrorText(errorText);
  }, [props.errorText]);

  /**
   * @author Anderson Oliveira
   * @description Executa validações internas e dá prioridade à mensagem de erro externa.
   */
  const validate = value => {
    const { required } = props;
    let errorText = "";

    if (!value && required) errorText = `Ops! Valor deve ser preenchido :(`;

    setErrorText(errorText);
    return errorText ? false : true;
  };

  /**
   * @author Giuseppe M Pereira
   * @description Método para resolver as classes de estilo
   * do input.
   * @param {e} none
   */
  const getClassNames = () => {
    const { className } = props;
    return [
      "form-control",
      className,
      !errorText || errorText === "OK" ? "" : "is-invalid"
    ].join(" ");
  };

  const onBlur = e => {
    onChange(e);
  };

  /**
   * @author Leonardo Lucas dos Santos
   * @description Método para fazer onChange do state, se o valor
   * for valido, ele chama o parent.
   * @param {e} event
   */
  const onChange = e => {
    const { onChangeValue } = props;

    let value = VMasker.toMoney(e.target.value, {
      // Decimal precision -> "90"
      // precision: 2,
      // Decimal separator -> ",90"
      // separator: ",",
      // Number delimiter -> "12.345.678"
      // delimiter: ".",
      // Money unit -> "R$ 12.345.678,90"
      unit: "R$"
      // Money unit -> "12.345.678,90 R$"
      // suffixUnit: "R$",
      // Force type only number instead decimal,
      // masking decimals with ",00"
      // Zero cents -> "R$ 1.234.567.890,00"
      // zeroCents: true
    });

    let numberValue = Number(
      value
        .split(".")
        .join("")
        .replace(",", ".")
        .replace("R$", "")
    );

    if (numberValue === 0) value = "";

    validate(value);
    setValue(value);

    if (onChangeValue) onChangeValue(numberValue);
  };

  const { maxLength, required, id, placeholder, name } = props;

  /**
   * @author Leonardo Lucas dos Santos
   * @description Método para fazer render do inputPassword
   * @param {e} none
   */
  return (
    <div>
      <input
        id={id}
        className={getClassNames()}
        type="text"
        maxLength={maxLength}
        required={required}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
      />
      <div className="invalid-feedback">
        {errorText === "OK" ? "" : errorText}
      </div>
    </div>
  );
};

MoneyInput.defaultProps = {
  required: false,
  className: "",
  value: 0
};

MoneyInput.propTypes = {
  // Valor default do componente para inicializar o input.
  value: PropTypes.number,
  // Classname anexada ao className do <input>.
  className: PropTypes.string,
  // Tamanho max.
  maxLength: PropTypes.number,
  // Function executada para retornar o valor valido.
  onChangeValue: PropTypes.func.isRequired,
  // Bool para prop required do <input>
  required: PropTypes.bool,
  // Bool para prop required do <input>
  id: PropTypes.string,
  // Texto apresentado pre digitacao
  placeholder: PropTypes.string
};

export default MoneyInput;
