import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import _ from "lodash";
import {
  setUserIdentification,
  userDataLebes,
  lazyLogin,
  unsetUser,
} from "../../Store/Ducks/user";
import { store } from "../../Store";
import ServicesBFF from "Store/BFFWeb/BFFWeb";
import { received } from "../../Store/Ducks/clientInstallments";
import Button from "Components/Button/Button";
import IdentifierInput from "Components/IdentifierInput/IdentifierInput";
import logo from "../../Components/Headerlogo/lebes-logo.png";
import logo_for_modal from "images/logo_frame.png";
import GridLayout from "Layouts/GridLayout/GridLayout";
import { Paths as LoanPaths } from "Routes/LoanRoutes";
import { navigate } from "Helpers/Navigate";
import { Paths } from "Routes";

import "./Identify.css";

function Identify(props) {
  const [cpf, setCpf] = useState("");
  const [loginToken, setLoginToken] = useState("");
  const [challenge, setChallenge] = useState("");
  const [invalidToken, setInvalidToken] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const [finalNumber, setFinalNumber] = useState("");
  const [resendCooldownActive, setResendCooldownActive] = useState(false);
  const [timerCount, setTimer] = useState(60);

  const APIWhatsApp = `https://api.whatsapp.com/send/?phone=5551997600832&text=${`Olá. Meu CPF é o ${cpf}. Não tenho acesso ao número ${finalNumber} para o envio do Token. Poderiam me ajudar?`}&app_absent=0`;
  const recaptchaRef = useRef(null);

  useEffect(() => {
    if (resendCooldownActive) {
      let interval = setInterval(() => {
        setTimer((lastTimerCount) => {
          lastTimerCount <= 1 && clearInterval(interval);
          return lastTimerCount - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [resendCooldownActive]);

  useEffect(() => {
    if (timerCount === 0 || !cpf > 0) {
      setTimer(60);
      setResendCooldownActive(false);
    }
  }, [timerCount, cpf]);

  useEffect(() => {
    challenge.length < 6 && setInvalidToken(false);
  }, [challenge]);

  useEffect(() => {
    !isShown && setChallenge("");
  }, [isShown]);

  async function handleTokenSubmission() {
    !isShown && finalNumber && openModal();
    if (timerCount === 60) {
      store.dispatch({ type: "loading/start" });

      try {
        const response = await ServicesBFF.receiveToken(cpf);
        const { maskedPhone, loginToken } = response.data;

        if (loginToken) {
          !isShown && openModal();
          setResendCooldownActive(true);
          setFinalNumber(maskedPhone);
          setLoginToken(loginToken);
        } else {
          consultServiceIntallments();
        }
      } catch (err) {
        setErrorText("Por favor, tente novamente mais tarde.");
        closeModal();
      }

      store.dispatch({ type: "loading/stop" });
    }
  }

  async function consultServiceIntallments() {
    try {
      store.dispatch({ type: "loading/start" });
      const response = await ServicesBFF.getClientInstallments(cpf, true);

      if (response.data && response.data.code === "38404") {
        store.dispatch({ type: "loading/stop" });
        setErrorText(response.data.message);
      } else if (response.data.cliente.situacaoCliente === "20") {
        store.dispatch(lazyLogin({ fullName: response.data.cliente.nome }));
        store.dispatch({ type: "loading/stop" });
        navigate(props, LoanPaths.NEGOTIATE);
      } else {
        store.dispatch(lazyLogin({ cpf: cpf }));
        store.dispatch({ type: "loading/stop" });
        store.dispatch(received(response.data));
        navigate(props, LoanPaths.EASY_INSTALLMENTS);
      }
    } catch (error) {
      store.dispatch({ type: "loading/stop" });
      closeModal();
    }
  }

  async function handleChallengeToken() {
    try {
      const response = await ServicesBFF.checkValidToken(loginToken, challenge);
      const { message } = response.data.status;

      if (message === "Authorized") {
        consultServiceIntallments();
        setInvalidToken(false);
        setResendCooldownActive(true);
        setErrorText("");
      } else {
        setInvalidToken(true);
      }
    } catch (err) {
      if (err.message === "Request failed with status code 401") {
        return setInvalidToken(true);
      }
      setErrorText("Por favor, tente novamente mais tarde.");
      closeModal();
    }
  }

  function handleOnChangeTokenValue(e) {
    setChallenge(e.target.value);
  }

  const onInputChange = (value) => {
    setCpf(value);
  };

  async function onChangeReCAPTCHA() {
    let recaptchaValue = await recaptchaRef.current.getValue();

    setCaptchaToken(recaptchaValue);
  }

  function closeModal() {
    setIsShown(false);
  }

  function openModal() {
    setIsShown(true);
  }

  return (
    <div className="LoginContainer">
      <div className="Layer">
        <GridLayout
          alignContent="center"
          alignFooter="center"
          alignHeader="center"
          content={
            <div className="mb-5">
              <div className="text-center">
                <NavLink to={Paths.HOME}>
                  <img src={logo} alt="logo" />
                </NavLink>
              </div>
              <div className="Message mb-4" style={{ fontSize: "24px" }}>
                Olá! Digite seu{" "}
                <strong style={{ fontSize: "24px" }}>CPF</strong> ou{" "}
                <strong style={{ fontSize: "24px" }}>CNPJ </strong> para poder
                acessar suas parcelas! :)
              </div>
              <div className="d-flex justify-content-center">
                <div
                  style={{ maxWidth: 350 }}
                  className="col p-0 justify-content-center w-100"
                >
                  <IdentifierInput
                    isBoxInput={false}
                    placeholder="Digite aqui seu CPF ou seu CNPJ"
                    required={true}
                    className="mt-4 align-self-center"
                    onChangeValue={onInputChange}
                    // defaultValue={props.cpf}
                    name="CpfInputLogin"
                    errorText={errorText}
                  />
                  <div className="d-flex justify-content-center p-2">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Lf9iucUAAAAAG9bQ7hbZ4xBkrfHMG63RDHfssPR"
                      onChange={onChangeReCAPTCHA}
                    />
                  </div>
                  {isShown ? (
                    <div className="ModalIsertToken">
                      <div className="row h-100">
                        <div className="m-auto ModalScreen p-2 pb-4">
                          <div
                            onClick={() => closeModal()}
                            className="CloseContainer"
                          >
                            <div className="CloseButton">&times;</div>
                          </div>
                          <div className="content-m">
                            <img
                              src={logo_for_modal}
                              alt="logo_modal"
                              style={{
                                objectFit: "contain",
                                alignSelf: "center",
                              }}
                            />
                            <p className="content-p-title">Autentificador</p>
                            <p className="content-p-subtitle">
                              Insira o código enviado por SMS
                              <br />
                              {finalNumber
                                ? "para " + finalNumber
                                : "para seu número cadastrado"}
                              .
                            </p>
                            <input
                              className={"content-input"}
                              value={challenge}
                              onChange={(e) => handleOnChangeTokenValue(e)}
                              placeholder={"Token"}
                              type="text"
                              maxLength="6"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {invalidToken ? (
                              <p className="content-p-invalid-token">
                                Token inválido
                              </p>
                            ) : (
                              <p></p>
                            )}
                            <Button
                              text="Enviar"
                              disabled={challenge.length < 6}
                              onClick={() => handleChallengeToken()}
                            />
                            <p
                              className={
                                timerCount === 60
                                  ? "content-p-resend"
                                  : "content-p-resend-disable"
                              }
                              onClick={() =>
                                timerCount === 60
                                  ? handleTokenSubmission()
                                  : null
                              }
                            >
                              Reenviar o código
                              <br />
                              {`00:` +
                                String(
                                  timerCount < 10
                                    ? `0` + timerCount
                                    : timerCount
                                )}
                            </p>
                            <a
                              className="content-a-footer"
                              href={APIWhatsApp}
                              target="blank"
                            >
                              Não tem acesso a este número? Clique aqui
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          }
          footer={
            <Button
              text="Próximo"
              name="LoginProximo"
              onClick={() => consultServiceIntallments()}
              disabled={cpf > 0 && captchaToken ? false : true}
            />
          }
        />
      </div>
    </div>
  );
}

const mapStateToProps = (store) => ({
  cpf: store.user.cpf,
});

export default connect(mapStateToProps, {
  setUserIdentification,
  userDataLebes,
  unsetUser,
})(Identify);
