import React, { Component } from "react";
import LoggedHeader from "../../Components/LoggedHeader/LoggedHeader";
import Button from "Components/Button";
import Label from "Components/Label/Label";
import TextInput from "Components/TextInput";
import TextStep from "Components/TextStep";
import PhoneInput from "Components/PhoneInput";
import "./CompleteRegisterThirdStep.css";
import GridLayout from "Layouts/GridLayout";
import { connect } from "react-redux";
import {
  completeRegisterThirdStep,
  setUserIdentification,
  eraseImageBase64
} from "Store/Ducks/user";
import { bindActionCreators } from "redux";
import { Paths } from "Routes/LoanRequestRoutes";
import ServicesBFF from "Store/BFFWeb/BFFWeb";
import { navigate } from "Helpers/Navigate";

import initializeReactGA from "Services/ReactGA";

import { store } from "../../Store";

class CompleteRegisterThirdStep extends Component {
  state = {
    firstNameRefer: this.props.user.firstNameRefer,
    firstPhoneRefer: this.props.user.firstPhoneRefer,
    secondNameRefer: this.props.user.secondNameRefer,
    secondPhoneRefer: this.props.user.secondPhoneRefer,
    errorText: ""
  };

  isFormStateValid = () => {
    const { firstNameRefer, firstPhoneRefer } = this.state;
    let validateInfo = firstNameRefer && firstPhoneRefer;
    return validateInfo;
  };

  reduxStates = () => {
    let info = {
      firstNameRefer: this.state.firstNameRefer,
      firstPhoneRefer: this.state.firstPhoneRefer,
      secondNameRefer: this.state.secondNameRefer,
      secondPhoneRefer: this.state.secondPhoneRefer
    };
    return info;
  };

  componentDidMount = () => {
    initializeReactGA({
      pageName: "loanrequest/completeregisterthirdstep",
      category: "CADASTRO_COMPLETAR_CADASTRO",
      action: "Acesso_a_tela_de_cadastro",
      label: "Completar_cadastro"
    });
  };

  onPress = async () => {
    await this.props.completeRegisterThirdStep(this.reduxStates());

    if (this.isFormStateValid()) {
      this.insertOrUpdateCustomerLebes()
        .then(async res => {
          this.props.eraseImageBase64();
          const { code, status } = res.data;

          if (code === "37200") {
            await this.updateUserStatus();
            initializeReactGA({
              pageName: "loanrequest/completeregistersuccess",
              category: "CADASTRO_COMPLETAR_CADASTRO",
              action: "Acesso_a_tela_de_cadastro",
              label: "Completar_cadastro"
            });
            navigate(this.props, Paths.LR_COMP_REG_SUCCESS);
          } else if (status) {
            const { message } = status;
            this.setState({ errorText: message });
          }
        })
        .catch(res => {
          this.setState({
            errorText: "Ops! Ocorreu algum problema, por favor tente novamente."
          });
        });
    }
  };

  /**
   * @author Anderson O.
   * @copyright 05/2019
   * @returns response do BFF
   * @description Monta payload e envia requisição, para inserir ou editar dados cadastrais do usuário, no barramento da Lebes
   */
  isFormStateValid = () => {
    const { firstNameRefer, firstPhoneRefer } = this.state;

    let validateInfo = firstNameRefer && firstPhoneRefer;

    return validateInfo;
  };

  insertOrUpdateCustomerLebes = () => {
    const {
      fullName,
      cpf,
      birthdate,
      motherName,
      rg,
      emailAddress,
      phoneNumber,
      state,
      city,
      address,
      addressNumber,
      cep,
      addressComplement,
      job,
      companyWork,
      money,
      gender,
      lbs,
      imageBase64
    } = this.props.user;

    const {
      firstNameRefer,
      secondNameRefer,
      firstPhoneRefer,
      secondPhoneRefer
    } = this.state;

    let enderecoResidencial = {
      ufCod: state,
      cidade: city,
      rua: address,
      numero: addressNumber,
      complemento: addressComplement,
      cep: cep.replace("-", "")
    };

    let telefoneList = [
      {
        ddd: "",
        telefone:
          phoneNumber.slice(0, 2) === "55" ? phoneNumber.slice(2) : phoneNumber,
        ramal: "",
        descricao: "CELULAR"
      }
    ];

    let emprego = {
      profissao: job,
      renda: money,
      empresa: {
        razaoSocial: "",
        nomeFantasia: companyWork
      }
    };

    let referencias = [];
    if (firstNameRefer && firstPhoneRefer) {
      referencias.push({
        refNome: firstNameRefer,
        refParentesco: "",
        refTelefoneList: [
          {
            ddd: "",
            telefone:
              firstPhoneRefer.slice(0, 2) === "55"
                ? firstPhoneRefer.slice(2)
                : firstPhoneRefer,
            ramal: "",
            descricao: "CELULAR"
          }
        ]
      });
    }

    if (secondNameRefer && secondPhoneRefer) {
      referencias.push({
        refNome: secondNameRefer,
        refParentesco: "",
        refTelefoneList: [
          {
            ddd: "",
            telefone:
              secondPhoneRefer.slice(0, 2) === "55"
                ? secondPhoneRefer.slice(2)
                : secondPhoneRefer,
            ramal: "",
            descricao: "CELULAR"
          }
        ]
      });
    }

    let cliente = {
      codigoCliente: "",
      nome: fullName,
      cpf: cpf,
      sexo: gender,
      dataNascimento: birthdate,
      nomeMae: motherName,
      identidade: rg,
      email: emailAddress,
      telefoneList: telefoneList,
      enderecoResidencial: enderecoResidencial,
      emprego: emprego,
      referencias: referencias
    };

    let payload = {
      cliente,
      imagem: imageBase64
    };

    if (!lbs) return ServicesBFF.insertCustomerLebes(payload);
    else return ServicesBFF.updateCustomerLebes(payload);
  };

  /**
   * @author Anderson O.
   * @copyright 05/2019
   * @description Consulta status do cadastro do usuario e atualiza o redux
   */
  updateUserStatus = async () => {
    try {
      store.dispatch({ type: "loading/start" });
      const response = await ServicesBFF.getCustomerStats(this.props.user.cpf);

      const { status } = response.data;

      if (status) this.props.setUserIdentification({ ...status });
      store.dispatch({ type: "loading/stop" });
    } catch (error) {
      store.dispatch({ type: "loading/stop" });
    }
  };

  onChangeFirstNameRefer = firstNameRefer => {
    this.setState({ firstNameRefer });
  };

  onChangeSecondNameRefer = secondNameRefer => {
    this.setState({ secondNameRefer });
  };

  onChangeFirstPhoneRefer = firstPhoneRefer => {
    this.setState({ firstPhoneRefer });
  };

  onChangeSecondPhoneRefer = secondPhoneRefer => {
    this.setState({ secondPhoneRefer });
  };

  render() {
    const header = <LoggedHeader text="Completar cadastro" />;
    const footer = (
      <Button
        text="Próximo"
        disabled={!this.isFormStateValid()}
        onClick={this.onPress}
      />
    );

    const content = (
      <div className="Login">
        <TextStep current={3} total={3} />
        <div className="form-group">
          <Label styleCss="TextHeader" text="Referências pessoais" />
        </div>
        <div className="form-group">
          <div className="form-row">
            <div className="mr-3">1.</div>
            <div className="col">
              <TextInput
                value={this.state.firstNameRefer}
                name="firstNameRefer"
                placeholder="Nome Completo"
                onChangeValue={this.onChangeFirstNameRefer}
                minLength={3}
              />
            </div>
            <div className="col">
              <PhoneInput
                name="firstPhoneRefer"
                placeholder="Telefone"
                required={true}
                onChange={this.onChangeFirstPhoneRefer}
              />
            </div>
          </div>
        </div>
        <div className="form-group" />
        <div className="form-row">
          <div className="mr-3">2.</div>
          <div className="col">
            <TextInput
              value={this.state.secondNameRefer}
              name="secondNameRefer"
              placeholder="Nome Completo (opcional)"
              onChangeValue={this.onChangeSecondNameRefer}
            />
          </div>
          <div className="col">
            <PhoneInput
              name="secondPhoneRefer"
              placeholder="Telefone (opcional)"
              onChange={this.onChangeSecondPhoneRefer}
            />
          </div>
        </div>
        <div className="ErrorText">
          {this.state.errorText === "OK" ? "" : this.state.errorText}
        </div>
      </div>
    );

    return (
      <GridLayout
        alignContent="center"
        alignFooter="center"
        alignHeader="center"
        content={content}
        header={header}
        footer={footer}
      />
    );
  }
}

const mapDispathToProps = dispatch =>
  bindActionCreators(
    { completeRegisterThirdStep, setUserIdentification, eraseImageBase64 },
    dispatch
  );

const mapStateToProps = ({ user }) => ({ user });

export default connect(
  mapStateToProps,
  mapDispathToProps
)(CompleteRegisterThirdStep);
