import BFFWeb from "../BFFWeb/BFFWeb";
import { createSelector } from "reselect";

/* Action types */
export const Types = {
  SET_CREDIT_REQUEST: "credit/SET",
  UNSET_CREDIT_REQUEST: "credit/UNSET"
};

/* Initial State */
const initialState = {
  valorFinanciado: null,
  firstInstallmentDate: "",
  lastInstallmentDate: "",
  insurance: false,
  idOperacao: "",
  condicao: {
    valorTfc: "",
    taxaCetAno: "",
    qtdParcelas: "",
    parcelas: "",
    primeiroVencimento: ""
  }
};

/* Reducer */
export default function creditRequest(state = initialState, { type, payload }) {
  switch (type) {
    case Types.SET_CREDIT_REQUEST:
      return { ...state, ...payload };
    case Types.UNSET_CREDIT_REQUEST:
      return { ...initialState, ...payload };
    default:
      return state;
  }
}

/* Action Creators */
export function setCreditRequest(payload) {
  return {
    type: Types.SET_CREDIT_REQUEST,
    payload
  };
}

export function unsetCreditResquest() {
  return { type: Types.UNSET_CREDIT_REQUEST };
}
/* Action Creators */

/* Thunk Action Creators */
export function handleSubmitContract(geolocation) {
  return (dispatch, getState) => {
    const { creditRequest, user } = getState();
    let payload = {
      ...creditRequest,
      cpfCliente: user.cpf,
      sessionToken: user.sessionToken,
      primeiroVencimento: creditRequest.firstInstallmentDate,
      valorFinanciado: creditRequest.valorFinanciado + ""
    };
    if (geolocation) payload.geolocation = geolocation;
    return BFFWeb.submitContract(payload).then(res => {
      const idOperacao = res.data.efetivaEmprestimoSaida.statusMotor.idOperacao;
      dispatch(setCreditRequest({ idOperacao }));
    });
  };
}
/* Thunk Action Creators */
function getFirstAttributeFromParcelas(parcelas, attribute) {
  return parcelas && parcelas.length ? parcelas[0][attribute] : "";
}

function getLasttAttributeFromParcelas(parcelas, attribute) {
  return parcelas && parcelas.length
    ? parcelas[parcelas.length - 1][attribute]
    : "";
}

const getParams = createSelector(
  creditRequest => creditRequest,
  ({
    valorFinanciado,
    condicao: {
      primeiroVencimento,
      parcelas,
      taxaCetAno,
      taxaCet,
      valorTfc,
      qtdParcelas,
      valorIof,
      taxaJuros,
      valorTotalPrazo
    }
  }) => ({
    primeiroVencimento: primeiroVencimento,
    vctoParcela: getFirstAttributeFromParcelas(parcelas, "vctoParcela"), //primeiroVencimento
    taxaCetAno: taxaCetAno,
    taxaCet: taxaCet,
    taxaJuros: taxaJuros,
    valorTfc: valorTfc,
    qtdParcelas: qtdParcelas, //quantidade de parcelas que ele selecionou
    valorParcela: getFirstAttributeFromParcelas(parcelas, "valorParcela"), //valor da percela
    valorIof: valorIof,
    valorVenda: valorTotalPrazo, //igual valor financiado
    valorFinanciado: valorFinanciado,
    valorTotalPrazo: valorTotalPrazo,
    dataFimVigencia: getLasttAttributeFromParcelas(parcelas, "vctoParcela")
  })
);
export { getParams };
