import React from "react";
import ImageButton from "Components/ImageButton";

import { Paths as CreditRequestPaths } from "Routes/CreditRequestRoutes";
import { Paths as LoanPaths } from "Routes/LoanRoutes";

import { withRouter } from "react-router";

import initializeReactGA from "Services/ReactGA";

const QuickAccess = ({ history, className }) => {
  const accessCreditRequest = () => {
    initializeReactGA({
      pageName: "/",
      category: "HOME-ACESSO_RAPIDO",
      action: "clique_no_botao_simular_emprestimo",
      label: "visualizar_simulacao_e_contratacao_de_emprestimo"
    });

    history.push(CreditRequestPaths.CREDIT_REQUEST);
  };

  const accessStore = () => {
    initializeReactGA({
      pageName: "/",
      category: "HOME-ACESSO_RAPIDO",
      action: "clique_no_botao_comprar_on-line",
      label: "Acessar_o_e-commerce"
    });

    window.open("https://www.lebes.com.br/");
  };

  const accessInstallments = () => {
    initializeReactGA({
      pageName: "/",
      category: "HOME-ACESSO_RAPIDO",
      action: "clique_no_botao_pagar_parcelas",
      label: "visualizar_e_copiar_barras_para_pagamento_de_parcelas"
    });

    history.push(LoanPaths.INSTALLMENTS);
  };

  return (
    <div className={className}>
      <div
        style={{ fontSize: "1.2em", color: "#4a4a4a" }}
        className="font-weight-bold mt-4 mb-3 ml-2"
      >
        Acesso rápido
      </div>
      <div className="quickAccess">
        <ImageButton
          onClick={accessCreditRequest}
          name="LoanImageButton"
          src="calculadora"
          alt="calculadora"
          text={
            <>
              Fazer
              <br />
              empréstimo
            </>
          }
        />
        <ImageButton
          onClick={accessStore}
          name="StoreAccessImageButton"
          src="localizacao"
          alt="localizacao"
          text={
            <>
              Comprar
              <br />
              online
            </>
          }
        />
        <ImageButton
          name="ExtractImageButton"
          onClick={accessInstallments}
          src="extrato"
          alt="extrato"
          text={
            <>
              Pagar
              <br />
              parcelas
            </>
          }
        />
      </div>
    </div>
  );
};

export default withRouter(QuickAccess);
