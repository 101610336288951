import React, { Component } from "react";
import "./PasswordReset.css";
import PasswordInput from "Components/PasswordInput";
import Button from "Components/Button";

import logo from "images/logo-lebes-header.png";
import Label from "Components/Label/Label";

import BFFWeb from "Store/BFFWeb/BFFWeb.js";

class PasswordReset extends Component {
  state = {
    newPassword: "",
    newPasswordConfirmation: "",
    error: "",
    invalidParams: false,
    redefined: false
  };

  params = {};

  submit = e => {
    e.preventDefault();

    const { newPassword } = this.state;
    const { emailAddress, token } = this.params;

    BFFWeb.completePasswordRecover({ newPassword, emailAddress, token })
      .then(({ status }) => {
        if (status.code === "15200") {
          this.setState({ redefined: true, error: "" });
        } else {
          this.setState({
            error: status.message
          });
        }
      })
      .catch(error => {
        this.setState({
          error: error.response.data.status.message
        });
      });
  };

  componentDidMount() {
    const { location } = this.props;

    const params = new URLSearchParams(location.search);
    const emailAddress = params.get("emailAddress");
    const token = params.get("token");
    const applicationName = params.get("applicationName");
    const platform = params.get("platform");

    if (!emailAddress || !token) {
      this.setState({ invalidParams: true });
    } else {
      this.setState({ invalidParams: false });
      this.params = {
        emailAddress,
        token,
        applicationName,
        platform
      };
    }
  }

  changeNewPassword = newPassword => {
    this.setState(({ newPasswordConfirmation }) => {
      return {
        error:
          newPasswordConfirmation !== newPassword &&
          newPasswordConfirmation.length === 6
            ? "As senhas não coincidem"
            : "",
        newPassword
      };
    });
  };

  changeNewPasswordConfirmation = newPasswordConfirmation => {
    this.setState(({ newPassword }) => {
      return {
        newPasswordConfirmation,
        error:
          newPasswordConfirmation !== newPassword
            ? "As senhas não coincidem"
            : ""
      };
    });
  };

  render() {
    const {
      newPassword,
      newPasswordConfirmation,
      error,
      redefined,
      invalidParams
    } = this.state;

    const form = (
      <form onSubmit={this.submit}>
        <Label text="Nova senha:" />
        <PasswordInput
          type="password"
          value={newPassword}
          maxLength={6}
          onChangeValue={this.changeNewPassword}
          successValidation={!error}
        />

        <Label text="Repita a nova senha:" />
        <PasswordInput
          type="password"
          value={newPasswordConfirmation}
          maxLength={6}
          onChangeValue={this.changeNewPasswordConfirmation}
          successValidation={!error}
        />
        <Button
          disabled={!newPassword || !newPasswordConfirmation || error}
          type="submit"
          text="Redefinir"
          className="btn-block"
        />
        <span className="text-danger text-center">{error}</span>
      </form>
    );

    const renderContent = () => {
      if (redefined) {
        return (
          <div className="alert alert-success">
            Senha redefinida com sucesso!
          </div>
        );
      }

      if (invalidParams) {
        return <div>Parâmetros inválidos</div>;
      }

      return form;
    };

    return (
      <>
        <div className="container pt-5">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <h3 className="mb-3">Redefinição de senha</h3>
              {renderContent()}
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="container text-center text-white">
            <div className="row">
              <div style={{ marginBottom: 55 }} className="col-12 text-center">
                <img src={logo} width="150px" alt="logo" />
              </div>
              <div className="col align-self-end">
                <a
                  className="text-white"
                  href="https://www.lebes.com.br/facilita-lebes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Central de Relacionamento
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PasswordReset;
