import React from "react";
import "./SelectStore.css";
import CheckBox from "Components/CheckBox/CheckBox";
import { FaMapMarkerAlt } from "react-icons/fa";
import { PropTypes } from "prop-types";
import TextPhone from "../TextPhone/TextPhone";

/**
 * @description Lista de seleção de lojas.
 * @author Guilherme Z
 * @example
 *  <SelectStore
 *       stores={stores}
 *       selected={this.state.store}
 *       onChangeValue={store => this.setState({store})}
 *     />
 */
function SelectStore({ stores, selected, onChangeValue, ...rest }) {
  const onChange = (storeCodigo, storeGeo) => {
    let value = "";
    if (storeCodigo !== selected) value = storeCodigo;
    onChangeValue(value, storeGeo.latitude, storeGeo.longitude);
  };

  return stores.map(store => {
    const { codigoLoja } = store;
    const { geolocalizacao } = store;

    return (
      <div {...rest} key={codigoLoja}>
        <div className="row">
          <div className="col-auto">
            <FaMapMarkerAlt
              name="BankTransferButton"
              icon="FaMapMarkerAlt"
              color="green"
            />
          </div>

          <div className="col">
            <strong>Lebes {store.endereco.cidade}</strong>
            <br />
            <small className="font-weight-bold">
              {store.endereco.rua}, {store.endereco.numero},{" "}
              {store.endereco.bairro}
            </small>
            <br />
            <small className="font-weight-bold">
              <TextPhone value={store.telefone.telefone} />
            </small>
          </div>

          <div className="col-auto align-self-center">
            <CheckBox
              checked={selected === codigoLoja}
              onChangeValue={() => onChange(codigoLoja, geolocalizacao)}
              style={{ marginLeft: 10 }}
            />
          </div>
        </div>

        <hr />
      </div>
    );
  });
}

SelectStore.propTypes = {
  stores: PropTypes.array.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  selected: PropTypes.string
};

export default SelectStore;
