import React from "react";
import "./LoggedHeader.css";
import PropTypes from "prop-types";
import { IoIosArrowBack } from "react-icons/io";
import iconFilter from "../../images/icon_filter.svg";
import { withRouter } from "react-router-dom";
import logo from "./../../images/logo-lebes-header.png";

/**
 *
 * @param {*} props
 */
const LoggedHeader = ({
  history,
  navFunction,
  hasNavigation,
  onClickOptions,
  text
}) => {
  return (
    <div className="LoggedHeaderContainer">
      {(hasNavigation && (
        <div
          onClick={() => history.goBack()}
          className="LoggedHeaderNavButton"
        >
          <IoIosArrowBack />
          <div>{text}</div>
        </div>
      )) || <div className="LoggedHeaderNavButton" />}
      <div className="LoggedHeaderText">
        <img
          src={logo}
          alt="logo"
          style={{ height: "95%", objectFit: "contain" }}
        />
      </div>

      {(onClickOptions && (
        <div onClick={onClickOptions} className="LoggedHeaderNavButton">
          <div style={{ paddingRight: 10, width: 110 }}>Filtrar parcelas</div>
          <img src={iconFilter} alt="opções" />
        </div>
      )) || <div className="LoggedHeaderNavButton" />}
    </div>
  );
};

LoggedHeader.propTypes = {
  // Texto exibido dentro do Header é obrigatório.
  text: PropTypes.any.isRequired,
  // Function disparada ao clicar no botão de navegar back, por padrão é props.history.goBack.
  navFunction: PropTypes.func,
  // Bool que indica se renderiza a seta de retornar
  hasNavigation: PropTypes.bool
};

LoggedHeader.defaultProps = {
  // Texto exibido dentro do Header, caso não enviado recebe Lebbes.
  text: "Lebes",
  // Bool que indica se renderiza a seta de retornar, default true
  hasNavigation: true
};

export default withRouter(LoggedHeader);
