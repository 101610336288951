import moment from "moment";
import { PropTypes } from "prop-types";

function DateText({ value, valueFormat, format }) {
  return value && moment(value, valueFormat).format(format);
}

DateText.propTypes = {
  value: PropTypes.string.isRequired
};

DateText.defaultProps = {
  valueFormat: "YYYY-MM-DD",
  format: "DD/MM/YYYY"
};

export default DateText;
