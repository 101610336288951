import React from "react";

import x1 from "../../images/logo/1x.png";
import x2 from "../../images/logo/2x.png";
import x3 from "../../images/logo/3x.png";
import x4 from "../../images/logo/4x.png";

import "./styles.css";

export default function Logo() {
  return (
    <div className={"container-logo"}>
      <img src={x4} alt="logox4" />
      <img src={x3} alt="logox3" />
      <img src={x2} alt="logox2" />
      <img src={x1} alt="logox1" />
    </div>
  );
}
