import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Button.css";

/**
 * @author Leonardo Lucas dos Santos
 * @description Componente de botão
 * @example
 *   <Button
 *      className="FeedBackButton"
 *      text={textButton}
 *      onClick={submit}
 *      />
 *
 */
class Button extends Component {
  static propTypes = {
    // Tipo de botão: Primary || Secondary
    color: PropTypes.string,
    // Texto que aparecera no botão
    text: PropTypes.string.isRequired,
    // Evento de click
    onClick: PropTypes.func,
    // classe de estilos para botão
    className: PropTypes.string
  };

  render() {
    const { className, text, onClick, color, disabled, name } = this.props;
    return (
      <button
        className={`btn Button ${className} ${color}`}
        title={text}
        disabled={disabled}
        onClick={onClick}
        name={name}
      >
        {text}
      </button>
    );
  }
}

Button.defaultProps = { color: "Primary", className: "", disabled: false };

export default Button;
