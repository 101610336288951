import React, { Component } from "react";
import PropTypes from "prop-types";

import months from "./months";
import Select from "Components/Select";
import Button from "Components/Button/Button";

export default class FilterDialog extends Component {
  state = {
    isShown: false,
    month: undefined
  };

  handleFilterChange = () => {
    const { month } = this.state;
    this.state.month !== 12
      ? this.props.onFilter({ month })
      : this.props.onFilter({ month: undefined });
    this.close();
  };

  changeMonth = month => {
    this.setState({ month: Number(month) });
  };

  close = () => this.setState({ isShown: false });

  open = () => this.setState({ isShown: true });

  render() {
    const { isShown } = this.state;

    return isShown ? (
      <div className="DialogBackground">
        <div className="row h-100">
          <div className="m-auto Dialog p-2 pb-4">
            <div onClick={this.close} className="CloseContainer">
              <div className="CloseButton">&times;</div>
            </div>
            <p className="text-center">Selecione o mês de vencimento</p>

            <Select
              value={String(this.state.month)}
              placeholder="Selecione um mês"
              labelKey="value"
              label="key"
              onChangeValue={this.changeMonth}
              options={Object.values(months)}
            />

            <Button
              text="Aplicar filtro"
              className="btn-block mt-3"
              disabled={typeof this.state.month !== "number"}
              onClick={this.handleFilterChange}
            />
          </div>
        </div>
      </div>
    ) : (
      <></>
    );
  }
}

FilterDialog.propTypes = {
  onFilter: PropTypes.func.isRequired
};
