import React, { Component } from "react";
import PropTypes from "prop-types";
import "./EmailInput.css";

class EmailInput extends Component {
  state = {
    valid: true,
    errorText: ""
  };

  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    /* Desabilita campo se valor pre carregado */
    disabled: PropTypes.bool,
  };

  validate = value => {
    if (this.props.required && !value) {
      this.setState({ errorText: "Ops! E-mail não foi preenchido :(" });
      return false;
    } else {
      const regex = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
      let validEmail = value ? regex.test(value) : true;
      if (validEmail) {
        this.setState({ errorText: "OK" });
      } else {
        this.setState({ errorText: "Ops! E-mail inválido! :(" });
      }
      return validEmail;
    }
  };

  onChange = e => {
    let value = e.target.value;
    let valid = this.validate(value);
    this.setState({ valid }, () => {
      if (!valid) {
        value = "";
      }
      this.props.onChange(value);
    });
  };

  onBlur = e => {
    this.onChange(e);
  }

  render() {
    const { value, className, placeholder, name, disabled } = this.props;
    const { errorText } = this.state;

    return (
      <div className="form-group">
        <input
          type="text"
          maxLength="60"
          name={name}
          defaultValue={value}
          placeholder={placeholder}
          onChange={this.onChange}
          onBlur={this.onBlur}
          disabled={disabled}
          className={`form-control ${!errorText || errorText === "OK" ? "" : "is-invalid"} ${className ? className : ""}`}
        />
        <div className="invalid-feedback">{errorText === "OK" ? "" : errorText}</div>
      </div>
    );
  }
}

EmailInput.defaultProps = {
  placeholder: "E-mail",
  /* Campo habilitado por valor padrao */
  disabled: false
};

export default EmailInput;
