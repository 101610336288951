import React, { Component } from "react";
import "./Dialog.css";
/**
 * @author Guilherme Zordan, Leonardo Lucas dos Santos
 * @description Componente de caixa de diálogo com duas opções. Necessário referenciar com ref.
 * @example
 *   <Dialog
 *      ref={el => (this.dialog = el)}>
 *      Quer confirmar o investimento?
 *    </Dialog>
 *
 */
export default class Dialog extends Component {
  state = {
    isShown: false
  };

  close = () => this.setState({ isShown: false });

  open = () => this.setState({ isShown: true });

  render() {
    const { isShown } = this.state;
    const { children } = this.props;
    if (isShown) {
      return (
        <div className="DialogBackground">
          <div className="row h-100">
            <div className="m-auto Dialog p-2 pb-4">
              <div onClick={this.close} className="CloseContainer">
                <div className="CloseButton">&times;</div>
              </div>
              {children}
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
}
