import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./PasswordInput.css";
import PropTypes from "prop-types";

const PasswordInput = props => {
  const [showPassword, togglePassword] = useState(false);
  let inputClasses = [
    "form-control",
    props.styleCss ? props.styleCss : "",
    props.successValidation ? "" : "is-invalid"
  ].join(" ");

  const validatePassword = password => {
    return password.length === 6 && props.numberOnly
      ? new RegExp(/^[0-9]{1,}$/).test(password)
      : true;
  };

  const onChange = e => {
    let password = props.numberOnly
      ? e.target.value.replace(/[^\d]/g, "")
      : e.target.value;
    if (validatePassword(password)) {
      props.onChangeValue(password);
    } else {
      props.onChangeValue("");
    }
    e.target.value = password;
  };

  const onBlur = e => {
    onChange(e);
  };

  return (
    <div className="d-flex form-group justify-content-center">
      <div className="input-group">
        <input
          className={inputClasses}
          ref={props.reference}
          type={showPassword ? "text" : "password"}
          minLength="6"
          maxLength="6"
          placeholder={props.placeholder}
          defaultValue={props.password}
          onChange={onChange}
          onBlur={onBlur}
          name={props.name}
        />
        <div
          className="input-group-append PasswordInputPointer"
          onClick={() => togglePassword(!showPassword)}
        >
          <div className=" input-group-text PasswordInputPointer">
            {showPassword ? (
              <FaEye className="Icon" />
            ) : (
              <FaEyeSlash className="Icon" />
            )}
          </div>
        </div>
        <div className="invalid-feedback">{props.errorText}</div>
      </div>
    </div>
  );
};

PasswordInput.propTypes = {
  // Alinhamento do conteudo do header, deve ser String.
  onChangeValue: PropTypes.func.isRequired,
  numberOnly: PropTypes.bool
};

PasswordInput.defaultProps = {
  // Alinhamento do conteudo do header, por default center
  placeholder: "Informe a senha",
  numberOnly: true
};
export default PasswordInput;
